import React, { Component } from 'react';
import { DECIMAL_FIELD_ERROR, DECIMAL_REGEX, PLEASE_SELECT_COUNTRY, UNIT_OPTIONS } from '../../models/constants';
import './product_management.css';
import {
  ForegroundOption,
  ForegroundSelectMetaAction,
  ForegroundSelectMulti,
  ForegroundSelectSingle,
  SELECT_ACTION,
} from '../../components/ForegroundSelect';
import CountryService from '../../services/country.service';
import { DeviceWizardStepProps } from './DeviceWizard';
import { Country, ReducedCountry } from '../../models/country';

class DevicePricingStepState {
  errorMessage: string;
  isErrorOnCountries: boolean;
  isErrorOnPricingFrom1To25: boolean;
  isErrorOnPricingFrom26To50: boolean;
  isErrorOnPricingFrom51To100: boolean;
  isErrorOnPricingFrom101To250: boolean;
  isErrorOnPricingFrom251To500: boolean;
  isErrorOnPricingFrom501To1000: boolean;
  isErrorOnPricingFrom1001To5000: boolean;
  isErrorOnPricingFrom5001To10000: boolean;
  isErrorOnPricingAbove10000: boolean;
  currentDeviceCurrency: ForegroundOption;
  currentDeviceIsSamplesAvailables: boolean;
  currentDeviceProductionCountries: ForegroundOption[];
  currentDeviceDeployedCountries: ForegroundOption[];
  countryListOptions: ForegroundOption[];
  countryList: Country[];

  constructor() {
    this.errorMessage = '';
    this.isErrorOnCountries = false;
    this.isErrorOnPricingFrom1To25 = false;
    this.isErrorOnPricingFrom26To50 = false;
    this.isErrorOnPricingFrom51To100 = false;
    this.isErrorOnPricingFrom101To250 = false;
    this.isErrorOnPricingFrom251To500 = false;
    this.isErrorOnPricingFrom501To1000 = false;
    this.isErrorOnPricingFrom1001To5000 = false;
    this.isErrorOnPricingFrom5001To10000 = false;
    this.isErrorOnPricingAbove10000 = false;
    this.currentDeviceCurrency = UNIT_OPTIONS[0];
    this.currentDeviceIsSamplesAvailables = false;
    this.currentDeviceProductionCountries = [];
    this.currentDeviceDeployedCountries = [];
    this.countryListOptions = [];
    this.countryList = [];
  }
}

export class DevicePricingStep extends Component<DeviceWizardStepProps, DevicePricingStepState> {
  constructor(props) {
    super(props);
    this.state = new DevicePricingStepState();
  }

  async componentDidMount() {
    try {
      const isCreation = !!!this.props.device.id;
      const response = await CountryService.list('countryName');
      if (!isCreation) {
        const deviceProductionCountries = this.props.device.productionCountries;
        const deviceDeployedInCountries = this.props.device.deployedInCountries;
        const deviceSamplesAvailables = this.props.device.samplesAvailable;
        const devicePrincingUnit = this.props.device.pricingUnit;
        this.setState({
          currentDeviceCurrency: !!devicePrincingUnit
            ? new ForegroundOption(this.props.device.pricingUnit, this.props.device.pricingUnit)
            : UNIT_OPTIONS[0],

          countryList: response.data,
          countryListOptions: response.data.map((item) => {
            return new ForegroundOption(item.countryName, item.id);
          }),

          currentDeviceProductionCountries: !!deviceProductionCountries
            ? deviceProductionCountries.map((item) => {
                return new ForegroundOption(item.countryName, item.id);
              })
            : [],

          currentDeviceDeployedCountries: !!deviceDeployedInCountries
            ? deviceDeployedInCountries.map((item) => {
                return new ForegroundOption(item.countryName, item.id);
              })
            : [],

          currentDeviceIsSamplesAvailables: deviceSamplesAvailables,
        });
      } else {
        this.setState({
          countryList: response.data,
          countryListOptions: response.data.map((item) => {
            return new ForegroundOption(item.countryName, item.id);
          }),
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleChangeDeviceCurrency(event: ForegroundOption) {
    this.props.device.pricingUnit = event.value;
    this.setState({ currentDeviceCurrency: event });
  }

  handleClickSamplesAvailables(isSamplesAvailablesOn: boolean) {
    this.setState({ currentDeviceIsSamplesAvailables: isSamplesAvailablesOn });
    this.props.device.samplesAvailable = isSamplesAvailablesOn;
  }

  handleChangeDeviceCountryProduction(event: ForegroundOption[], action: ForegroundSelectMetaAction) {
    const currentDeviceProductionCountries = this.state.currentDeviceProductionCountries;
    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        this.props.device.productionCountries = !!event
          ? event.map((r) => {
              return new ReducedCountry(r.value, r.label);
            })
          : [];
        this.setState({ currentDeviceProductionCountries: event });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelectionProductionCountries = currentDeviceProductionCountries.filter((e) => e.value !== action.removedValue.value);
        this.props.device.productionCountries = this.props.device.productionCountries.filter((e) => e.id !== action.removedValue.value);

        this.setState({ currentDeviceProductionCountries: newSelectionProductionCountries });
        break;
      case SELECT_ACTION.CLEAR:
        this.props.device.productionCountries = [];
        this.setState({ currentDeviceProductionCountries: [] });
        break;
      default:
    }
  }

  handleChangeDeviceCountryDistribution(event: ForegroundOption[], action: ForegroundSelectMetaAction) {
    const currentDeviceDeployedCountries = this.state.currentDeviceDeployedCountries;
    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        currentDeviceDeployedCountries.push(action.option);
        this.props.device.deployedInCountries = !!event
          ? event.map((r) => {
              return new ReducedCountry(r.value, r.label);
            })
          : [];
        this.setState({ currentDeviceDeployedCountries: event, isErrorOnCountries: false });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentDeviceDeployedCountries.filter((e) => e.value !== action.removedValue.value);
        this.props.device.deployedInCountries =
          !!this.props.device.deployedInCountries && this.props.device.deployedInCountries.filter((e) => e.id !== action.removedValue.value);
        const isError = !!this.props.device.deployedInCountries ? false : true;
        const errorMessage = isError ? PLEASE_SELECT_COUNTRY : '';
        this.setState({ currentDeviceDeployedCountries: newSelection, isErrorOnCountries: isError, errorMessage: errorMessage });
        break;
      case SELECT_ACTION.CLEAR:
        this.props.device.deployedInCountries = [];
        this.setState({ currentDeviceDeployedCountries: [], isErrorOnCountries: true, errorMessage: PLEASE_SELECT_COUNTRY });
        break;
      default:
    }
  }

  validate() {
    if (this.props.isReadOnly) {
      return true;
    }

    if (!!this.props.device.pricingFrom1To25 && !DECIMAL_REGEX.test(this.props.device.pricingFrom1To25)) {
      this.setState({ isErrorOnPricingFrom1To25: true, errorMessage: DECIMAL_FIELD_ERROR });
      return false;
    }
    if (!!this.props.device.pricingFrom26To50 && !DECIMAL_REGEX.test(this.props.device.pricingFrom26To50)) {
      this.setState({ isErrorOnPricingFrom26To50: true, errorMessage: DECIMAL_FIELD_ERROR });
      return false;
    }
    if (!!this.props.device.pricingFrom51To100 && !DECIMAL_REGEX.test(this.props.device.pricingFrom51To100)) {
      this.setState({ isErrorOnPricingFrom51To100: true, errorMessage: DECIMAL_FIELD_ERROR });
      return false;
    }
    if (!!this.props.device.pricingFrom101To250 && !DECIMAL_REGEX.test(this.props.device.pricingFrom101To250)) {
      this.setState({ isErrorOnPricingFrom101To250: true, errorMessage: DECIMAL_FIELD_ERROR });
      return false;
    }
    if (!!this.props.device.pricingFrom251To500 && !DECIMAL_REGEX.test(this.props.device.pricingFrom251To500)) {
      this.setState({ isErrorOnPricingFrom251To500: true, errorMessage: DECIMAL_FIELD_ERROR });
      return false;
    }
    if (!!this.props.device.pricingFrom501To1000 && !DECIMAL_REGEX.test(this.props.device.pricingFrom501To1000)) {
      this.setState({ isErrorOnPricingFrom501To1000: true, errorMessage: DECIMAL_FIELD_ERROR });
      return false;
    }
    if (!!this.props.device.pricingFrom1001To5000 && !DECIMAL_REGEX.test(this.props.device.pricingFrom1001To5000)) {
      this.setState({ isErrorOnPricingFrom1001To5000: true, errorMessage: DECIMAL_FIELD_ERROR });
      return false;
    }
    if (!!this.props.device.pricingFrom5001To10000 && !DECIMAL_REGEX.test(this.props.device.pricingFrom5001To10000)) {
      this.setState({ isErrorOnPricingFrom5001To10000: true, errorMessage: DECIMAL_FIELD_ERROR });
      return false;
    }
    if (!!this.props.device.pricingAbove10000 && !DECIMAL_REGEX.test(this.props.device.pricingAbove10000)) {
      this.setState({ isErrorOnPricingAbove10000: true, errorMessage: DECIMAL_FIELD_ERROR });
      return false;
    }

    if (!!!this.props.device.deployedInCountries || this.props.device.deployedInCountries.length === 0) {
      this.setState({ isErrorOnCountries: true, errorMessage: PLEASE_SELECT_COUNTRY });
      return false;
    }

    return true;
  }

  render() {
    if (this.props.shouldDisplay) {
      return (
        <div className="row">
          <div className='mb-3 mt-2'>All mandatory fields are marked with an *</div>
          <h2 className=" fs-5">Business model</h2>
          <div className="row col-md-10 ms-2">
            <label htmlFor="textareaDescription">Description</label>
            <textarea
              required
              className="w-100 mt-2 ms-2"
              rows={5}
              id="textareaDescription"
              name="textareaDescription"
              defaultValue={this.props.device.businessModel}
              onChange={(e) => (this.props.device.businessModel = e.target.value)}
              readOnly={this.props.isReadOnly}
              disabled={this.props.isReadOnly}
            ></textarea>
          </div>
          <div className="row ">
            <div className="col-md-5">
              <h2 className="mt-4 fs-5">Indicative price per quantities</h2>
              <div className="col-md-4 ms-2">
                <div className="form-check form-switch div-check ">
                  <label className="form-check-label" htmlFor="flexSwitchCheckSamplesAvailables">
                    Samples availables
                  </label>
                  <input
                    className="form-check-input "
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckSamplesAvailables"
                    name="flexSwitchCheckSamplesAvailables"
                    checked={this.state.currentDeviceIsSamplesAvailables}
                    onChange={() => this.handleClickSamplesAvailables(!this.state.currentDeviceIsSamplesAvailables)}
                    readOnly={this.props.isReadOnly}
                    disabled={this.props.isReadOnly}
                  ></input>
                </div>
                <label className="mt-4" htmlFor="deviceCurrency">
                  Currency
                </label>

                <ForegroundSelectSingle
                  inputId="deviceCurrency"
                  value={this.state.currentDeviceCurrency}
                  options={UNIT_OPTIONS}
                  onChange={(e) => this.handleChangeDeviceCurrency(e)}
                  isDisabled={this.props.isReadOnly}
                  isClearable={false}
                  placeholder="Please select the pricing unit"
                />
              </div>
              <div className="row mt-4 ps-2">
                <div className="col-md-4">
                  <label htmlFor="1To25">1-25 units</label>
                  <input
                    className="w-100 form-control mt-2"
                    type="text"
                    id="1To25"
                    defaultValue={this.props.device.pricingFrom1To25}
                    onChange={(e) => {
                      this.props.device.pricingFrom1To25 = e.target.value;
                      if (DECIMAL_REGEX.test(this.props.device.pricingFrom1To25)) {
                        this.setState({ isErrorOnPricingFrom1To25: false });
                      } else {
                        this.setState({ isErrorOnPricingFrom1To25: true, errorMessage: DECIMAL_FIELD_ERROR });
                      }
                    }}
                    readOnly={this.props.isReadOnly}
                    disabled={this.props.isReadOnly}
                  ></input>
                  {this.state.isErrorOnPricingFrom1To25 ?
                    <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}

                  <label className="mt-3" htmlFor="26To50">
                    26-50 units
                  </label>
                  <input
                    className="w-100 form-control mt-2"
                    type="text"
                    id="26To50"
                    defaultValue={this.props.device.pricingFrom26To50}
                    onChange={(e) => {
                      this.props.device.pricingFrom26To50 = e.target.value;
                      if (DECIMAL_REGEX.test(this.props.device.pricingFrom26To50)) {
                        this.setState({ isErrorOnPricingFrom26To50: false });
                      } else {
                        this.setState({ isErrorOnPricingFrom26To50: true, errorMessage: DECIMAL_FIELD_ERROR });
                      }
                    }}
                    readOnly={this.props.isReadOnly}
                    disabled={this.props.isReadOnly}
                  ></input>
                  {this.state.isErrorOnPricingFrom26To50 ?
                    <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}

                  <label className="mt-3" htmlFor="51To100">
                    51-100 units
                  </label>
                  <input
                    className="w-100 form-control mt-2"
                    type="text"
                    id="51To100"
                    defaultValue={this.props.device.pricingFrom51To100}
                    onChange={(e) => {
                      this.props.device.pricingFrom51To100 = e.target.value;
                      if (DECIMAL_REGEX.test(this.props.device.pricingFrom51To100)) {
                        this.setState({ isErrorOnPricingFrom51To100: false });
                      } else {
                        this.setState({ isErrorOnPricingFrom51To100: true, errorMessage: DECIMAL_FIELD_ERROR });
                      }
                    }}
                    readOnly={this.props.isReadOnly}
                    disabled={this.props.isReadOnly}
                  ></input>
                  {this.state.isErrorOnPricingFrom51To100 ?
                    <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}
                </div>
                <div className="col-md-4">
                  <label htmlFor="101To250">101-250 units</label>
                  <input
                    className="w-100 form-control mt-2"
                    type="text"
                    id="101To250"
                    defaultValue={this.props.device.pricingFrom101To250}
                    onChange={(e) => {
                      this.props.device.pricingFrom101To250 = e.target.value;
                      if (DECIMAL_REGEX.test(this.props.device.pricingFrom101To250)) {
                        this.setState({ isErrorOnPricingFrom101To250: false });
                      } else {
                        this.setState({ isErrorOnPricingFrom101To250: true, errorMessage: DECIMAL_FIELD_ERROR });
                      }
                    }}
                    readOnly={this.props.isReadOnly}
                    disabled={this.props.isReadOnly}
                  ></input>
                  {this.state.isErrorOnPricingFrom101To250 ?
                    <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}
                  <label className="mt-3" htmlFor="251To500">
                    251-500 units
                  </label>
                  <input
                    className="w-100 form-control mt-2"
                    type="text"
                    id="251To500"
                    defaultValue={this.props.device.pricingFrom251To500}
                    onChange={(e) => {
                      this.props.device.pricingFrom251To500 = e.target.value;
                      if (DECIMAL_REGEX.test(this.props.device.pricingFrom251To500)) {
                        this.setState({ isErrorOnPricingFrom251To500: false });
                      } else {
                        this.setState({ isErrorOnPricingFrom251To500: true, errorMessage: DECIMAL_FIELD_ERROR });
                      }
                    }}
                    readOnly={this.props.isReadOnly}
                    disabled={this.props.isReadOnly}
                  ></input>
                  {this.state.isErrorOnPricingFrom251To500 ?
                    <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}

                  <label className="mt-3" htmlFor="501To1000">
                    501-1000 units
                  </label>
                  <input
                    className="w-100 form-control mt-2"
                    type="text"
                    id="501To1000"
                    defaultValue={this.props.device.pricingFrom501To1000}
                    onChange={(e) => {
                      this.props.device.pricingFrom501To1000 = e.target.value;
                      if (DECIMAL_REGEX.test(this.props.device.pricingFrom501To1000)) {
                        this.setState({ isErrorOnPricingFrom501To1000: false });
                      } else {
                        this.setState({ isErrorOnPricingFrom501To1000: true, errorMessage: DECIMAL_FIELD_ERROR });
                      }
                    }}
                    readOnly={this.props.isReadOnly}
                    disabled={this.props.isReadOnly}
                  ></input>
                  {this.state.isErrorOnPricingFrom501To1000 ?
                    <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}
                </div>
                <div className="col-md-4">
                  <label htmlFor="1001To5000">1001-5000 units</label>
                  <input
                    className="w-100 form-control mt-2"
                    type="text"
                    id="1001To5000"
                    defaultValue={this.props.device.pricingFrom1001To5000}
                    onChange={(e) => {
                      this.props.device.pricingFrom1001To5000 = e.target.value;
                      if (DECIMAL_REGEX.test(this.props.device.pricingFrom1001To5000)) {
                        this.setState({ isErrorOnPricingFrom1001To5000: false });
                      } else {
                        this.setState({ isErrorOnPricingFrom1001To5000: true, errorMessage: DECIMAL_FIELD_ERROR });
                      }
                    }}
                    readOnly={this.props.isReadOnly}
                    disabled={this.props.isReadOnly}
                  ></input>
                  {this.state.isErrorOnPricingFrom1001To5000 ?
                    <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}

                  <label className="mt-3" htmlFor="5001To10000">
                    5001-10 000 units
                  </label>
                  <input
                    className="w-100 form-control mt-2"
                    type="text"
                    id="5001To10000"
                    defaultValue={this.props.device.pricingFrom5001To10000}
                    onChange={(e) => {
                      this.props.device.pricingFrom5001To10000 = e.target.value;
                      if (DECIMAL_REGEX.test(this.props.device.pricingFrom5001To10000)) {
                        this.setState({ isErrorOnPricingFrom5001To10000: false });
                      } else {
                        this.setState({ isErrorOnPricingFrom5001To10000: true, errorMessage: DECIMAL_FIELD_ERROR });
                      }
                    }}
                    readOnly={this.props.isReadOnly}
                    disabled={this.props.isReadOnly}
                  ></input>
                  {this.state.isErrorOnPricingFrom5001To10000 ?
                    <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}

                  <label className="mt-3" htmlFor="Above10000">
                    10 000+ units
                  </label>
                  <input
                    className="w-100 form-control mt-2"
                    type="text"
                    id="Above10000"
                    defaultValue={this.props.device.pricingAbove10000}
                    onChange={(e) => {
                      this.props.device.pricingAbove10000 = e.target.value;
                      if (DECIMAL_REGEX.test(this.props.device.pricingAbove10000)) {
                        this.setState({ isErrorOnPricingAbove10000: false });
                      } else {
                        this.setState({ isErrorOnPricingAbove10000: true, errorMessage: DECIMAL_FIELD_ERROR });
                      }
                    }}
                    readOnly={this.props.isReadOnly}
                    disabled={this.props.isReadOnly}
                  ></input>
                  {this.state.isErrorOnPricingAbove10000 ?
                    <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}
                </div>
              </div>
            </div>
            <div className="col-md-5 px-5">
              <h2 className="mt-4 fs-5">Distribution</h2>
              <div className="ms-2">
                <label className="is-required" htmlFor="distributionCountries">
                  Countries where distribution chain is in place
                </label>
                <ForegroundSelectMulti
                  inputId="distributionCountries"
                  placeholder={PLEASE_SELECT_COUNTRY}
                  value={this.state.currentDeviceDeployedCountries}
                  options={this.state.countryListOptions}
                  onChange={(e, a) => this.handleChangeDeviceCountryDistribution(e, a)}
                  isDisabled={this.props.isReadOnly}
                />
                {this.state.isErrorOnCountries ? (
                  <p id="error-distributionCountries" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
              <h2 className="mt-4 fs-5">Production</h2>
              <div className="ms-2">
                <label className="" htmlFor="productionCountries">
                  Countries where device is producted
                </label>
                <ForegroundSelectMulti
                  inputId="productionCountries"
                  placeholder={PLEASE_SELECT_COUNTRY}
                  value={this.state.currentDeviceProductionCountries}
                  options={this.state.countryListOptions}
                  onChange={(e, a) => this.handleChangeDeviceCountryProduction(e, a)}
                  isDisabled={this.props.isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
