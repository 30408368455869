import React, { Component } from 'react';
import DocumentService from '../services/document.service';
import './AddDocument.css';
import { ForegroundOption, ForegroundSelectSingle, SELECT_ACTION } from './ForegroundSelect';
import { Buffer } from 'buffer';
import { BAD_WEB_URL_YOUTUBE, FOREGROUND_SCOPE, WEB_URL_YOUTUBE_REGEX, MANDATORY_FIELD_MISSING, PLEASE_SELECT_CATEGORY } from '../models/constants';
import { DocumentTypeEnum, ForegroundDocumentToAdd } from '../models/document';
import { cloneDeep } from 'lodash';

class AddDocumentProps {
  public isForDevice: boolean;
  public isForCompany: boolean;
  public scope: FOREGROUND_SCOPE;
  public currentDocumentToAdd: ForegroundDocumentToAdd;
  public handleDocumentChanged(fileContent: string, selectedFileName: string, selectedDocumentType: ForegroundOption) {}
  public handleVideoDocumentChanged(videoUrl: string, label: string, selectedDocumentType: ForegroundOption) {}
}

class AddDocumentState {
  public documentTypes: ForegroundOption[];
  public isLoading: boolean;
  public selectedDocumentType: ForegroundOption;
  public selectedFileName: string;
  public currentFile: any;
  public videoUrl: string;
  public videoLabel: string;
  public isErrorOnYoutubeLink: boolean;
  public errorMessageUrl: string;
  public isErrorOnVideoLabel: boolean;
  public errorMessageLabel: string;
  public isErrorOnCategory: boolean;

  constructor() {
    this.documentTypes = [];
    this.isLoading = true;
    this.selectedDocumentType = null;
    this.selectedFileName = null;
    this.currentFile = null;
    this.videoUrl = null;
    this.videoLabel = null;
    this.isErrorOnYoutubeLink = false;
    this.isErrorOnVideoLabel = false;
    this.errorMessageUrl = '';
    this.errorMessageLabel = '';
  }
}

export class AddDocument extends Component<AddDocumentProps, AddDocumentState> {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = new AddDocumentState();
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onVideoUrlChange = this.onVideoUrlChange.bind(this);
    this.onVideoLabelChange = this.onVideoLabelChange.bind(this);
  }

  async componentDidMount() {
    try {
      const partnerExcludedDocumentTypesForCompany = [DocumentTypeEnum.NDA];
      const partnerExcludedDocumentTypesForDevice = [DocumentTypeEnum.TEST_RESULT];

      let response = await DocumentService.list(this.props.isForDevice, this.props.isForCompany);
      const documentTypes = response.data.map((item) => {
        return new ForegroundOption(item.label, item.id);
      });
      const userScope = this.props.scope;
      let documentTypesFiltered = [];

      if (userScope === FOREGROUND_SCOPE.ORANGE_ADMIN) {
        documentTypesFiltered = cloneDeep(documentTypes);
      }

      if (userScope === FOREGROUND_SCOPE.ORANGE_PARTNER || userScope === FOREGROUND_SCOPE.PARTNER) {
        documentTypesFiltered = documentTypes.filter((item) => {
          if (this.props.isForCompany) return !partnerExcludedDocumentTypesForCompany.includes(item.value);
          if (this.props.isForDevice) return !partnerExcludedDocumentTypesForDevice.includes(item.value);
          return null;
        });
      }

      this.setState({
        documentTypes: documentTypesFiltered,
        isLoading: false,
        selectedDocumentType: !!this.props.currentDocumentToAdd
          ? new ForegroundOption(this.props.currentDocumentToAdd.type.label, this.props.currentDocumentToAdd.type.id)
          : null,
        selectedFileName: !!this.props.currentDocumentToAdd ? this.props.currentDocumentToAdd.fileName : null,
        currentFile: !!this.props.currentDocumentToAdd ? this.props.currentDocumentToAdd.fileContent : null,
        videoUrl: !!this.props.currentDocumentToAdd ? this.props.currentDocumentToAdd.videoUrl : null,
        videoLabel: !!this.props.currentDocumentToAdd ? this.props.currentDocumentToAdd.label : null,
      });
    } catch (error) {
      console.log(`ERROR AddDocument : ${JSON.stringify(error)}`);
      this.setState({ isLoading: false });
    }
  }

  handleChangeCategory(event: ForegroundOption, action: SELECT_ACTION) {
    const newValue = action === SELECT_ACTION.CLEAR ? null : event;

    if (newValue === null && this.state.selectedFileName != null) {
      this.setState({ selectedDocumentType: newValue, isErrorOnCategory: true });
    } else {
      this.setState({ selectedDocumentType: newValue, isErrorOnCategory: false });
    }

    const selectedFileName = this.state.selectedFileName;
    const currentFile = this.state.currentFile;
    const videoUrl = this.state.videoUrl;
    const videoLabel = this.state.videoLabel;
    this.notifyParent(newValue, selectedFileName, currentFile, videoUrl, videoLabel);
  }

  onFileChange(e) {
    let files = e.target.files;
    const selectedDocumentType = this.state.selectedDocumentType;
    if (selectedDocumentType === null) {
      this.setState({
        selectedFileName: files[0].name,
        currentFile: files[0],
        isErrorOnCategory: true,
      });
      return;
    } else {
      this.notifyParent(selectedDocumentType, files[0].name, files[0], null, null);
      this.setState({ selectedFileName: files[0].name, currentFile: files[0], isErrorOnCategory: false });
    }
  }

  onVideoUrlChange(e: string) {
    if (e.trim().length === 0) {
      this.setState({ isErrorOnYoutubeLink: true, errorMessageUrl: MANDATORY_FIELD_MISSING });
      return;
    }
    if (!WEB_URL_YOUTUBE_REGEX.test(e.trim())) {
      this.setState({ isErrorOnYoutubeLink: true, errorMessageUrl: BAD_WEB_URL_YOUTUBE });
      return;
    }
    this.setState({ isErrorOnYoutubeLink: false, videoUrl: e });
    const selectedDocumentType = this.state.selectedDocumentType;
    this.notifyParent(selectedDocumentType, null, null, e, this.state.videoLabel);
  }

  onVideoLabelChange(e: string) {
    if (e.trim().length === 0) {
      this.setState({ isErrorOnVideoLabel: true, errorMessageLabel: MANDATORY_FIELD_MISSING });
      return;
    }

    this.setState({ isErrorOnVideoLabel: false, videoLabel: e });
    const selectedDocumentType = this.state.selectedDocumentType;
    this.notifyParent(selectedDocumentType, null, null, this.state.videoUrl, e);
  }

  notifyParent(selectedDocumentType: ForegroundOption, selectedFileName: string, currentFile, videoUrl: string, videoLabel: string) {
    if (!!selectedDocumentType) {
      if (selectedDocumentType.value !== 14 && !!currentFile) {
        const reader = new FileReader();
        reader.readAsBinaryString(currentFile);

        reader.onloadend = function (e) {
          this.props.handleDocumentChanged(
            Buffer.from(reader.result.toString(), 'binary').toString('base64'),
            selectedFileName,
            selectedDocumentType
          );
        }.bind(this);
      } else {
        if (selectedDocumentType.value === 14 && !!videoUrl && !!videoLabel) {
          this.props.handleVideoDocumentChanged(videoUrl, videoLabel, selectedDocumentType);
        }
      }
    }
  }

  validate() {
    if (!!this.state.isErrorOnVideoLabel || !!this.state.isErrorOnYoutubeLink) {
      return false;
    }

    if (!!this.state.isErrorOnCategory) {
      return false;
    }

    return true;
  }

  render() {
    const selectedDocumentType = this.state.selectedDocumentType;
    return (
      <div className="add-document-div">
        <h2 className="mt-2">Add document</h2>
        <div className="my-2">
          <label className='is-required' htmlFor="category" >
            Category
          </label>
          <ForegroundSelectSingle
            inputId="category"
            placeholder="Please select document type"
            value={this.state.selectedDocumentType}
            options={this.state.documentTypes}
            onChange={(e, a) => this.handleChangeCategory(e, a)}
            closeOnSelect={true}
            aria-describedby={this.state.isErrorOnCategory ? 'error-no-select' : ''}
            aria-invalid={this.state.isErrorOnCategory}
            aria-required="true"
          ></ForegroundSelectSingle>
          {this.state.isErrorOnCategory ? (
            <p id="error-no-select" className="errorMessage mt-2">
              {PLEASE_SELECT_CATEGORY}
            </p>
          ) : null}
        </div>
        {!!selectedDocumentType && selectedDocumentType.value === 14 ? (
          <div>
            <div className="row mb-2">
              <div className="col-md-12">
                <label className="is-required" htmlFor="youtube">
                  Paste Youtube URL
                </label>
                <input
                  id="youtube"
                  type="text"
                  className="form-control"
                  defaultValue={this.state.videoUrl}
                  onChange={(e) => {
                    this.onVideoUrlChange(e.target.value);
                  }}
                  aria-describedby={this.state.isErrorOnYoutubeLink ? 'error-video-url' : ''}
                  aria-invalid={this.state.isErrorOnYoutubeLink}
                  aria-required="true"
                  required
                ></input>
                {this.state.isErrorOnYoutubeLink ? (
                  <p id="error-video-url" className="errorMessage mt-2">
                    {this.state.errorMessageUrl}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-md-12">
                <label className="is-required" htmlFor="videoLabel">
                  Video label
                </label>
                <input
                  id="videoLabel"
                  className="form-control mb-2"
                  defaultValue={this.state.videoLabel}
                  onChange={(e) => {
                    this.onVideoLabelChange(e.target.value);
                  }}
                  aria-describedby={this.state.isErrorOnVideoLabel ? 'error-video-label' : ''}
                  aria-invalid={this.state.isErrorOnVideoLabel}
                  aria-required="true"
                ></input>
                {this.state.isErrorOnVideoLabel ? (
                  <p id="error-video-label" className="errorMessage mt-2">
                    {this.state.errorMessageLabel}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <label className="custom-file-upload mb-2">
            <input type="file" onChange={this.onFileChange} />
            <i className="icon foreground-ic_Attachment" />
            <span style={{ fontSize: '14px' }}>{!!this.state.selectedFileName ? this.state.selectedFileName : 'Please select a document'}</span>
          </label>
        )}
      </div>
    );
  }
}
