import React, { Component } from 'react';

export class TermsConditions extends Component {
  render() {
    return (
      <div className="TermsConditions" id="TermsConditions">
        <div id="administratif_termsconditions" className="border-bottom border-light pt-3">
          <div className="container">
            <h1 className="display-2">Terms and conditions</h1>
          </div>
        </div>

        <div className="container mt-4 ">
          <div className="row col-10 mt-5">
            <p className="mb-3 display-5">
              The website called “Orange Foreground” is edited and operated by Orange SA (‘Orange’). By accessing, browsing and/or using the Sites,
              you agree to the following terms and conditions.
            </p>
            <p className="mb-5 font-10">Last updated: March 16th, 2020</p>

            <h2 className="mb-1 display-4">1. Purpose of the sites</h2>
            <p className="mb-4 lh-1.5">
              Orange Foreground is a website enabling end-users (called Partners) : <br />
              - To provide their company and products public information; <br />
              - To access and update their company and products public information; <br />- To have a private space on Orange Foreground, called
              “Profile”, to edit the information supplied during sign-up.
            </p>

            <h2 className="mb-1 display-4">2. Content</h2>
            <p className="mb-4 lh-1.5">
              The content (including all information, data, documents, pictures, videos, drawings, audio tracks) (the “Content”) contained on or
              available through the Site is provided by Orange, its affiliates, independent content providers or third parties. It is protected by
              copyright of Orange, its affiliates, independent content providers or third parties. All rights are reserved and any reproduction, copy,
              modification, transfer, distribution or storage of part or all of the Content in any form or by any means without the prior written
              permission of Orange is prohibited.
            </p>

            <h2 className="mb-1 display-4">3. Orange rights</h2>
            <p className="mb-4 lh-1.5">
              Orange is and will remain the owner of the Sites (including software, software applications, graphics, design, brands and logos,
              databases) and Content published on the Sites or available through the Sites. <br />
              ORANGE, the ORANGE logo and any “Orange” product or service name referred to on the Sites or any Contents published on the Sites or
              available through the Sites are trademarks of Orange or its affiliates. Other products or names mentioned in these Sites or any Contents
              published on the Sites or available through the Sites may be trademarks or trade names of their respective owners. Your access to the
              Sites should not be construed as granting, by implication or otherwise, any licence or right to use any marks appearing on the Sites
              without the prior written consent of Orange or the third party owner thereof.
            </p>

            <h2 className="mb-1 display-4">4. Right of use (License)</h2>
            <p className="mb-4 lh-1.5">
              Orange grants you a non-exclusive, non-transferable, revocable, worldwide and non-sublicensable right to use the Sites (including the
              Content) for your personal use only. This right to use is subject to compliance with these Sites Terms and Conditions. The right to use
              does not allow you: <br />
              - To access or use the Sites sources codes and/or any of its software components; <br />
              - To use, copy, modify or distribute the Sites and/or any of its software components; <br />
              - To dismantle, decompile or translate the Sites and/or any of its software components, except as expressly authorized by the applicable
              laws or regulations;
              <br />- To sub-license or concede the Sites and/or any of its software components.
            </p>

            <h2 className="mb-1 display-4">5. Supply of information other than personal information</h2>
            <p className="mb-4 lh-1.5">
              By submitting any non-personal information or material (for example, description of your service or application) to Orange, you grant
              Orange a restricted right to use such non-personal information or material for the sole purpose of putting you in contact with relevant
              people at Orange and/or at its affiliates and/or at Orange’s business partners. Therefore, you accept that Orange discloses your
              non-personal information or material to any of its affiliated companies and/or Orange’s business partners.
              <br />
              Your non-personal information or material will not be used for any other purposes and will remain confidential within Orange, its
              affiliates and Orange’s business partners. In case you have the opportunity to work with Orange, any of its affiliates and/or any
              Orange’s business partners and wish to use any services provided by Orange (including the distribution of your service or application),
              specific agreements will apply to such services and suitable rights to use your non-personal information or material will be granted.
            </p>

            <h2 className="mb-1 display-4">6. Orange liability</h2>
            <p className="mb-4 lh-1.5">
              The Sites and their Content are provided as a convenience to you. The Sites and their Content are provided on an “as is” and “as
              available” basis. Orange does not guarantee that the Sites’ web pages will be uninterrupted or error-free or fit for any particular
              purpose. Orange reserves the right, at any time and at its own discretion, to revise the Sites’ pages or withdraw access to the Sites or
              any part of it. NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR NON-INFRINGEMENT OR
              IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, IS MADE IN RELATION TO THE AVAILABILITY, ACCURACY,
              RELIABILITY OR CONTENT OF THE SITES. ORANGE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES, LOST PROFITS
              OR FOR BUSINESS INTERRUPTION ARISING OUT OF THE USE OF OR INABILITY TO USE THESE SITES EVEN IF ORANGE HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. Some jurisdictions do not allow exclusions of certain warranties or limitations of liability, so the above
              limitations or exclusions may not apply to you. The liability of Orange would in such case be limited to the greatest extent permitted
              by law.
            </p>

            <h2 className="mb-1 display-4">7. Third parties sites</h2>
            <p className="mb-4 lh-1.5">
              For your easy accessibility Orange may include links to sites on the internet that are owned or operated by third parties. You agree to
              review and agree to any such sites rules of use before using such sites. You also agree that Orange has no control over the content of
              any such sites and cannot assume any responsibility for material created or published by such third party sites. In addition, a link to
              a non-Orange site does not imply that Orange endorses the site, the contents published on such third party site or the products or
              services referenced in such third party site.
            </p>

            <h2 className="mb-1 display-4">8. Use of Internet</h2>
            <p className="mb-4 lh-1.5">
              The Sites are accessed via the Internet which is independent of Orange. Use of the Internet is solely at your own risk and is subject to
              all applicable national and international laws and regulations. Orange has no responsibility for any information or service obtained by
              you on the Internet.
            </p>

            <h2 className="mb-1 display-4">9. Changing the site</h2>
            <p className="mb-4 lh-1.5">
              Orange may suspend or vary the whole or any part of the Sites for any reason, at any time at its sole discretion.
            </p>

            <h2 className="mb-1 display-4">10. Change of the site Terms and conditions</h2>
            <p className="mb-4 lh-1.5">
              Orange reserves the right to vary from time to time the terms of these Sites Terms and Conditions, such variations becoming effective
              immediately upon posting of the varied Sites Terms and Conditions on the Sites. By continuing to use the Sites, you will be deemed to
              have accepted the updated Sites Terms and Conditions available on the Sites.
            </p>

            <h2 className="mb-1 display-4">11. Choice of Law - Jurisdiction</h2>
            <p className="mb-4 lh-1.5">
              These Sites Terms and Conditions shall be governed by and construed in accordance with the laws of France. You and Orange agree to
              submit to the jurisdiction of the courts of Paris, France, for any claim or matter arising under or in connection with these Sites Terms
              and Conditions.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
