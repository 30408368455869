import React, { Component } from 'react';
import './Login.css';
import UserService from '../../services/user.service';
import ModalResetPassword from '../../components/resetPasswordModal';
import { jwtDecode } from "jwt-decode";
import {
  updateUserScope,
  updateUserFirstName,
  updateUserToken,
  updateUserLastName,
  updateUserId,
  updateCompanySlug,
  updateUserCgu,
  updateUserCanDeleteCompaniesAndDevices,
} from '../../actions/users.actions';
import { connect } from 'react-redux';
import Utils from '../../helpers/Utils';
import { PasswordInput } from '../../components/PasswordInput';
import { UserToken } from '../../models/token';
import { FOREGROUND_SCOPE, EMAIL_REGEX, SESSION_STORAGE_PASSWORD, LOGIN_ERROR } from '../../models/constants';

class LoginProps {
  history: any;
  updateUserFirstName(userFirstName: string) {}
  updateUserLastName(userLastName: string) {}
  updateUserScope(scopes: FOREGROUND_SCOPE[]) {}
  updateUserToken(token: string) {}
  updateUserId(userId: number) {}
  updateCompanySlug(companySlug: string) {}
  updateUserCgu(cgu: boolean) {}
  updateUserCanDeleteCompaniesAndDevices(canDeleteCompaniesAndDevices: boolean) {}
}

class LoginState {
  isError: boolean;
  messageError: string;
  username: string;
  myPassword: string;
  showResetPassword: boolean;
  isValidUserName: boolean;
  isValidPassword: boolean;
  messageUserNameError: string;
  messagePasswordError: string;
  submitDisabled:boolean;

  constructor() {
    this.isError = false;
    this.messageError = '';
    this.showResetPassword = false;
    this.isValidUserName = true;
    this.messageUserNameError = '';
    this.submitDisabled = false;
  }
}

export class Login extends Component<LoginProps, LoginState> {
  /**
   * Constructor
   * @param props
   */
  myPassword: any;

  constructor(props) {
    super(props);
    this.state = new LoginState();
    this.myPassword = React.createRef();
  }

  /**
     * Mount Login component -> Initialize props
     * @param

    componentDidMount() {
        this.props.updateUserScope(null);
        this.props.updateUserToken(null);
        this.props.updateUserFirstName(null);
        this.props.updateUserLastName(null);
    }*/

  /**
   * Submit
   * @param e
   * @returns {Promise<void>}
   */
  async submit(e) {
    e.preventDefault();
    if (this.state.submitDisabled) return
    let response;
    this.setState({ submitDisabled:true });

    if (!this.state.username  || !this.state.myPassword ){
      this.handleChangeUserName(this.state.username);
      this.handleChangePassword( this.state.myPassword);
      return;
    }

    try {
      let { username, myPassword } = this.state;
      response = await UserService.authenticate({ email: username, password: myPassword });
      let token = response.data.accessToken;
      const decoded = jwtDecode(token) as UserToken;
      this.props.updateUserFirstName(decoded.userFirstName);
      this.props.updateUserLastName(decoded.userLastName);
      this.props.updateUserScope(decoded.scopes);
      this.props.updateUserToken(token);
      this.props.updateUserId(decoded.userId);
      this.props.updateCompanySlug(decoded.companySlug);
      this.props.updateUserCgu(decoded.cgu);
      this.props.updateUserCanDeleteCompaniesAndDevices(decoded.canDeleteCompaniesAndDevices);
      Utils.setAuthToken(token);
      if (response.data.changeNextTime) {
        sessionStorage.setItem(SESSION_STORAGE_PASSWORD, myPassword);
        this.props.history.push('/changePassword');
      } else if (!decoded.cgu) {
        this.props.history.push('/cgu');
      } else {
        this.props.history.push('/products');
      }
      this.setState({ submitDisabled:false })
    } catch (e) {
      let message = LOGIN_ERROR;
      if (e !== undefined) {
        if (response.status === 401)
          message =
            'Email and / or password is incorrect.';
        if (response.status === 403)
          message =
            'Email and / or password is incorrect.';
        if (response.status === 429)
          message = 'Too many requests. You have been blocked for 1 hour.';
      }
      this.setState({ isError: true, messageError: message, submitDisabled:false });
    }
  }

  /**
   * Handle change
   * @param userName
   */
  handleChangeUserName(userName:string) {

    if (!userName || userName.trim().length === 0){
      this.setState({ isValidUserName: false, messageUserNameError: 'Email must be not empty', username: userName });
      return;
    }

    if (!EMAIL_REGEX.test(userName.trim())) {
      this.setState({ isValidUserName: false, messageUserNameError: 'Email field must be a valid email address', username: userName });
      return;
    }
    this.setState({ isValidUserName: true, messageUserNameError: '', username: userName });
  }

  handleChangePassword(myPassword:string) {
    if (!myPassword || myPassword.trim().length === 0){
      this.setState({ isValidPassword: false, messagePasswordError: 'Password must be not empty', myPassword: myPassword });
      return;
    }

    this.setState({ myPassword: myPassword, isValidPassword: true });
  }

  async handleCloseDlg() {
    this.setState({ showResetPassword: false });
  }

  render() {
    document.title = 'Login - Foreground V3';
    return (
      <div className="login__container">
        <div id={`auth_login`} className={`border-bottom border-light pt-3`}>
          <div className={`container`}>
            <h1 id="login-title">Log in</h1>
          </div>
        </div>

        <div id={`loginForm`} className={`container`}>
          <form className={`mt-3`} onSubmit={(e) => this.submit(e)}>
            <div className={`d-flex flex-column`}>
              <div className='mb-1'>All mandatory fields are marked with an *</div>
              <div id={`loginSection`} className={`form-group mt-3`}>
                <label htmlFor={`username`} className={`is-required`}>
                  Email
                </label>
                <input
                  type={`email`}
                  className={`form-control mt-1 ${this.state.isValidUserName ? '' : 'error'} `}
                  id={`username`}
                  name={`email`}
                  placeholder={`email@example.com`}
                  onChange={(e) => this.handleChangeUserName(e.target.value)}
                  aria-describedby={this.state.isValidUserName ? '' : 'error-email'}
                  aria-invalid={!this.state.isValidUserName}
                  aria-required='true'
                ></input>
              </div>

              {this.state.isValidUserName ? null : (
                <p id='error-email' className='errorMessage'>
                  {this.state.messageUserNameError}
                </p>
              )}

              <div id={`passwordSection`} className={`form-group mt-3`}>
                <label htmlFor={`myPassword`} className={`is-required`}>
                  Password
                </label>
                <PasswordInput
                  inputId='myPassword'
                  placeholder={`Your password`}
                  handleChangePassword={(e) => this.handleChangePassword(e.target.value)}
                ></PasswordInput>
              </div>
              {this.state.isValidPassword ? null : (
                <p id="error-password" className="errorMessage">
                  {this.state.messagePasswordError}
                </p>
              )}

              {this.state.isError ? <p style={{ color: '#ff0000' }}>{this.state.messageError}</p> : ''}

              <div id={`loginButtonSection`} className={`mt-3`}>
                <button id='authButton' type={`submit`} className={`btn btn-primary`} disabled={this.state.submitDisabled}>
                  Log in
                </button>
              </div>

              <div className={`d-flex flex-column mt-3 col-5 justify-content-between`}>
                <div id={`forgotPasswordSection`} className={`flex-row mt-3justify-content-between`}>
                  <div className={`flex-column col-8 d-inline-flex fw-bold`}>
                    <strong>Forgot password?</strong>
                  </div>
                  <div className={`flex-column col-4 d-inline-flex`}>
                    <button
                      id={`authForgotPassword`}
                      type={`button`}
                      className={`btn btn-secondary`}
                      onClick={() => this.setState({ showResetPassword: true })}
                    >
                      Reset password
                    </button>
                  </div>
                  {/*Modal call*/}
                  <ModalResetPassword show={this.state.showResetPassword}
                                      onClose={() => this.handleCloseDlg()}></ModalResetPassword>
                </div>
                <div id={`signUpSection`} className={`flex-row mt-3 justify-content-between`}>
                  <div className={`flex-column col-8 d-inline-flex`}>
                    <strong>No account yet?</strong>
                  </div>
                  <div className={`flex-column col-4 d-inline-flex`}>
                    <a id={`authSignUp`} type={`button`} className={`btn btn-secondary`} href={`/register`}>
                      Sign up
                    </a>
                  </div>
                </div>
                <div id={`contactUsSection`} className={`flex-row mt-3`}>
                  <div className={`flex-column col-8 d-inline-flex`}>
                    <strong>You did not find what you looked for?</strong>
                  </div>
                  <div className={`flex-column col-4 d-inline-flex`}>
                    <a href={`mailto:${process.env.REACT_APP_MAIL_TO}`} id={`authContactUs`} type={`button`}
                       className={`btn btn-secondary`}>
                      Contact us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    userid: state.userid,
    firstname: state.firstname,
    lastname: state.lastname,
    scope: state.scope,
    cgu: state.cgu,
    canDeleteCompaniesAndDevices: state.canDeleteCompaniesAndDevices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserToken: (token) => {
      dispatch(updateUserToken(token));
    },
    updateUserLastName: (lastname) => {
      dispatch(updateUserLastName(lastname));
    },
    updateUserFirstName: (firstname) => {
      dispatch(updateUserFirstName(firstname));
    },
    updateUserScope: (scope) => {
      dispatch(updateUserScope(scope));
    },
    updateUserId: (userid) => {
      dispatch(updateUserId(userid));
    },
    updateCompanySlug: (companySlug) => {
      dispatch(updateCompanySlug(companySlug));
    },
    updateUserCgu: (cgu) => {
      dispatch(updateUserCgu(cgu));
    },
    updateUserCanDeleteCompaniesAndDevices: (canDeleteCompaniesAndDevices) => {
      dispatch(updateUserCanDeleteCompaniesAndDevices(canDeleteCompaniesAndDevices));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
