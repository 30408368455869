import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React from 'react';

export default function ModalAddReview(props) {
  return (
    <Modal show={props.show} onHide={props.onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add review</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>All mandatory fields are marked with an *</div>
        <span className='me-1 is-required'>Please enter your comment bellow:</span>
        <textarea
          className='w-100 form-control mt-2'
          rows={5}
          id='review'
          onChange={(e) => {
            props.onChange(e);
          }}
        ></textarea>
        {props.isErrorReviewValidation ? <p className='errorMessage mt-2'>{props.validationErrorMessage}</p> : ''}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onClose}>
        Cancel
        </Button>
        <Button variant="primary" onClick={() => props.onAddReview()}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
