import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import UserService from '../services/user.service';
import { EMAIL_REGEX } from '../models/constants';

const ModalResetPassword = (props) => {
  const [email, setEmail] = useState('');
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [showActionError, setActionError] = useState(false);

  async function validForm() {
    if (email.trim().length === 0) {
      setError(true);
      return;
    }
    if (!EMAIL_REGEX.test(email.trim())) {
      setError(true);
      return;
    }
    const body = { email: email };
    try {
      const response = await UserService.passwordReset(body);
      if (response.status === 204) {
        setSuccess(true);
      } else {
        setActionError(true);
      }
    } catch (e) {
      console.log('dans catch : ' + JSON.stringify(e));
    }
  }

  return (
    <Modal show={props.show} onHide={props.onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Reset password</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: '150px' }}>
        <div className='mt-2'>All mandatory fields are marked with an *</div>
        <label htmlFor={`email`} className='mt-3 is-required'>
          Email
        </label>
        <input
          className='w-100 form-control mt-2'
          type={`email`}
          id={`email`}
          placeholder={`email@ememple.com`}
          onChange={(e) => {
            setEmail(e.target.value);
            if (e.target.value.trim().length > 0) {
              setError(false);
            }
            if (!EMAIL_REGEX.test(e.target.value.trim())) {
              setError(true);
            }
          }}
          value={email}
        />

        {showError ? <p className='errorMessage mt-2'>Please enter an email</p> : null}
        {showSuccess ? (
          <div className='alert alert-success mt-2'>
            <span className='alert-icon'>
              <span className='visually-hidden'>Success</span>
            </span>

            <p className=''>Email with a reset link has been sent you successfully</p>
          </div>
        ) : null}
        {showActionError ? (
          <div className='alert alert-danger mt-2'>
            <span className='alert-icon'>
              <span className='visually-hidden'>Error</span>
            </span>

            <p className=''>Failed to send email with a reset link</p>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onClose}>
        Cancel
        </Button>
        <Button variant="primary" onClick={() => validForm()}>
          Send me a link
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalResetPassword;
