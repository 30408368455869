import React, { Component } from 'react';
import '../styles/Header.css';
import { createBrowserHistory } from 'history';
import { AppProps } from '../models/Props';
import { FOREGROUND_SCOPE } from '../models/constants';
import { Link } from 'react-router-dom';

export class HeaderProps {
  public appProps: AppProps;
  public history: any;
}

export class Header extends Component<HeaderProps> {
  render() {
    const location = window.location.pathname;
    const partnerLabel =
      this.props.appProps.scope === FOREGROUND_SCOPE.ORANGE_ADMIN || this.props.appProps.scope === FOREGROUND_SCOPE.ORANGE_USER
        ? 'Partners'
        : 'My company';

    const partnerRef =
      this.props.appProps.scope === FOREGROUND_SCOPE.ORANGE_ADMIN || this.props.appProps.scope === FOREGROUND_SCOPE.ORANGE_USER
        ? '/companies'
        : `/companies/${this.props.appProps.companySlug}/edit`;

    return (
      <header>
        <nav role="navigation" className="navbar navbar-dark bg-dark navbar-expand-md" aria-label="Main navigation">
          {window.location.pathname === '/cgu' || window.location.pathname === '/changePassword' ? (
            <div className="container-fluid navbar-brand d-flex flex-row">
              <div id="orangeLogo" className={`pb-0 d-flex flex-row align-items-end`} role={`button`}>
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/orange-logo.svg'}
                  width="50"
                  height="50"
                  alt="Orange logo Back to home page"
                  loading="lazy"
                />
                <span className={`text-decoration-none ms-3 pb-0`}>Foreground</span>
              </div>
            </div>
          ) : (
            <div className="container-fluid">
              <Link className={`navbar-brand d-flex flex-row`} to="/">
                <div id="orangeLogo" className={`pb-0 d-flex flex-row align-items-end`} role={`button`}>
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/orange-logo.svg'}
                    width="50"
                    height="50"
                    alt="Orange logo Back to home page"
                    loading="lazy"
                  />
                  <span className={`text-decoration-none ms-3 pb-0`}>Foreground</span>
                </div>
              </Link>
              <div
                className={`d-flex flex-row w-100 collapse navbar-collapse justify-content-between align-items-baseline`}
                id="orange-navbar-collapse"
              >
                <div id="global-header-0.1" className="navbar-collapse collapse me-lg-auto global-header-0">
                  {!!this.props.appProps.scope ? (
                    <ul className={`navbar-nav`}>
                      <li id="products-li" className={`nav-item nav-link ${location.startsWith('/products') ? 'active' : ''}`}>
                        <Link id="products" to={`/products`} className="text-decoration-none">
                          Products
                        </Link>
                      </li>
                      <li className={`nav-item nav-link ${location.startsWith('/companies') ? 'active' : ''}`}>
                        <Link id="companies" to={partnerRef} className="text-decoration-none">
                          {partnerLabel}
                        </Link>
                      </li>

                      {this.props.appProps.scope === FOREGROUND_SCOPE.ORANGE_ADMIN && (
                        <li className={`nav-item dropdown`}>
                          <Link
                            className={`topbar-btn nav-link dropdown-toggle ${
                              location.startsWith('/partner-users') ||
                              location.startsWith('/plateform-users') ||
                              location.startsWith('/reference-data')
                                ? 'active'
                                : ''
                            }`}
                            data-bs-toggle="dropdown"
                            to={`#`}
                          >
                            Administration
                          </Link>

                          <ul className={`dropdown-menu`}>
                            <li>
                              <Link className={`dropdown-item`} to={`/partner-users`}>
                                Partner Users
                              </Link>
                            </li>
                            <li>
                              <Link className={`dropdown-item`} to={`/plateform-users`}>
                                Plateform Users
                              </Link>
                            </li>
                            <li>
                              <Link className={`dropdown-item`} to={`/reference-data`}>
                                Reference data
                              </Link>
                            </li>
                          </ul>
                        </li>
                      )}
                    </ul>
                  ) : (
                    <ul></ul>
                  )}
                </div>

                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a
                      id="user-profile"
                      href="#"
                      className={`nav-link btn-secondary dropdown-toggle align-items-center headerStyle ${
                        location.startsWith('/user-profile') ? 'active' : ''
                      }`}
                      role={`button`}
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="foreground-Avatar ms-auto icon-27"></i>
                      <span className="sr-only" ref="/user-profile/details">
                        My account
                      </span>
                    </a>
                    {!this.props.appProps.lastname ? (
                      <ul className={`dropdown-menu dropdown-menu-right`}>
                        <li>
                          <Link className="dropdown-item" to={`/login`}>
                            Log in
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      <ul className={`dropdown-menu dropdown-menu-right`}>
                        <li>
                          <Link className={`dropdown-item`} to={`/user-profile/details`}>
                            View account
                          </Link>
                        </li>
                        <li className="dropdown-divider"></li>
                        <li>
                          <span className="dropdown-item-text" ref="#">
                            Connected as
                            <br />
                            {this.props.appProps.firstname}
                            <br />
                            {this.props.appProps.lastname}
                          </span>
                        </li>
                        <li className="dropdown-divider"></li>
                        <li>
                          <Link
                            className={`dropdown-item fs-3`}
                            to={`/`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.props.appProps.logout();
                            }}
                          >
                            Log out
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </nav>
      </header>
    );
  }
}

export default createBrowserHistory();
