/**
 * Event add notification
 * @param e
 */
export const addNotificationEvent = (e) => {
  let container = document.querySelector('#containerNotifications');
  container.innerHTML = `<div class="alert alert-${
    e.detail.type
  } alert-dismissible fade show bg-white" role="alert"><span class="alert-icon"><span class="visually-hidden">${
    e.detail.type
  }</span></span><span><span id="alertText" >${
    e.detail.category && e.detail.category === 'error' ? 'Error! ' : 'Great! '
  }</span><span id="alertTextDetail" >${
    e.detail.content
  }</span></span><button type="button" class="btn-close" data-bs-dismiss="alert"><span class="visually-hidden">Close</span></button></div>`;
  setTimeout(() => {
    container.innerHTML = '';
  }, 5000);
};
