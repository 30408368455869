import React, { Component } from 'react';

export class UserPrivacies extends Component {
  render() {
    return (
      <div className="UserPrivacies" id="userprivacies">
        <div id="layouts_account" className="border-light pt-3">
          <div className="container">
            <span className="display-5">Account Privacies ... To be made</span>
          </div>
        </div>
      </div>
    );
  }
}
