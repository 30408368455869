import React, { Component } from 'react';
import { Company } from '../../models/company';
import './company-management.css';

export class CompanyCardProps {
  public partner: Company;
  public history: any;
}

export class CompanyCard extends Component<CompanyCardProps> {
  constructor(props) {
    super(props);
  }
  render() {
    // const baseUrl = window.location.hostname === 'localhost' || '127.0.0.1' ? 'https://foreground-v3-pre-prod.apps.fr01.paas.tech.orange' : '';
    const baseUrl = process.env.REACT_APP_BASE_URL_API;
    let { partner } = this.props;
    return (
      <div className="company-card" tabIndex={0}
      onKeyDown={(e) => {
        if (e.code === "Enter" ){
          this.props.history.push(`/companies/${partner.slug}`);
        }
      }}
      >
        <div
          className="company-divimg "
          onClick={() => {
            this.props.history.push(`/companies/${partner.slug}`);
          }}
        >
          {!!partner.logo && !!partner.logo.link ? (
            <img
              id={`partner-img-${partner.id}`}
              className="company-img"
              src={`${baseUrl}/public/storage/img/${partner.logo.link}`}
              alt={`${partner.name} logo`}
              onError={(e) => {
                (e.target as HTMLImageElement).onerror = null;
                (e.target as HTMLImageElement).remove();
              }}
            />
          ) : (
            <div className="company-img"></div>
          )}
        </div>
        <div className="company-div-desc">
          <div
            className="company-div-name"
            onClick={() => {
              this.props.history.push(`/companies/${partner.slug}`);
            }}
          >
            <span id={`partner-name-${partner.id}`} className="company-name">
              {partner.name}
            </span>
          </div>
          <div className="company-div-description">
            <p id={`partner-description-${partner.id}`} className="company-description" dangerouslySetInnerHTML={{ __html: partner.description }}></p>
          </div>
          <div className="company-country">
            <span id={`partner-country-${partner.id}`}>{partner.country}</span>
          </div>
        </div>
      </div>
    );
  }
}
