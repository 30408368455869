import moment from 'moment';
import { ReducedCountry } from './country';
import { ForegroundDocument, ForegroundDocumentToAdd } from './document';
import { ForegroundImage, ForegroundImageToUpdate } from './image';
import { User } from './user';
import Utils from '../helpers/Utils';

export class DeviceProductLiveCycle {
  constructor(public id: number, public label: string) {}
}

export class DeviceInformationI18n {
  constructor(public id: number, public countryCode: string, public description: string, public features: string, public website: string) {}
}

export class DeviceModularity {
  constructor(public sensors: string, public regions: string) {}
}

export class Verticals {
  constructor(public id: number, public label: string, public parent: number) {}
}

export class DeviceTypes {
  constructor(public id: number, public label: string, public order: number) {}
}

export class DeviceFrontend {
  constructor(public id: number, public label: string) {}
}

export class DeviceIOTProtocol {
  constructor(public id: number, public label: string, public parent: number = 0) {}
}

export class DeviceIPNorm {
  constructor(public id: number, public label: string) {}
}

export class DeviceMaturity {
  constructor(public id: number, public label: string) {}
}

export class ModemMaker {
  constructor(public id: number, public name: string) {}
}

export class ModuleMaker {
  constructor(public id: number, public name: string) {}
}

export class NetworkInterface {
  constructor(public id: number, public label: string, public connectivity: boolean, public geolocation: boolean = undefined) {}
}

export class Geolocation {
  constructor(public id: number, public label: string, public geolocation: boolean) {}
}

export class RegulationCompliancy {
  constructor(public id: number, public label: string) {}
}

export class DeviceStatus {
  constructor(public id: number, public label: string) {}
}

export class DeviceUseCase {
  constructor(public id: number, public label: string, public parent: number = 0) {}
}

export class CpuMaker {
  constructor(public id: number, public name: string) {}
}

export class BatteryLife {
  constructor(public id: number, public content: string) {}
}

export class DeviceNote {
  constructor(
      public id: number,
      public regulation: number,
      public modularity: number,
      public battery: number,
      public reliability: number,
      public performance: number,
      public connectivity: number,
      public security: number,
      public designToCost: number,
      public partner: number,
      public platform: number,
      public maturity: number
  ) {}
}

export class DeviceDocument {
  constructor(
      public id: number,
      public type: DocumentType,
      public name: string,
      public link: string,
      public note: string,
      public approved: boolean,
      public nameApproval: string,
      public createdAt: string,
      public updatedAt: string,
      public fileContent: string,
      public videoUrl: string
  ) {}
}

export class DevicePlatform {
  constructor(public isPlatformAvailable: boolean, public isIntegratedWithLO: boolean) {}
}

export class DeviceReview {
  constructor(
      public id: number,
      public review: string,
      public createdAt: string,
      public updatedAt: string,
      public user: User,
      public deviceId: number
  ) {}
}

export class DeviceCompany {
  constructor(public slug: string, public name: string) {}
}

export class ReducedDevice {
  constructor(
      public id: number,
      public name: string,
      public slug: string,
      public features: string,
      public createdAt: string,
      public updatedAt: string,
      public pricing: number,
      public pricingUnit: string,
      public type: DeviceTypes,
      public company: DeviceCompany,
      public images: Array<ForegroundImage>,
      public versions: Array<DeviceVersion>
  ) {}
}

export class Device {
  public id: number;
  public name: string;
  public slug: string;
  public productLifeCycle: DeviceProductLiveCycle;
  public description: string;
  public dimensionX: string;
  public dimensionY: string;
  public dimensionZ: string;
  public weight: string;
  public powerSupply: boolean;
  public range: number;
  public operatingTemperatureStart: string;
  public operatingTemperatureStop: string;
  public fixation: string;
  public productionCountry: string;
  public coveredArea: string;
  public improvingProductQuality: string;
  public features: string;
  public partnerServices: string;
  public cloud: string;
  public otherUsescases: string;
  public website: string;
  public createdAt: string; //  "2018-11-22 17:27:15"
  public updatedAt: string; //  "2018-11-22 17:27:15"
  public status: DeviceStatus; // "Validated by Orange"
  public dateStatus: string; // "2018-11-22"
  public type: DeviceTypes;
  public maturity: DeviceMaturity;
  public frontend: DeviceFrontend;
  public company: DeviceCompany;
  public batteryLife: BatteryLife;
  public batteryRechargeable: boolean;
  public esim: boolean;
  public orangeChecked: boolean;
  public dateOrangeChecked: string; // "2018-11-22"
  public businessModel: string;
  public pricingFrom1To25: string;
  public pricingFrom26To50: string;
  public pricingFrom51To100: string;
  public pricingFrom101To250: string;
  public pricingFrom251To500: string;
  public pricingFrom501To1000: string;
  public pricingFrom1001To5000: string;
  public pricingFrom5001To10000: string;
  public pricingAbove10000: string;
  public pricingUnit: string;
  public asanaId: string;
  public samplesAvailable: boolean;
  public moduleMaker: ModuleMaker;
  public otherModuleMaker: string;
  public modemMaker: ModemMaker;
  public otherModemMaker: string;
  public cpuMaker: CpuMaker;
  public otherCpuMaker: string;
  public integratedWithLOChecked: boolean;
  public deployedInCountriesChecked: boolean;
  public connectivityUrl: string;
  public images: Array<ForegroundImage>;
  public verticals: Array<Verticals>;
  public usescases: Array<DeviceUseCase>;
  public networks: Array<NetworkInterface>;
  public geolocation: Array<Geolocation>;
  public ipnorms: Array<DeviceIPNorm>;
  public regulations: Array<RegulationCompliancy>;
  public platform: DevicePlatform;
  public notes: DeviceNote;
  public protocols: Array<DeviceIOTProtocol>;
  public versions: Array<DeviceVersion>;
  public versionsToAdd: Array<DeviceVersionToAdd>;
  public versionsToUpdate: Array<string>;
  public versionsToDelete: Array<string>;

  public deviceModularity: DeviceModularity;
  public productionCountries: Array<ReducedCountry>;
  public deployedInCountries: Array<ReducedCountry>;
  public documents: Array<ForegroundDocument>;
  public reviews: Array<DeviceReview>;
  public informationI18N: Array<DeviceInformationI18n>;
  public documentsToAdd: ForegroundDocumentToAdd;
  public documentsToUpdate: Array<ForegroundDocument>;
  public documentsToDelete: Array<number>;

  public imagesToAdd: Array<ForegroundImage>;
  public imagesToUpdate: Array<ForegroundImageToUpdate>;
  public imagesToDelete: Array<number>;
  public iotMarketplace: boolean;

  public esgCsrSustainability: DeviceEsgCsrSustainability;

  constructor() {
    this.id = null;
    this.name = '';
    this.productLifeCycle = null;
    this.description = '';
    this.type = null;
    this.website = null;
    this.connectivityUrl = null;
    this.verticals = [];
    this.usescases = [];
    this.features = '';
    this.samplesAvailable = false;
    this.maturity = null;
    this.dimensionX = null;
    this.dimensionY = null;
    this.dimensionZ = null;
    this.weight = null;
    this.powerSupply = false;
    this.batteryLife = null;
    this.batteryRechargeable = false;
    this.operatingTemperatureStart = null;
    this.operatingTemperatureStop = null;
    this.esim = false;
    this.moduleMaker = null;
    this.otherModuleMaker = '';
    this.modemMaker = null;
    this.otherModemMaker = '';
    this.cpuMaker = null;
    this.otherCpuMaker = '';
    this.regulations = [];
    this.networks = [];
    this.geolocation = [];
    this.ipnorms = [];
    this.protocols = [];
    this.platform = null;
    this.cloud = '';
    this.frontend = null;
    this.productionCountries = [];
    this.productionCountry = null;
    this.deployedInCountries = [];
    this.businessModel = '';
    this.pricingFrom1To25 = null;
    this.pricingFrom26To50 = null;
    this.pricingFrom51To100 = null;
    this.pricingFrom101To250 = null;
    this.pricingFrom251To500 = null;
    this.pricingFrom501To1000 = null;
    this.pricingFrom1001To5000 = null;
    this.pricingFrom5001To10000 = null;
    this.pricingAbove10000 = null;
    this.pricingUnit = '€';
    this.range = 0;
    this.fixation = null;
    this.coveredArea = null;
    this.partnerServices = null;
    this.deviceModularity = null;
    this.improvingProductQuality = null;
    this.otherUsescases = '';
    this.orangeChecked = false;
    this.integratedWithLOChecked = false;
    this.deployedInCountriesChecked = false;
    this.images = [];
    this.documents = [];
    this.informationI18N = [];
    this.esgCsrSustainability = new DeviceEsgCsrSustainability(
      null,
      null,
      false,
      null,
      false,
      null,
      false,
      false,
      false,
      '',
      null,
      false,
      '',
      false,
      '',
      false,
      '',
      false,
      '',
      false,
      false
    );
  }

  get pricingFrom1To25Number() {
    return parseInt(this.pricingFrom1To25);
  }
  get pricingFrom26To50Number() {
    return parseInt(this.pricingFrom26To50);
  }
  get pricingFrom51To100Number() {
    return parseInt(this.pricingFrom51To100);
  }
  get pricingFrom101To250Number() {
    return parseInt(this.pricingFrom101To250);
  }
  get pricingFrom251To500Number() {
    return parseInt(this.pricingFrom251To500);
  }
  get pricingFrom501To1000Number() {
    return parseInt(this.pricingFrom501To1000);
  }

  get pricingFrom1001To5000Number() {
    return parseInt(this.pricingFrom1001To5000);
  }
  get pricingFrom5001To10000Number() {
    return parseInt(this.pricingFrom5001To10000);
  }

  get pricingAbove10000Number() {
    return parseInt(this.pricingAbove10000);
  }
}

export class RestrictedDevice {
  constructor(public name: string, public slug: string) {}
}
/**
 * @typedef RestrictedCompany
 * @property {string} name - name of the company
 * @property {string} slug - slug of the company
 * */
export class RestrictedCompany {
  constructor(public name: string, public slug: string) {}
}

export class DeviceSolution extends Device {
  public devices: Array<RestrictedDevice>;
  public companies: Array<RestrictedCompany>;
  constructor() {
    super();
    this.devices = [];
    this.companies = [];
  }
}

export class DeviceVersionToAdd {
  public hardwareVersion: string;
  public firmwareVersion: string;
  public newVersion : boolean;

  constructor(hardwareVersion: string, firmwareVersion: string) {
    this.hardwareVersion = hardwareVersion;
    this.firmwareVersion = firmwareVersion;
    this.newVersion = true;
  }
}

export class DeviceVersionToUpdate {
  public approved: boolean;
  public approvedDate: string;
  public connected: boolean;
  public connectedDate: string;
  public assessed: boolean;
  public assessedDate: string;
  public technologies: Array<DeviceTechnology>;
  public testedBy: boolean;
  public testedByName: string;

  constructor(version: DeviceVersion) {
    this.approved = version.approved;
    this.approvedDate = version.approvedDate;
    this.connected = version.connected;
    this.connectedDate = version.connectedDate;
    this.assessed = version.assessed;
    this.assessedDate = version.assessedDate;
    let technologies = [];
    if (!!version.technologies) {
      version.technologies.forEach((technology) => {
        technologies.push(Utils.omit(['image', 'label', 'order'], technology));
      });
    }
    this.technologies = technologies;
    this.testedBy = version.testedBy;
    this.testedByName = version.testedByName;
  }
}

export class DeviceVersion {
  public id: string;
  public approved: boolean;
  public approvedDate: string;
  public connected: boolean;
  public connectedDate: string;
  public assessed: boolean;
  public assessedDate: string;
  public hardwareVersion: string;
  public firmwareVersion: string;
  public createdAt: string;
  public updatedAt: string;
  public technologies: Array<DeviceTechnology>;
  public testedBy: boolean;
  public testedByName: string;
  public newVersion: boolean;

  constructor(version: DeviceVersionToAdd) {
    this.id = `TEMP-${moment().format('X')}`;
    this.hardwareVersion = version.hardwareVersion;
    this.firmwareVersion = version.firmwareVersion;
    this.approved = false;
    this.approvedDate = '';
    this.connected = false;
    this.connectedDate = '';
    this.assessed = false;
    this.assessedDate = '';
    this.technologies = [];
    this.testedBy = false;
    this.testedByName = '';
    this.newVersion=version.newVersion;
  }
}

export function deviceIsAssessed(device: DeviceSolution) {
  const version = device.versions.find((v) => v.assessed);
  return !!version;
}
export function deviceIsConnected(device: DeviceSolution) {
  const version = device.versions.find((v) => v.connected);
  return !!version;
}

export function deviceIsApproved(device: DeviceSolution) {
  const version = device.versions.find((v) => v.approved);
  return !!version;
}

export class DeviceCountItem {
  constructor(public id: number, public label: string, public nbDevice) {}
}

export class DeviceCount {
  public deviceStatus: DeviceCountItem[];
  public deviceTypes: DeviceCountItem[];
  public companies: DeviceCountItem[];
  public networkInterface: DeviceCountItem[];
  public verticals: DeviceCountItem[];
  public usescases: DeviceCountItem[];

  constructor() {
    this.deviceStatus = [];
    this.deviceTypes = [];
    this.companies = [];
    this.networkInterface = [];
    this.verticals = [];
    this.usescases = [];
  }
}

export class DeviceTechnologyImage {
  constructor(public fileContent: string, public name: string, public link?: string) {}
}

export class DeviceTechnology {
  constructor(public order?: number, public label?: string, public image?: DeviceTechnologyImage, public id?: number) {}
}
export class DeviceEsgCsrSustainability {
  constructor(
    public id: number,
    public deviceId: number,
    public useRecycledMaterials: boolean,
    public percentageRecycledMaterials: number,
    public evaluateCarbonImpact: boolean,
    public carbonImpact: number,
    public lifecycleAssessment: boolean,
    public canShare: boolean,
    public evaluateEnvironmentalImpactReduction: boolean,
    public environmentalImpactReduction: string,
    public expectedLifeSpan: number,
    public refurbishingProcess: boolean,
    public docsForRefurbishing: string,
    public repairingProcess: boolean,
    public docsForRepairing: string,
    public frenchMarketProducerRole: boolean,
    public ecoOrganismAffiliation: string,
    public ecMarketProducerRole: boolean,
    public ecCountriesNote: string,
    public ecRegulationsCompliance: boolean,
    public reachRegulationsCompliance: boolean
  ) {}
}
