import React from 'react';
import './spinner.css';

export default function LoadingSpinner() {
  return (
    <div className="d-flex flex-row spinner-div justify-content-center mt-4">
      <div className=" loading-spinner"></div>
    </div>
  );
}
