const initState = {
  token: null,
  firstname: null,
  lastname: null,
  scope: null,
  userid: null,
  companySlug: null,
  cgu: null,
  canDeleteCompaniesAndDevices: null,
};

const rootReducer = (state = initState, action) => {
  if (action.type === 'UPDATE_USER_TOKEN') {
    return { ...state, token: action.token };
  } else {
    if (action.type === 'UPDATE_USER_FIRST_NAME') {
      return { ...state, firstname: action.firstname };
    } else {
      if (action.type === 'UPDATE_USER_LAST_NAME') {
        return { ...state, lastname: action.lastname };
      } else {
        if (action.type === 'UPDATE_USER_SCOPE') {
          return { ...state, scope: action.scope };
        } else {
          if (action.type === 'UPDATE_USER_ID') {
            return { ...state, userid: action.userid };
          } else {
            if (action.type === 'UPDATE_COMPANY_SLUG') {
              return { ...state, companySlug: action.companySlug };
            } else {
              if (action.type === 'UPDATE_USER_CGU') {
                return { ...state, cgu: action.cgu };
              } else {
                if (action.type === 'UPDATE_USER_CAN_DELETE_COMPAGNIES_AND_DEVICES') {
                  return { ...state, canDeleteCompaniesAndDevices: action.canDeleteCompaniesAndDevices };
                }
              }
            }
          }
        }
      }
    }
  }
  return state;
};

export default rootReducer;
