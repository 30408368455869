import { FOREGROUND_SCOPE } from './constants';

export class AppProps {
  public token: string;
  public userid: string;
  public firstname: string;
  public lastname: string;
  public scope: FOREGROUND_SCOPE;
  public companySlug: string;
  public canDeleteCompaniesAndDevices: boolean;

  public logout() {}

  constructor(
    token: string,
    userid: string,
    firstname: string,
    lastname: string,
    scope: FOREGROUND_SCOPE,
    companySlug: string,
    canDeleteCompaniesAndDevices: boolean
  ) {
    this.token = token;
    this.userid = userid;
    this.firstname = firstname;
    this.lastname = lastname;
    this.scope = scope;
    this.companySlug = companySlug;
    this.canDeleteCompaniesAndDevices = canDeleteCompaniesAndDevices;
  }
}

export class PropsMatchParams {
  id: string;
  token: string;
}

export class PropsMatch {
  public path: string;
  public url: string;
  public isExact: boolean;
  public params: PropsMatchParams;
}
