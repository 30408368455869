import instance from '../helpers/InterceptorAxios';

export default class StatisticsService {
  /**
   * List of statistics
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async statistics() {
    const config = { headers: { accept: 'application/json', 'content-type': 'application/x-www-form-urlencoded' } };
    let response = await instance.get(`/api/v1/statistics`, config);
    return response;
  }
}
