import React, { Component } from 'react';
import UserService from '../../services/user.service';
import LoadingSpinner from '../LoadingSpinner';
import { createBrowserHistory } from 'history';
import { BAD_EMAIL_FORMAT, MANDATORY_FIELD_MISSING, ORANGE_EMAIL_REGEX, PLEASE_SELECT_USER_ROLE, USER_TYPE } from '../../models/constants';
import { UserToUpdate, UserTypes } from '../../models/user';
import { ForegroundOption, ForegroundSelectSingle, SELECT_ACTION } from '../ForegroundSelect';
import { PageHeader } from '../PageHeader';

class AddOrangeUserProps {
  history: any;
}

class AddOrangeUserState {
  public userTypeList: Array<ForegroundOption>;
  public isLoading: boolean;
  public firstName: string;
  public isErrorOnFirstName: boolean;
  public lastName: string;
  public isErrorOnLastName: boolean;
  public email: string;
  public isErrorOnEmail: boolean;
  public currentType: ForegroundOption;
  public isErrorOnType: boolean;
  public isError: boolean;
  public errorMessage: string;
  public submitDisabled:boolean;


  constructor() {
    this.userTypeList = [];
    this.isLoading = true;

    this.firstName = '';
    this.isErrorOnFirstName = false;
    this.lastName = '';
    this.isErrorOnLastName = false;
    this.email = '';
    this.isErrorOnEmail = false;
    this.currentType = null;
    this.isErrorOnType = false;
    this.isError = false;
    this.errorMessage = '';
    this.submitDisabled = false;
  }
}

export class AddOrangeUser extends Component<AddOrangeUserProps, AddOrangeUserState> {
  constructor(props) {
    super(props);
    this.state = new AddOrangeUserState();
    this.handleChangeUserType = this.handleChangeUserType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      let response = await UserService.userTypesList();

      const userTypeList = response.data
        .filter((item) => {
          return item.id !== USER_TYPE.PARTNER && item.id !== USER_TYPE.ALSTOM;
        })
        .map((item) => {
          return {
            label: item.label,
            value: item.id,
          };
        });
      this.setState({
        userTypeList: userTypeList,
        isLoading: false,
      });
    } catch (error) {
      console.log('AddOrangeUser : ' + JSON.stringify(error));
      this.setState({ isLoading: false });
    }
  }

  renderForm() {
    let { firstName, lastName, email, currentType } = this.state;

    const userTypes = this.state.userTypeList;

    return (
      <div className="container">
        <form id="myForm" className="mt-3" onSubmit={this.handleSubmit}>
          <div className='row'>
            <div className='col-md-6 offset-md-0'>
              <div className='mb-3'>All mandatory fields are marked with an *</div>
              <div className='form-group mb-3'>
                <label htmlFor='firstname' className='is-required'>
                  First name
                </label>
                <input
                  type='text'
                  className='form-control mt-2'
                  id='firstname'
                  name='firstname'
                  maxLength={1000}
                  value={firstName}
                  onChange={this.handleChangeFirstName}
                  required
                />
                {this.state.isErrorOnFirstName ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
              </div>
              <div className='form-group mb-3'>
                <label htmlFor='lastname' className='is-required'>
                  Last name
                </label>
                <input
                  type='text'
                  className='form-control mt-2'
                  id='lastname'
                  name='lastname'
                  maxLength={1000}
                  value={lastName}
                  onChange={this.handleChangeLastName}
                  required
                />
                {this.state.isErrorOnLastName ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
              </div>

              <div className='form-group mb-3'>
                <label htmlFor='email' className='is-required'>
                  Email
                </label>
                <input
                  type='email'
                  className='form-control mt-2'
                  id='email'
                  name='email'
                  maxLength={1000}
                  value={email}
                  onChange={this.handleChangeEmail}
                  required
                />
                {this.state.isErrorOnEmail ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
              </div>

              <div className='form-group mb-3'>
                <label htmlFor='job' className='is-required'>
                  User role
                </label>
                <ForegroundSelectSingle
                  inputId='job'
                  placeholder={PLEASE_SELECT_USER_ROLE}
                  value={currentType}
                  options={userTypes}
                  onChange={(e, a) => this.handleChangeUserType(e, a)}
                />

                {this.state.isErrorOnType ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
              </div>

              <div className='form-group my-3 d-flex flex-row justify-content-end'>
                <button onClick={() => this.props.history.push('/plateform-users')} className='me-3 btn btn-secondary'>
                  Cancel
                </button>
                <button type='submit' className='btn btn-primary' disabled={this.state.submitDisabled}>
                  Add
                </button>
                <br></br>
              </div>
            </div>
            {this.state.isError ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <>
        <PageHeader title="Add Plateform user"></PageHeader>

        {this.state.isLoading ? <LoadingSpinner /> : this.renderForm()}
      </>
    );
  }

  handleChangeFirstName = (event) => {
    const value = event.target.value;
    this.setState({ firstName: value });
    this.setState({ isErrorOnFirstName: false });
  };

  handleChangeLastName = (event) => {
    const value = event.target.value;
    this.setState({ lastName: value });
    this.setState({ isErrorOnLastName: false });
  };

  handleChangeEmail = (event) => {
    const value = event.target.value;
    this.setState({ email: value });
    this.setState({ isErrorOnEmail: false });
  };

  handleChangeUserType(event: ForegroundOption, action: SELECT_ACTION) {
    if (action === SELECT_ACTION.CLEAR) {
      this.setState({ currentType: null, isErrorOnType: true, errorMessage: PLEASE_SELECT_USER_ROLE });
    } else {
      this.setState({ currentType: event, isErrorOnType: false });
    }
  }

  validate() {
    if (this.state.firstName.trim().length === 0) {
      this.setState({ isErrorOnFirstName: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }

    if (this.state.lastName.trim().length === 0) {
      this.setState({ isErrorOnLastName: true, errorMessage: MANDATORY_FIELD_MISSING });

      return false;
    }

    if (!ORANGE_EMAIL_REGEX.test(this.state.email)) {
      this.setState({ isErrorOnEmail: true, errorMessage: BAD_EMAIL_FORMAT });

      return false;
    }

    if (this.state.currentType === null) {
      this.setState({ isErrorOnType: true, errorMessage: PLEASE_SELECT_USER_ROLE });

      return false;
    }

    return true;
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.state.submitDisabled) return
    this.setState({ isError: false });

    if (!this.validate()) {
      console.log(`error validate`);
      return;
    }
    this.setState({ submitDisabled:true });

    const userType = new UserTypes(this.state.currentType.value, this.state.currentType.label);
    const body = new UserToUpdate(this.state.firstName, this.state.lastName, this.state.email, null, null, userType, null, null);

    try {
      const res = await UserService.createUser(body);
      if (res.status === 201) {
        document.dispatchEvent(
          new CustomEvent('addNotification', {
            detail: {
              type: 'success',
              content: `The user ${this.state.firstName} ${this.state.lastName} has been added.`,
            },
          })
        );
        this.props.history.push('/plateform-users');
      } else {
        if (res.status >= 400) {
          this.setState({ isError: true, errorMessage: res.data.message });
        }
      }
      this.setState({ submitDisabled:false })
    } catch (e) {
      let message = 'Error';
      this.setState({ isError: true, errorMessage: message, submitDisabled:false });
    }
  }
}

export default createBrowserHistory();
