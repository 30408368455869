import instance from '../helpers/InterceptorAxios';

export default class CountryService {
  /**
   * List of countries
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async list(orderBy: string) {
    const params = {};
    if (!!orderBy) {
      params['orderBy'] = orderBy;
    }
    return await instance.get(`api/v1/countries`, { params: params });
  }
}
