import React, { Component } from 'react';

export class Footer extends Component {
  render() {
    const year = process.env.REACT_APP_COPYRIGHT_YEAR;
    return (
      <footer className={`bg-dark py-3 my-3`} role="contentinfo">
        <h2 className={`visually-hidden`}>Footer - informations</h2>
        <div className={`footer-bottom`}>
          <div className={`container`}>
            <ul className={`nav justify-content-center`}>
              <li className={`nav-item`}>
                <span className={`nav-link`}>© Orange {year}</span>
              </li>
              <li className={`nav-item`}>
                <a className={`nav-link`} href={`/legal-matters`}>
                  Legal matters
                </a>
              </li>
              <li className={`nav-item`}>
                <a className={`nav-link`} href={`/terms-conditions`}>
                  Terms & Conditions
                </a>
              </li>
              <li className={`nav-item`}>
                <a className={`nav-link`} href={`/privacy-policy`}>
                  Privacy policy
                </a>
              </li>
              <li className={`nav-item`}>
                <a className={`nav-link`} href={`/accessibility`}>
                  Accessibility : partially compliant
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}
