import { Component } from 'react';
import { cloneDeep } from 'lodash';
import { createBrowserHistory } from 'history';
import LoadingSpinner from '../../components/LoadingSpinner';

import './product_management.css';
import { WizardHeader } from '../../components/Wizard/WizardHeader';
import { WizardFooter } from '../../components/Wizard/WizardFooter';
import { AVAILABLE_LANGUAGES, FOREGROUND_SCOPE, SESSION_STORAGE_DOC_EDIT_ONLY } from '../../models/constants';

import { Device, DeviceEsgCsrSustainability, DeviceInformationI18n, DeviceVersionToUpdate } from '../../models/device';
import ProductService from '../../services/product.service';
import { DeviceInformationStep } from './DeviceInformationStep';
import { DeviceDescriptionStep } from './DeviceDescriptionStep';
import { DeviceFeatureStep } from './DeviceFeatureStep';
import { DevicePricingStep } from './DevicePricingStep';
import { DeviceDocumentStep } from './DeviceDocumentStep';
import { DeviceVersionStep } from './DeviceVersionStep';
import DocumentService from '../../services/document.service';
import { AppProps, PropsMatch } from '../../models/Props';
import { DeviceESGCSRStep } from './DeviceESGCSRStep';

class DeviceCreateModifyProps {
  public appProps: AppProps;
  public history: any;
  public match: PropsMatch;
}

class DeviceCreateModifyState {
  isLoading: boolean;
  currentStep: number;
  device: Device;
  unmodifyDevice: Device;
  isCreation: boolean;
  isError: boolean;
  errorMessage: string;
  wizardStep: Array<string>;
  docOnlyMode: boolean;

  constructor() {
    this.isLoading = true;
    this.currentStep = 1;
    this.device = new Device();
    this.unmodifyDevice = null;
    this.isCreation = true;
    this.isError = false;
    this.errorMessage = '';
    this.docOnlyMode = sessionStorage.getItem(SESSION_STORAGE_DOC_EDIT_ONLY) === SESSION_STORAGE_DOC_EDIT_ONLY;
  }
}

enum DEVICE_WIZARD_STEP {
  INFORMATION = 'Information',
  VERSIONS = 'Versions',
  DESCRIPTION = 'Description',
  FEATURES = 'Features',
  ESG_CSR = 'ESG/CSR or Sustainability policy',
  PRICING = 'Pricing',
  DOCUMENT = 'Documents',
}

export class DeviceCreateModify extends Component<DeviceCreateModifyProps, DeviceCreateModifyState> {
  private childDeviceInformationStep: any;
  private childDeviceVersionStep: any;
  private childDeviceDescriptionStep: any;
  private childDeviceFeatureStep: any;
  private childDeviceESG_CSRStep: any;
  private childDevicePricingStep: any;
  private childDeviceDocumentStep: any;

  constructor(props) {
    super(props);
    this.state = new DeviceCreateModifyState();
    this.onStepChange = this.onStepChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onPrevious = this.onPrevious.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const id = this.props.match.params.id;
      if (!!id && id !== 'add') {
        const response = await ProductService.deviceAllDetails(id);
        const informationI18N = response[1].data;
        let device = { ...response[0].data, informationI18N };
        if (!response[0].data.esgCsrSustainability) {
          const emptyEsgCSR = new DeviceEsgCsrSustainability(
            null,
            null,
            false,
            null,
            false,
            null,
            false,
            false,
            false,
            null,
            null,
            false,
            null,
            false,
            null,
            false,
            null,
            false,
            null,
            false,
            false
          );
          device = { ...device, esgCsrSustainability: emptyEsgCSR };
        }
        const deviceCopy = cloneDeep(device);
        AVAILABLE_LANGUAGES.forEach((l) => {
          const trad = device.informationI18N.find((i) => i.countryCode === l);
          if (l !== 'EN' && !!!trad) {
            device.informationI18N.push(new DeviceInformationI18n(null, l, null, null, null));
          }
        });
        const wizardStep = [];
        if (!this.state.docOnlyMode) {
          wizardStep.push(DEVICE_WIZARD_STEP.INFORMATION);
          wizardStep.push(DEVICE_WIZARD_STEP.VERSIONS);
          wizardStep.push(DEVICE_WIZARD_STEP.DESCRIPTION);
          wizardStep.push(DEVICE_WIZARD_STEP.FEATURES);
          wizardStep.push(DEVICE_WIZARD_STEP.ESG_CSR);
          wizardStep.push(DEVICE_WIZARD_STEP.PRICING);
        }
        wizardStep.push(DEVICE_WIZARD_STEP.DOCUMENT);
        this.setState({
          isLoading: false,
          device: device,
          unmodifyDevice: deviceCopy,
          isCreation: false,
          wizardStep: wizardStep,
        });
      } else {
        const wizardStep = [];
        wizardStep.push(DEVICE_WIZARD_STEP.INFORMATION);
        wizardStep.push(DEVICE_WIZARD_STEP.DESCRIPTION);
        wizardStep.push(DEVICE_WIZARD_STEP.FEATURES);
        wizardStep.push(DEVICE_WIZARD_STEP.ESG_CSR);
        wizardStep.push(DEVICE_WIZARD_STEP.PRICING);
        wizardStep.push(DEVICE_WIZARD_STEP.DOCUMENT);
        this.setState({
          isLoading: false,
          wizardStep: wizardStep,
        });
      }
    } catch (error) {
      console.log(`ERROR Device : ${JSON.stringify(error)}`);
      this.setState({ isLoading: false });
    }
  }

  onStepChange(step: number) {
    const currentStep = this.state.currentStep;
    let allAreValid = true;
    for (let i = currentStep; i < step; i++) {
      const child = this.getNextStep(i);
      if (!!child) {
        const valide = child.validate();
        if (!valide) {
          allAreValid = false;
          this.setState({
            currentStep: i,
          });
          break;
        }
      }
    }
    if (allAreValid) {
      this.setState({
        currentStep: step,
      });
    }
  }

  onCancel() {
    if (this.state.isCreation) {
      this.props.history.push('/products');
    } else {
      this.props.history.push(`/products/${this.state.device.slug}`);
    }
  }

  onPrevious() {
    const currentStep = this.state.currentStep;

    this.setState({
      currentStep: currentStep - 1,
    });
  }

  onNext() {
    const currentStep = this.state.currentStep;
    const child = this.getNextStep(currentStep);
    if (!!child) {
      const valide = child.validate();
      if (valide) {
        this.setState({
          currentStep: currentStep + 1,
        });
      }
    } else {
      this.setState({
        currentStep: currentStep + 1,
      });
    }
  }

  getNextStep(currentStep) {
    const wizardName = this.state.wizardStep[currentStep - 1];
    switch (wizardName) {
      case DEVICE_WIZARD_STEP.INFORMATION:
        return this.childDeviceInformationStep;
      case DEVICE_WIZARD_STEP.VERSIONS:
        return this.childDeviceVersionStep;
      case DEVICE_WIZARD_STEP.DESCRIPTION:
        return this.childDeviceDescriptionStep;
      case DEVICE_WIZARD_STEP.FEATURES:
        return this.childDeviceFeatureStep;
      case DEVICE_WIZARD_STEP.ESG_CSR:
        return this.childDeviceESG_CSRStep;
      case DEVICE_WIZARD_STEP.PRICING:
        return this.childDevicePricingStep;
      case DEVICE_WIZARD_STEP.DOCUMENT:
        return this.childDeviceDocumentStep;

      default:
        return null;
    }
  }

  async onSubmit() {
    if (!this.childDeviceDocumentStep.validate()) {
      return;
    }
    if (this.state.isCreation) {
      try {
        const res = await ProductService.createDevice(this.props.appProps.companySlug, this.state.device);

        if (res.status === 201) {
          const otherCreation = [];
          this.state.device.informationI18N.forEach((t) => {
            if (!!t.description || !!t.features || !!t.website) {
              otherCreation.push(ProductService.createDeviceinformantionI18N(res.data.slug, t.countryCode, t));
            }
          });

          if (otherCreation.length > 0) {
            await Promise.all(otherCreation)
              .then(() => {
                  this.onSuccess(res.data.slug);
                })
              .catch(() => {
                  this.setState({ isError: true, errorMessage: 'Error during the request (please reload the page)' });
              });
          } else {
            this.onSuccess(res.data.slug);
          }
        } else {
          if (res.status >= 400) {
            this.setState({ isError: true, errorMessage: res.data.message });
          }
        }
      } catch (e) {
        let message = 'Error';
        this.setState({ isError: true, errorMessage: message });
      }
    } else {
      // modification of existing device
      // Refactoring to be made = foreach to be replaced by for !!!
      // - Benjamin's advice, from June 2023

      const otherUpdate = [];

      try {
        if (!this.state.docOnlyMode) {
          const res = await ProductService.updatedevice(
            this.state.device.company.slug,
            this.state.device.slug,
            this.state.device,
            this.props.appProps.scope
          );
          if (res.status === 204) {
            this.state.device.informationI18N.forEach((t) => {
              const previousState = this.state.unmodifyDevice.informationI18N.find((p) => p.countryCode === t.countryCode);
              if (!!t.description || !!t.features || !!t.website) {
                if (!!previousState) {
                  otherUpdate.push(ProductService.updateDeviceinformantionI18N(this.state.device.slug, t.countryCode, t));
                } else {
                  otherUpdate.push(ProductService.createDeviceinformantionI18N(this.state.device.slug, t.countryCode, t));
                }
              } else {
                if (!!previousState) {
                  otherUpdate.push(ProductService.updateDeviceinformantionI18N(this.state.device.slug, t.countryCode, t));
                }
              }
            });

            if (!!this.state.device.imagesToUpdate) {
              this.state.device.imagesToUpdate.forEach((i) => {
                otherUpdate.push(ProductService.modifyImage(i));
              });
            }

            if (!!this.state.device.imagesToDelete) {
              this.state.device.imagesToDelete.forEach((id) => {
                otherUpdate.push(ProductService.deleteImage(id));
              });
            }

            if (!!this.state.device.imagesToAdd) {
              this.state.device.imagesToAdd.forEach((i) => {
                otherUpdate.push(ProductService.addImage(this.state.device.slug, i));
              });
            }

            if (!!this.state.device.versionsToAdd) {
              this.state.device.versionsToAdd.forEach((v) => {
                otherUpdate.push(ProductService.addVersion(this.state.device.slug, v));
              });
            }

            if (!!this.state.device.versionsToUpdate) {
              this.state.device.versionsToUpdate.forEach((id) => {
                const version = this.state.device.versions.find((v) => v.id === id);
                const versionToUpdate = new DeviceVersionToUpdate(version);
                otherUpdate.push(ProductService.modifyVersion(this.state.device.slug, id, versionToUpdate));
              });
            }

            if (!!this.state.device.versionsToDelete) {
              this.state.device.versionsToDelete.forEach((id) => {
                otherUpdate.push(ProductService.deleteVersion(this.state.device.slug, id));
              });
            }

            if (otherUpdate.length > 0) {
              await Promise.all(otherUpdate)
                .then(() => {
                this.onSuccess(this.state.device.slug);
              })
                .catch(() => {
                  this.setState({ isError: true, errorMessage: 'Error during the request (please reload the page)' });
                });
            }
          }
        }
        if (!!this.state.device.documentsToUpdate) {
          this.state.device.documentsToUpdate.forEach((d) => {
            otherUpdate.push(DocumentService.updateDocument(d.id, d));
          });
        }

        if (!!this.state.device.documentsToDelete) {
          this.state.device.documentsToDelete.forEach((id) => {
            otherUpdate.push(DocumentService.deleteDocument(id));
          });
        }

        if (!!this.state.device.documentsToAdd) {
          otherUpdate.push(
            DocumentService.createDeviceDocument(this.state.device.company.slug, this.state.device.slug, this.state.device.documentsToAdd)
          );
        }
        this.onSuccess(this.state.device.slug);
      } catch (e) {
        let message = 'Error (device modification impossible)';
        this.setState({ isError: true, errorMessage: message });
      }
    }
  }

  onSuccess(deviceSlug) {
    let contentEnd = '';
    if (!this.state.docOnlyMode) {
      contentEnd = this.state.isCreation ? ' has been created.' : ' has been updated.';
      sessionStorage.removeItem(SESSION_STORAGE_DOC_EDIT_ONLY);
    }
    document.dispatchEvent(
      new CustomEvent('addNotification', {
        detail: {
          type: 'success',
          content:
            contentEnd === ''
              ? `The documents related to device ${this.state.device.name} have been updated`
              : `The device ${this.state.device.name}${contentEnd}`,
        },
      })
    );

    this.props.history.push(`/products/${deviceSlug}`);
  }
  render() {
    const scope = this.props.appProps.scope;
    const isLoading = this.state.isLoading;

    const isCreation = !!this.state.device && !!!this.state.device.id;

    const canCancel = true;
    const isReadOnly = scope === FOREGROUND_SCOPE.ORANGE_USER || scope === FOREGROUND_SCOPE.ORANGE_VALIDATION;

    const currentStep = this.state.currentStep;
    const displayDeviceInformation = !isLoading && currentStep === 1;
    const displayDeviceVersion = !isLoading && !isCreation && currentStep === 2;
    const displayDeviceDescription = !isLoading && ((isCreation && currentStep === 2) || (!isCreation && currentStep === 3));
    const displayDeviceFeature = !isLoading && ((isCreation && currentStep === 3) || (!isCreation && currentStep === 4));
    const displayDeviceEsr = !isLoading && ((isCreation && currentStep === 4) || (!isCreation && currentStep === 5));
    const displayDevicePricing = !isLoading && ((isCreation && currentStep === 5) || (!isCreation && currentStep === 6));
    const displayDeviceDocument = !isLoading && ((isCreation && currentStep === 6) || (!isCreation && currentStep === 7));

    const canGoForward = !isCreation;

    document.title = isCreation ? 'Create Device':'Modify Device';
    return (
      <div className="p-3 bg-white">
        <h1 id="device-wizard-title" className="display-2 mt-3">
          {!isCreation ? this.state.device.name : 'Add Device'}
        </h1>
        {this.state.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div style={{ marginTop: '20px' }}></div>
            <WizardHeader
              currentStep={currentStep}
              stepLabels={this.state.wizardStep}
              canGoForward={canGoForward}
              onStepChange={this.onStepChange}
            ></WizardHeader>

            <div style={{ marginTop: '20px', marginBottom: '20px', minHeight: this.state.docOnlyMode ? '500px' : '1250px' }}>
              <DeviceInformationStep
                device={this.state.device}
                scope={scope}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childDeviceInformationStep = instance;
                }}
                shouldDisplay={displayDeviceInformation && !this.state.docOnlyMode}
              ></DeviceInformationStep>
              <DeviceVersionStep
                device={this.state.device}
                scope={scope}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childDeviceVersionStep = instance;
                }}
                shouldDisplay={displayDeviceVersion && !this.state.docOnlyMode}
              ></DeviceVersionStep>
              <DeviceDescriptionStep
                device={this.state.device}
                scope={scope}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childDeviceDescriptionStep = instance;
                }}
                shouldDisplay={displayDeviceDescription && !this.state.docOnlyMode}
              ></DeviceDescriptionStep>
              <DeviceFeatureStep
                device={this.state.device}
                scope={scope}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childDeviceFeatureStep = instance;
                }}
                shouldDisplay={displayDeviceFeature && !this.state.docOnlyMode}
              ></DeviceFeatureStep>
              <DeviceESGCSRStep
                device={this.state.device}
                scope={scope}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childDeviceESG_CSRStep = instance;
                }}
                shouldDisplay={displayDeviceEsr && !this.state.docOnlyMode}
              ></DeviceESGCSRStep>
              <DevicePricingStep
                device={this.state.device}
                scope={scope}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childDevicePricingStep = instance;
                }}
                shouldDisplay={displayDevicePricing && !this.state.docOnlyMode}
              ></DevicePricingStep>
              <DeviceDocumentStep
                device={this.state.device}
                isReadOnly={isReadOnly}
                ref={(instance) => {
                  this.childDeviceDocumentStep = instance;
                }}
                scope={scope}
                shouldDisplay={displayDeviceDocument || this.state.docOnlyMode}
              ></DeviceDocumentStep>
            </div>

            <WizardFooter
              currentStep={currentStep}
              nbMaxItem={!!this.state.wizardStep ? this.state.wizardStep.length : 0}
              canCancel={canCancel}
              hidePrevious={this.state.docOnlyMode}
              actionLabel={isCreation ? 'Create' : 'Save'}
              onCancel={this.onCancel}
              onPrevious={this.onPrevious}
              onNext={this.onNext}
              onSubmit={this.onSubmit}
              isReadOnly={isReadOnly}
            ></WizardFooter>
          </>
        )}
      </div>
    );
  }
}

export default createBrowserHistory();
