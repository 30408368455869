import React, { Component } from 'react';
import StatisticsService from '../../services/statistics.service';

class ForegroundCatalogProps {}

class ForegroundCatalogState {
  constructor(public nbCompanies: number, public nbDevices: number, public nbCertificates: number) {}
}

export class ForegroundCatalog extends Component<ForegroundCatalogProps, ForegroundCatalogState> {
  constructor(props) {
    super(props);
    this.state = new ForegroundCatalogState(0, 0, 0);
  }

  async componentDidMount() {
    try {
      let response = await StatisticsService.statistics();
      this.setState({
        nbCompanies: response.data.nbCompanies,
        nbDevices: response.data.nbDevices,
        nbCertificates: response.data.nbCertificates,
      });
    } catch (error) {
      console.log('ForegroundCatalog : ' + JSON.stringify(error));
    }
  }

  render() {
    let { nbCompanies, nbDevices, nbCertificates } = this.state;
    return (
      <div className="bg-dark p-4">
        <div className="container fs-2">
          <strong>Foreground</strong>
          <div className="row mt-2">
            <div className="mt-2 col-md-4 display-2 text-center">
              <strong id="nbCompanies" className="text-primary">
                {nbCompanies}
              </strong>
              <p className="mt-2 lead text-center">IOT partners</p>
            </div>
            <div className="mt-2 col-md-4 display-2 text-center">
              <strong id="nbDevices" className="text-primary">
                {nbDevices}
              </strong>
              <p className="mt-2 lead text-center">IoT solutions and devices</p>
            </div>
            <div className="mt-2 col-md-4 display-2 text-center">
              <strong id="nbCertificates" className="text-primary">
                {nbCertificates}
              </strong>
              <p className="mt-2 lead text-center">certificates</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
