import React, { Component } from 'react';

export class SignupLogin extends Component {
  render() {
    return (
      <div className="p-3 bg-white">
        <p className="lead">
          <strong>Already a member </strong>
        </p>
        <strong></strong>
        <p>
          <strong>
            <a id="login" href="/login" className="btn btn-primary">
              Login
            </a>
          </strong>
        </p>
        <p className="lead">
          <strong>Welcome, become a member!</strong>
        </p>
        <ul className="benefits-list p-0 list-unstyled">
          <li>
            <div className="mb-2 btn p-0">
              <i className="icon foreground-Tick"></i>
              Large community of providers
            </div>
          </li>
          <li>
            <div className="mb-2 btn p-0">
              <i className="icon foreground-Tick"></i>
              Repository management tools
            </div>
          </li>
          <li>
            <div className="mb-2 btn p-0">
              <i className="icon foreground-Tick"></i>
              Certification program
            </div>
          </li>
        </ul>
        <a id="signup" className="btn btn-secondary" href={`/register`}>
          Sign up now
        </a>
      </div>
    );
  }
}
