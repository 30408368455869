import React, { Component } from 'react';
import { FOREGROUND_SCOPE } from '../../models/constants';
import './company-management.css';
import { AddDocument } from '../../components/AddDocument';
import Utils from '../../helpers/Utils';
import { ForegroundDocumentToAdd } from '../../models/document';
import { CompanyWizardStepProps } from './CompanyWizard';
import { ForegroundOption } from '../../components/ForegroundSelect';

export class CompanyDocumentStep extends Component<CompanyWizardStepProps> {
  private childAddDocument: any;
  constructor(props) {
    super(props);

    this.handleDocumentChanged = this.handleDocumentChanged.bind(this);
    this.handleVideoDocumentChanged = this.handleVideoDocumentChanged.bind(this);
    this.handleClickApproved = this.handleClickApproved.bind(this);
    this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
  }

  async componentDidMount() {
    try {
    } catch (e) {
      console.error(e);
    }
  }

  validate() {
    return this.childAddDocument.validate();
  }

  handleDocumentChanged(fileContent: string, fileName: string, documentType: ForegroundOption) {
    this.props.company.documentsToAdd = new ForegroundDocumentToAdd(fileName, fileContent, documentType, null, null);
  }

  handleVideoDocumentChanged(videoUrl: string, label: string, documentType: ForegroundOption) {
    this.props.company.documentsToAdd = new ForegroundDocumentToAdd(null, null, documentType, videoUrl, label);
  }

  handleClickApproved(id: number) {
    const document = this.props.company.documents.find((d) => d.id === id);
    if (!!document) {
      document.approved = !document.approved;
      if (!!!this.props.company.documentsToUpdate) {
        this.props.company.documentsToUpdate = [];
      }
      this.props.company.documentsToUpdate.push(document);
    }
    this.setState({ documents: this.props.company.documents });
  }

  handleDeleteDocument(id) {
    const documentIdx = this.props.company.documents.findIndex((d) => d.id === id);
    if (documentIdx !== -1) {
      if (!!!this.props.company.documentsToDelete) {
        this.props.company.documentsToDelete = [];
      }
      this.props.company.documentsToDelete.push(id);
      this.props.company.documents.splice(documentIdx, 1);
    }
    this.setState({ documents: this.props.company.documents });
  }

  render() {
    if (this.props.shouldDisplay) {
      const baseUrl = process.env.REACT_APP_BASE_URL_API;
      // const baseUrl = window.location.hostname === 'localhost' || '127.0.0.1' ? 'https://foreground-v3-pre-prod.apps.fr01.paas.tech.orange' : '';
      const canApprouved = this.props.scope === FOREGROUND_SCOPE.ORANGE_ADMIN;
      const canDelete =
        this.props.scope === FOREGROUND_SCOPE.ORANGE_ADMIN ||
        this.props.scope === FOREGROUND_SCOPE.PARTNER ||
        this.props.scope === FOREGROUND_SCOPE.ORANGE_PARTNER;

      return (
        <>
          <div className='row'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-3 mt-1'>All mandatory fields are marked with an *</div>
              </div>
            </div>
            <table className='table'>
              <thead>
              <tr>
                <th scope='col' className='col-md-6'>
                  <p className='text-small'>Name</p>
                </th>
                <th scope='col'>
                  <p className='text-small'>Approved by Orange</p>
                </th>
                <th scope='col'>
                  <p className='text-small'>Type</p>
                </th>
                <th scope='col'>
                  <p className='text-small'>Category</p>
                </th>
                <th scope='col'>
                  <p className='text-small'>Last modification</p>
                </th>
                {canDelete ? (
                  <th scope='col'>
                    <p className='text-small'>Action</p>
                  </th>
                ) : null}
              </tr>
              </thead>
              <tbody>
              {!!this.props.company.documents
                ? this.props.company.documents.map((d) => {
                  return (
                    <tr key={d.id}>
                      <td>
                        <a href={`${baseUrl}/public/storage/files/${d.link}`} target='_blank'>
                          {d.name}
                        </a>
                      </td>
                      <td>
                        <div className='form-check form-switch mt-2 d-flex'>
                          <label className='form-check-label visually-hidden' htmlFor={`approved-${d.id}`}>
                            approuve {d.name}
                          </label>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            role='switch'
                            id={`approved-${d.id}`}
                            name={`approved-${d.id}`}
                            checked={d.approved}
                            onChange={() => this.handleClickApproved(d.id)}
                            disabled={!canApprouved}
                            readOnly={!canApprouved}
                          />
                        </div>
                        {d.approved && !!d.nameApproval ? <span>{d.nameApproval}</span> : ''}
                      </td>
                      <td>
                        <img
                          className='document-extension'
                          src={Utils.getIconForDocument(d.link)}
                          alt={Utils.getExtensionDocumentForAlt(d.link)}
                        />{' '}
                      </td>
                      <td>{d.type.label}</td>

                      <td>{Utils.formatDateToWebWithTime(d.updatedAt)}</td>
                      {canDelete ? (
                        <td>
                          <a
                            role='button'
                            aria-label={`deleteDocument-${d.name}`}
                            id={`deleteDocument-${d.id}`}
                            className='icon foreground-Trash delete_button not_underlined'
                            onClick={() => {
                              this.handleDeleteDocument(d.id);
                            }}
                          ></a>
                        </td>
                      ) : null}
                    </tr>
                  );
                })
                : null}
              </tbody>
            </table>
          </div>

          {!this.props.isReadOnly ? (
            <div className='row mt-4'>
              <AddDocument
                isForCompany={true}
                isForDevice={false}
                scope={this.props.scope}
                handleDocumentChanged={this.handleDocumentChanged}
                handleVideoDocumentChanged={this.handleVideoDocumentChanged}
                currentDocumentToAdd={this.props.company.documentsToAdd}
                ref={(instance) => {
                  this.childAddDocument = instance;
                }}
              ></AddDocument>
            </div>
          ) : (
            ''
          )}
        </>
      );
    } else {
      return null;
    }
  }
}
