import React, { Component } from 'react';
import { DISTRIBUTION_STRATEGY_OPTIONS } from '../../models/constants';
import './company-management.css';
import {
  ForegroundOption,
  ForegroundSelectMetaAction,
  ForegroundSelectSingle,
  SELECT_ACTION,
} from '../../components/ForegroundSelect';
import { CompanyWizardStepProps } from './CompanyWizard';

class CompanyReferenceIoTStepState {
  errorMessage: string;
  currentDistributionStrategy: ForegroundOption;

  constructor() {
    this.errorMessage = '';
    this.currentDistributionStrategy = DISTRIBUTION_STRATEGY_OPTIONS[0];
  }
}

export class CompanyReferenceIoTStep extends Component<CompanyWizardStepProps, CompanyReferenceIoTStepState> {
  constructor(props) {
    super(props);
    this.state = new CompanyReferenceIoTStepState();
    this.handleChangeDistributionStrategy = this.handleChangeDistributionStrategy.bind(this);
  }

  async componentDidMount() {
    try {
      const isCreation = !!!this.props.company.id;
      if (!isCreation) {
        const distributionStrategy = DISTRIBUTION_STRATEGY_OPTIONS.find(
          (d) => d.value === this.props.company.customerReferences.distributionStrategy
        );
        this.setState({
          currentDistributionStrategy: distributionStrategy,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  validate() {
    return true;
  }

  handleChangeDistributionStrategy(event: ForegroundOption, action: ForegroundSelectMetaAction) {
    if (action.action === SELECT_ACTION.CLEAR) {
      this.props.company.customerReferences.distributionStrategy = null;
      this.setState({
        currentDistributionStrategy: null,
      });
    } else {
      this.props.company.customerReferences.distributionStrategy = event.value;
      this.setState({
        currentDistributionStrategy: event,
      });
    }
  }

  render() {
    if (this.props.shouldDisplay) {
      const isCreation = !!!this.props.company.id;
      const currentDistributionStrategy = this.state.currentDistributionStrategy;
      return (
        <>
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-3 mt-1'>All mandatory fields are marked with an *</div>
              <label htmlFor='jointProjectWithOrange'>Joint projects with Orange</label>
              <textarea
                className='w-100 form-control mt-2'
                rows={5}
                id='jointProjectWithOrange'
                defaultValue={this.props.company.jointProjectWithOrange}
                onChange={(e) => {
                  this.props.company.jointProjectWithOrange = e.target.value;
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></textarea>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-12'>
            <label htmlFor="customerReferencesInIot">Customer references in Iot</label>
              <textarea
                className="w-100 form-control mt-2"
                rows={5}
                id="customerReferencesInIot"
                defaultValue={this.props.company.customerReferences.customerReferencesInIot}
                onChange={(e) => {
                  this.props.company.customerReferences.customerReferencesInIot = e.target.value;
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></textarea>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <label htmlFor="distributionStrategy">Distribution strategy</label>
              <ForegroundSelectSingle
                inputId="distributionStrategy"
                placeholder="Please select a distribution strategy"
                value={currentDistributionStrategy}
                options={DISTRIBUTION_STRATEGY_OPTIONS}
                onChange={(e, a) => this.handleChangeDistributionStrategy(e, a)}
                isDisabled={this.props.isReadOnly}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <label htmlFor="productsRange">Products range</label>
              <textarea
                className="w-100 form-control mt-2"
                rows={5}
                id="productsRange"
                defaultValue={this.props.company.productsRange}
                onChange={(e) => {
                  this.props.company.productsRange = e.target.value;
                }}
                required
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></textarea>
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}
