import moment from 'moment-timezone';

export default class Utils {
  static PRODUCTFILTERSTATUS='PRODUCTFILTERSTATUS';
  static PRODUCTFILTERTYPES='PRODUCTFILTERTYPES';
  static PRODUCTFILTERCOMPANIES='PRODUCTFILTERCOMPANIES';
  static PRODUCTFILTERNETWORK='PRODUCTFILTERNETWORKINTERFACE';
  static PRODUCTFILTERVERTICALS='PRODUCTFILTERVERTICALS';
  static PRODUCTFILTERUSECASES='PRODUCTFILTERUSECASES';
  static PRODUCTFILTERORDER='PRODUCTFILTERORDER';
  static PRODUCTFILTERDEVICENAME='PRODUCTFILTERDEVICENAME';
  static PRODUCTFILTERCOMPANYNAME='PRODUCTFILTERCOMPANYNAME';
  static COMPANYFILTERNAME='COMPANYFILTERNAME';
  static COMPANYFILTERSTATUS='COMPANYFILTERSTATUS';
  static COMPANYFILTERCOLLABORATIONS='COMPANYFILTERCOLLABORATIONS';
  static COMPANYFILTERCOUNTRIES='COMPANYFILTERCOUNTRIES';

  /**
   * Get auth token
   * @returns {string}
   */
  static getAuthToken() {
    return localStorage.getItem('token');
  }

  static getAuthHeader() {
    const token = Utils.getAuthToken();
    return !!token ? { Authorization: `Bearer ${token}` } : {};
  }

  static setAuthToken(token) {
    localStorage.setItem('token', token);
  }

  static getFilter(filterName) {
    const filter = localStorage.getItem('filter'+filterName);
    return !!filter ? JSON.parse(filter) : null;
  }

  static setFilter(filterName, filterValue) {
    localStorage.setItem('filter'+filterName, JSON.stringify(filterValue));
  }

  static deleteFilter(filterName) {
    localStorage.removeItem('filter'+filterName);
  }

  static logout() {
    localStorage.removeItem('token');
  }

  static checkFilledValue(x) {
    if (x == null || x === undefined || x === '') {
      return false;
    } else {
      return true;
    }
  }

  static formatDateToAPI(date) {
    if (!!date) {
      return moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
    } else {
      return date;
    }
  }
  static formatDateToWeb(date) {
    if (!!date && date !== '') {
      return moment(new Date(date)).format('YYYY-MM-DD');
    } else {
      return '';
    }
  }

  static formatDateToWebWithTime(date) {
    if (!!date && date !== '') {
      return moment(new Date(date)).tz('Europe/Paris').format('YYYY-MM-DD - HH:mm:ss');
    } else {
      return '';
    }
  }

  static getIconForDocument(documentLink) {
    const extension = documentLink.substring(documentLink.lastIndexOf('.') + 1, documentLink.length).toLowerCase();
    let img = `${process.env.PUBLIC_URL}/assets/img/`;

    switch (extension) {
      case 'htm':
        img += 'url-video.jpg';
        break;
      case 'pdf':
        img += 'pdf.jpg';
        break;
      case 'docx':
        img += 'doc.jpg';
        break;
      case 'pptx':
        img += 'ppt.jpg';
        break;
      case 'xlsx':
        img += 'xls.jpg';
        break;
      case 'jpg':
        img += 'jpg.jpg';
        break;
      case 'png':
        img += 'jpg.jpg';
        break;
      case 'jpeg':
        img += 'jpg.jpg';
        break;
      default:
        img += 'doc.jpg';
        break;
    }
    return img;
  }

  static getExtensionDocumentForAlt(file: string) {
    const extension = file.substring(file.lastIndexOf('.') + 1, file.length).toLowerCase();

    switch (extension) {
      case 'htm':
        return 'Video link';
      case 'pdf':
        return 'PDF document';
      case 'docx':
        return 'Word document';
      case 'pptx':
        return 'PowerPoint document';
      case 'xlsx':
        return 'Excel document';
      case 'jpg':
      case 'png':
      case 'jpeg':
        return 'Image file';

      default:
        return 'file ' + extension;
    }
  }

  static formatCSVData(data) {
    if (data === '') return data
    try {
      if (data && data.includes('"')) data = data.replaceAll('"', '""');
      if (data && data.match('\r\n|\r|\n|<br/>')) data = '"' + data + '"';
      if (data && data.includes(',')) data = '"' + data + '"';
    } catch {}
    return isNaN(data) ? data || '' : data || 0;
  }

  static getCSCStatus(signatureData, sep) {
    let CSCstatus = 'No',
      signatureDate = '',
      expirationDate = '';
    if (signatureData && signatureData.signature && signatureData.expiration) {
      const now = moment(new Date());
      try {
        const signature = moment(new Date(signatureData.signature)).tz('Europe/Paris');
        const expiration = moment(new Date(signatureData.expiration)).tz('Europe/Paris');
        signatureDate = Utils.formatDateToWeb(signature);
        expirationDate = Utils.formatDateToWeb(expiration);
        if (now >= signature && now <= expiration) {
          CSCstatus = 'Yes';
        }
      } catch {}
    }
    return CSCstatus + sep + signatureDate + sep + expirationDate;
  }

  static reduceArrayToString(array, find=null) {
    try {
        if(find) {
          const filteredArray = array.filter(item => { return item[find]; })
          return(filteredArray.map((item) => { return item.label; }).toString())
        }
        else {
          return array.map(item => item.label).join(', ')
        }
    }
    catch(error) {
      return ''
    }
  }

  static omit = (keys, obj) => (obj && keys ? Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k))) : obj);
}
