import { ForegroundOption } from '../components/ForegroundSelect';

export enum DocumentTypeEnum {
  CERTIFICATES = 1,
  MOU = 2,
  NDA = 3,
  PARTNER_SHEET = 4,
  DEVICE_SHEET = 5,
  PRICING = 6,
  TEST_RESULT = 7,
  CLIENT_PROJECT = 8,
  OTHER = 9,
  CATALOG_DEVICE_SHEET = 10,
  // 11 is Internal Analysis that in no more used
  CATALOGUE = 12,
  PRODUCT_SHEET = 13,
  VIDEO = 14,
  COMPANY_PRESENTATION = 15,
}

export class DocumentType {
  constructor(public id: number, public label: string) {}
}

export class ForegroundDocument {
  public id: number;
  public name: string;
  public link: string;
  public fileContent: string;
  public note: string;
  public approved: boolean;
  public idPlazza: string;
  public idAttachments: string;
  public nameApproval: string;
  public createdAt: string;
  public updatedAt: string;
  public type: DocumentType;
  public companyId: number;
  public deviceId: number;
  public fileName: string;

  constructor(fileName: string, fileContent: string, type: ForegroundOption) {
    this.fileName = fileName;
    this.name = this.fileName;
    this.fileContent = fileContent;
    this.type = new DocumentType(type.value, type.label);
  }
}

export class ForegroundDocumentToAdd {
  public fileContent: string;
  public type: DocumentType;
  public fileName: string;
  public approved: boolean;
  public name: string;
  public videoUrl: string;
  public label: string;

  constructor(fileName: string, fileContent: string, type: ForegroundOption, videoUrl: string, label: string) {
    this.fileName = fileName;
    this.name = !!fileName ? fileName : label;
    this.fileContent = fileContent;
    this.type = new DocumentType(type.value, type.label);
    this.videoUrl = videoUrl;
    this.label = label;
    this.approved = false;
  }
}
