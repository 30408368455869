import { ReducedDevice } from '../../models/device';

export default function ProductLabelLastest(props: ReducedDevice) {
  const versions = props.versions;

  for (const version of versions) {
    if (version.assessed) {
      return (
        <div className="container position-absolute bottom-0 text-center">
          <img
            id={`device-assessed-${props.id}`}
            className="imgLabel"
            src={`${process.env.PUBLIC_URL}/assets/img/Orange_Assessed_Small_Logo_Black_RGB.svg`}
            alt="Orange label assessed"
          ></img>
        </div>
      );
    }
    if (version.connected) {
      return (
        <div className="container position-absolute bottom-0 text-center">
          <img
            id={`device-connected-${props.id}`}
            className="imgLabel"
            src={`${process.env.PUBLIC_URL}/assets/img/Orange_Connected_Small_Logo_Black_RGB.svg`}
            alt="Orange label connected"
          ></img>
        </div>
      );
    }
    if (version.approved) {
      return (
        <div className="container position-absolute bottom-0 text-center">
          <img
            id={`device-approved-${props.id}`}
            className="imgLabel"
            src={`${process.env.PUBLIC_URL}/assets/img/Orange_Approved_Small_Logo_Black_RGB.svg`}
            alt="Orange label approved"
          ></img>
        </div>
      );
    }
  }
}
