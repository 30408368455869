import { Component } from 'react';
import LoadingSpinner from './LoadingSpinner';
import './ReferenceData.css';
import { PageHeader } from './PageHeader';
import { DeviceTechnology } from '../models/device';
import ProductService from '../services/product.service';
import { Link } from 'react-router-dom';

enum MoveType {
  Up = 'up',
  Down = 'down',
}

class ReferenceDataProps {}

class ReferenceDataState {
  public technologiesList: Array<DeviceTechnology>;

  public firstName: string;
  public lastName: string;
  public isLoading: boolean;
  public newPassword: string;
  public isErrorOnMyNewPassword: boolean;
  public messageError: string;
  public orderIndex: number;

  constructor() {
    this.technologiesList = [];
    this.firstName = null;
    this.lastName = null;
    this.isLoading = true;
    this.newPassword = null;
    this.isErrorOnMyNewPassword = null;
    this.messageError = null;
  }
}

export class ReferenceData extends Component<ReferenceDataProps, ReferenceDataState> {
  constructor(props) {
    super(props);
    this.state = new ReferenceDataState();
  }

  async componentDidMount() {
    try {
      let response = await ProductService.getDeviceTechnologies();
      this.setState({
        technologiesList: this.sortDeviceTechnology(response.data),
        isLoading: false,
        orderIndex: response.data.length,
      });
    } catch (error) {
      console.log('ReferenceData : ' + JSON.stringify(error));
      this.setState({ isLoading: false });
    }
  }

  public deleteTechnologyList(list: Array<DeviceTechnology>) {
    list.forEach(async (technology) => {
      await ProductService.deleteTechnology(technology.id);
    });
  }

  public sortDeviceTechnology(list: Array<DeviceTechnology>): Array<DeviceTechnology> {
    return list.sort((first, second) => (first.order < second.order ? -1 : 1));
  }

  public async updateTechnology(id: number, toOrder: number) {
    await ProductService.updateTechnologyOrder(id, toOrder);
  }

  public async swapTechnology(firstIndex: number, secondIndex: number) {
    const technoOne: DeviceTechnology = this.state.technologiesList[firstIndex],
      technoTwo: DeviceTechnology = this.state.technologiesList[secondIndex];
    await this.updateTechnology(technoOne.id, secondIndex);
    await this.updateTechnology(technoTwo.id, firstIndex);
  }

  async move(firstIndex: number, direction: MoveType) {
    let secondIndex: number = -1;
    switch (direction) {
      case MoveType.Down:
        secondIndex = firstIndex + 1;
        break;
      case MoveType.Up:
        secondIndex = firstIndex - 1;
        break;
      default:
        console.log('Move Error - direction not set: should not happen');
        break;
    }
    if (secondIndex !== -1) {
      await this.swapTechnology(firstIndex, secondIndex);
      this.componentDidMount();
    }
  }

  renderTable() {
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Label</th>
              <th>Image</th>
              <th>Edit</th>
              <th>Order</th>
            </tr>
          </thead>
          <tbody>
            {!!this.state.technologiesList
              ? this.state.technologiesList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.label}</td>
                      <td className="center-horizontal">
                        <img
                          src={process.env.REACT_APP_BASE_URL_API + '/public/storage/img/' + item.image.link}
                          alt={item.image.name}
                          height="100"
                          loading="lazy"
                        />
                      </td>
                      <td>
                        <Link
                          id="editTechno"
                          className="btn btn-primary mt-2"
                          to={{
                            pathname: '/reference-data/add-edit',
                            state: {
                              techno: this.state.technologiesList[index],
                              baseImgURL: process.env.REACT_APP_BASE_URL_API + '/public/storage/img/',
                            },
                          }}
                        >
                          <span className="icon foreground-ic_Pencil" aria-hidden="true"></span>
                        </Link>
                      </td>
                      <td>
                        <span>
                          {index === this.state.technologiesList.length - 1 ? (
                            <img aria-hidden="true" src={process.env.PUBLIC_URL + '/assets/img/blank.png'} width="50" loading="lazy" alt="" />
                          ) : (
                            <button
                              type="button"
                              className="btn btn-icon btn-no-outline"
                              onClick={() => {
                                this.move(index, MoveType.Down);
                              }}
                            >
                              <img aria-hidden="true" src={process.env.PUBLIC_URL + '/assets/img/down.png'} width="30" loading="lazy" alt="Down" />
                            </button>
                          )}
                          {index === 0 ? (
                            <img aria-hidden="true" src={process.env.PUBLIC_URL + '/assets/img/blank.png'} width="50" loading="lazy" alt="" />
                          ) : (
                            <button
                              type="button"
                              className="btn btn-icon btn-no-outline"
                              onClick={() => {
                                this.move(index, MoveType.Up);
                              }}
                            >
                              <img aria-hidden="true" src={process.env.PUBLIC_URL + '/assets/img/up.png'} width="30" loading="lazy" alt="Up" />
                            </button>
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })
              : ''}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    document.title = 'Reference data - Foreground V3';
    return (
      <div className="p-3 bg-white">
        <PageHeader title="Reference data"></PageHeader>
        <p className="header">Technologies</p>
        <Link
          id="addTechno"
          className="btn btn-primary mt-2"
          to={{ pathname: '/reference-data/add-edit', state: { orderIndex: this.state.orderIndex } }}
        >
          Add Technology
        </Link>
        {this.state.isLoading ? <LoadingSpinner /> : this.renderTable()}
      </div>
    );
  }
}
