export class Country {
  public id: number;
  public countryCode: string;
  public countryName: string;
  public currencyCode: string;
  public fipsCode: string;
  public isoNumeric: string;
  public north: string;
  public south: string;
  public east: string;
  public west: string;
  public capital: string;
  public continentName: string;
  public continent: string;
  public languages: Array<string>;
  public isoAlpha3: string;
  public geonameId: number;
  public orange: boolean;
  public dialCode: string;
}

export class ReducedCountry {
  public id: number;

  public countryName: string;

  constructor(id: number, countryName: string) {
    this.id = id;
    this.countryName = countryName;
  }
}
