import React, { Component } from 'react';
import UserService from '../../services/user.service';
import CountryService from '../../services/country.service';
import CompanyService from '../../services/company.service';
import LoadingSpinner from '../LoadingSpinner';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { createBrowserHistory } from 'history';
import {
  AUTOCOMPLETE_STYLE,
  BAD_EMAIL_FORMAT,
  EMAIL_REGEX,
  MANDATORY_FIELD_MISSING,
  PLEASE_SELECT_COMPANY,
  PLEASE_SELECT_COUNTRY,
  PLEASE_SELECT_JOB_POSITION,
  USER_TYPE,
  ZINDEX_HIGHEST,
  ZINDEX_LOWEST,
} from '../../models/constants';
import { UserCompany, UserJobs, UserToUpdate, UserTypes } from '../../models/user';
import { ForegroundOption, ForegroundSelectSingle, SELECT_ACTION } from '../ForegroundSelect';
import { Country } from '../../models/country';
import { PageHeader } from '../PageHeader';

class AddPartnerUserProps {
  history: any;
}

class AddPartnerUserState {
  public userTypeList: Array<UserTypes>;
  public userJobList: Array<ForegroundOption>;
  public countriesList: Array<Country>;
  public companiesList: Array<UserCompany>;
  public isLoading: boolean;
  public firstName: string;
  public isErrorOnFirstName: boolean;
  public lastName: string;
  public isErrorOnLastName: boolean;
  public email: string;
  public isErrorOnEmail: boolean;
  public currentType: ForegroundOption;
  public isErrorOnType: boolean;
  public isError: boolean;
  public errorMessage: string;
  public currentCountry: Country;
  public isErrorOnCountry: boolean;
  public currentJob: ForegroundOption;
  public isErrorOnJob: boolean;
  public currentCompany: UserCompany;
  public isErrorOnCompany: boolean;
  public countryFocus: boolean;
  public jobFocus: boolean;
  public companyFocus: boolean;
  public submitDisabled:boolean;

  constructor() {
    this.userTypeList = [];
    this.countriesList = [];
    this.userJobList = [];
    this.companiesList = [];
    this.isLoading = true;

    this.firstName = '';
    this.isErrorOnFirstName = false;
    this.lastName = '';
    this.isErrorOnLastName = false;
    this.email = '';
    this.isErrorOnEmail = false;
    this.currentType = null;
    this.isErrorOnType = false;
    this.isError = false;
    this.errorMessage = '';

    this.currentCountry = null;
    this.isErrorOnCountry = false;
    this.currentJob = null;
    this.isErrorOnJob = false;
    this.currentCompany = null;
    this.isErrorOnCompany = false;
    this.submitDisabled = false;
  }
}

export class AddPartnerUser extends Component<AddPartnerUserProps, AddPartnerUserState> {
  constructor(props) {
    super(props);
    this.state = new AddPartnerUserState();
    this.handleChangeUserJob = this.handleChangeUserJob.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      let response = await Promise.all([
        UserService.jobsList(),
        UserService.userTypesList(),
        CountryService.list('countryName'),
        CompanyService.companiesList(null, null, null, null, null, null),
      ]);
      this.setState({
        userJobList: response[0].data.map((item) => {
          return new ForegroundOption(item.label, item.id);
        }),
        userTypeList: response[1].data,
        countriesList: response[2].data,
        companiesList: response[3].data,
        isLoading: false,
      });
    } catch (error) {
      console.log('AddPartnerUser : ' + JSON.stringify(error));
      this.setState({ isLoading: false });
    }
  }

  renderForm() {
    let { firstName, lastName, email, currentJob } = this.state;
    const countries = this.state.countriesList;

    const countryFuseOptions = { keys: ['countryName'], distance: 0, minMatchCharLength: 2 };

    const userJobs = this.state.userJobList;
    const companies = this.state.companiesList;
    const companyFuseOptions = { keys: ['name'], distance: 0 };
    return (
      <div className="container">
        <form id="myForm" className="mt-3" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6 offset-md-0">
              <div className='mb-3'>All mandatory fields are marked with an *</div>
              <div className='form-group mb-3'>
                <label htmlFor='firstname' className='is-required'>
                  First name
                </label>
                <input
                  type='text'
                  className='form-control mt-2'
                  id='firstname'
                  name='firstname'
                  maxLength={1000}
                  value={firstName}
                  onChange={this.handleChangeFirstName}
                  required
                />
                {this.state.isErrorOnFirstName ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
              </div>
              <div className='form-group mb-3'>
                <label htmlFor="lastname" className="is-required">
                  Last name
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  id="lastname"
                  name="lastname"
                  maxLength={1000}
                  value={lastName}
                  onChange={this.handleChangeLastName}
                  required
                />
                {this.state.isErrorOnLastName ? <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}
              </div>

              <div className="form-group mb-3">
                <label htmlFor="email" className="is-required">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control mt-2"
                  id="email"
                  name="email"
                  maxLength={1000}
                  value={email}
                  onChange={this.handleChangeEmail}
                  required
                />
                {this.state.isErrorOnEmail ? <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}
              </div>

              <div className="form-group mb-3">
                <label htmlFor="country" className="is-required mb-2">
                  Country
                </label>

                <ReactSearchAutocomplete
                  items={countries}
                  autoFocus
                  formatResult={this.formatResultCountry}
                  onSelect={this.onSelectCountry}
                  styling={this.state.countryFocus ? { ...AUTOCOMPLETE_STYLE, ...ZINDEX_HIGHEST } : { ...AUTOCOMPLETE_STYLE, ...ZINDEX_LOWEST }}
                  placeholder={PLEASE_SELECT_COUNTRY}
                  showNoResults={true}
                  showNoResultsText="No Result"
                  fuseOptions={countryFuseOptions}
                  resultStringKeyName="countryName"
                  onClear={this.onClearCountry}
                  onFocus={() => {
                    this.setState({ countryFocus: true, jobFocus: false, companyFocus: false });
                  }}
                />
                {this.state.isErrorOnCountry ? <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}
              </div>

              <div className="form-group mb-3">
                <label htmlFor="job" className="is-required">
                  Job position
                </label>
                <ForegroundSelectSingle
                  inputId="job"
                  placeholder={PLEASE_SELECT_JOB_POSITION}
                  value={currentJob}
                  options={userJobs}
                  closeOnSelect={true}
                  onFocus={() => {
                    this.setState({ countryFocus: false, jobFocus: true, companyFocus: false });
                  }}
                  onChange={(e, a) => {
                    this.handleChangeUserJob(e, a);
                  }}
                />

                {this.state.isErrorOnJob ? <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}
              </div>

              <div className="form-group mb-3">
                <label htmlFor="company_name" className="is-required mb-2">
                  Company
                </label>
                <ReactSearchAutocomplete
                  items={companies}
                  onSearch={this.handleOnSearchCompany}
                  autoFocus
                  formatResult={this.formatResultCompany}
                  onSelect={this.onSelectCompany}
                  styling={this.state.companyFocus ? { ...AUTOCOMPLETE_STYLE, ...ZINDEX_HIGHEST } : { ...AUTOCOMPLETE_STYLE, ...ZINDEX_LOWEST }}
                  placeholder={PLEASE_SELECT_COMPANY}
                  showNoResults={true}
                  showNoResultsText="No Result"
                  fuseOptions={companyFuseOptions}
                  onClear={this.onClearCompany}
                  onFocus={() => {
                    this.setState({ countryFocus: false, jobFocus: false, companyFocus: true });
                  }}
                />
                {this.state.isErrorOnCompany ? <p className="errorMessage mt-2">{this.state.errorMessage}</p> : null}
              </div>

              <div className="form-group my-3 d-flex flex-row justify-content-end">
                <button onClick={() => this.props.history.push('/partner-users')} className="me-3 btn btn-secondary">
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary" disabled={this.state.submitDisabled}>
                  Add
                </button>
                <br></br>
              </div>
            </div>
            {this.state.isError ? <p className="errorMessage">{this.state.errorMessage}</p> : null}
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="p-3 bg-white">
        <PageHeader title="Add Partner user"></PageHeader>

        {this.state.isLoading ? <LoadingSpinner /> : this.renderForm()}
      </div>
    );
  }

  handleChangeFirstName = (event) => {
    const value = event.target.value;
    this.setState({ firstName: value, isErrorOnFirstName: false });
  };

  handleChangeLastName = (event) => {
    const value = event.target.value;
    this.setState({ lastName: value, isErrorOnLastName: false });
  };

  handleChangeEmail = (event) => {
    const value = event.target.value;
    this.setState({ email: value, isErrorOnEmail: false });
  };

  formatResultCountry = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.countryName}</span>
      </>
    );
  };
  onSelectCountry = (item) => {
    this.setState({ currentCountry: item, isErrorOnCountry: false });
  };

  onClearCountry = () => {
    this.setState({ currentCountry: null, isErrorOnCountry: true, errorMessage: PLEASE_SELECT_COUNTRY });
  };

  handleChangeUserJob(event: ForegroundOption, action: SELECT_ACTION) {
    if (action === SELECT_ACTION.CLEAR) {
      this.setState({ currentJob: null, isErrorOnJob: true, errorMessage: PLEASE_SELECT_JOB_POSITION });
    } else {
      this.setState({ currentJob: event, isErrorOnJob: false });
    }
  }

  handleOnSearchCompany = (item, results) => {
    this.setState({ currentCompany: item, isErrorOnCompany: false });
  };

  formatResultCompany = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    );
  };

  onSelectCompany = (item) => {
    this.setState({ currentCompany: item, isErrorOnCompany: false });
  };

  onClearCompany = () => {
    this.setState({ currentCompany: null });
  };

  validate() {
    if (this.state.firstName.trim().length === 0) {
      this.setState({ isErrorOnFirstName: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }

    if (this.state.lastName.trim().length === 0) {
      this.setState({ isErrorOnLastName: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }

    if (!EMAIL_REGEX.test(this.state.email)) {
      this.setState({ isErrorOnEmail: true, errorMessage: BAD_EMAIL_FORMAT });
      return false;
    }

    if (this.state.currentCountry === null) {
      this.setState({ isErrorOnCountry: true, errorMessage: PLEASE_SELECT_COUNTRY });
      return false;
    }

    if (this.state.currentJob === null) {
      this.setState({ isErrorOnJob: true, errorMessage: PLEASE_SELECT_JOB_POSITION });

      return false;
    }
    if (this.state.currentCompany === null) {
      this.setState({ isErrorOnCompany: true, errorMessage: PLEASE_SELECT_COMPANY });
      return false;
    }
    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.submitDisabled) return

    this.setState({ isError: false });

    if (!this.validate()) {
      console.log(`error validate`);
      return;
    }
    this.setState({ submitDisabled:true });
    const currentCountry = this.state.currentCountry;
    const currentJob = new UserJobs(this.state.currentJob.value, this.state.currentJob.label, null);
    const userType = this.state.userTypeList.find((t) => t.id === USER_TYPE.PARTNER);

    const body = new UserToUpdate(
      this.state.firstName,
      this.state.lastName,
      this.state.email,
      currentCountry,
      null,
      userType,
      currentJob,
      this.state.currentCompany
    );

    try {
      UserService.createUser(body)
        .then((res) => {
          if (res.status === 201) {
            document.dispatchEvent(
              new CustomEvent('addNotification', {
                detail: {
                  type: 'success',
                  content: `The user ${this.state.firstName} ${this.state.lastName} has been added.`,
                },
              })
            );
            this.props.history.push('/partner-users');
          } else {
            if (res.status >= 400) {
              this.setState({ isError: true, errorMessage: res.data.message });
            }
          }
        })
        .catch((e) => {
          console.log(`err ${JSON.stringify(e)}`);
          this.setState({ isError: true, errorMessage: e.message });
        }).finally(()=>{this.setState({ submitDisabled:false });});;
    } catch (e) {
      let message = 'Error';
      this.setState({ isError: true, errorMessage: message, submitDisabled:false });
    }
  }
}

export default createBrowserHistory();
