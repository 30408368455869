import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { PasswordInput } from '../PasswordInput';
import { Tooltip } from 'react-tooltip';
import { PASSWORD_FORMAT } from '../../models/constants';

export default function (props) {
  return (
    <Modal show={props.show} onHide={props.onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Password Change</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="me-1">Please enter the new password for the user:</span>
        <em>{`${props.firstName} ${props.lastName}`}</em>
        <span>
          . <br />
          <br />
          Once modified, please communicate the new password to the user.
        </span>

        <div id="newPasswordSection" className="form-group mt-3">
          <div className="d-flex flex-row">
            <PasswordInput
              inputId="myNewPassword"
              placeholder={`New Password`}
              handleChangePassword={(e) => props.handleChangePassword(e)}
            ></PasswordInput>

            <span className="input-group-append mt-2">
              <a data-tooltip-id="my-tooltip" data-tooltip-content={PASSWORD_FORMAT} data-tooltip-place="right">
                <span className="icon foreground-ic_Assistance ms-2 pt-2 icon-40" aria-hidden="true"></span>
              </a>
              <Tooltip id="my-tooltip" className="tooltip-content" />
            </span>
          </div>
          {props.isErrorOnMyNewPassword ? <p className="errorMessage mt-2">{props.messageError}</p> : ''}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => props.onConfirm()}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
