import instance from '../helpers/InterceptorAxios';
import Utils from '../helpers/Utils';
import { ForegroundDocumentToAdd } from '../models/document';

export default class DocumentService {
  /**
   * List of countries
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async list(isForDevice: boolean, isForCompany: boolean) {
    const params = {};
    if (!!isForDevice && isForDevice) {
      params['isForDevice'] = isForDevice;
    }

    if (!!isForCompany && isForCompany) {
      params['isForCompany'] = isForCompany;
    }
    return await instance.get(`api/v1/documentTypes`, { params: params, headers: Utils.getAuthHeader() });
  }

  static async updateDocument(documentId: number, document) {
    const data = { approved: document.approved };

    return await instance.patch(`api/v1/documents/${documentId}`, data, { headers: Utils.getAuthHeader() });
  }

  static async createCompanyDocument(companySlug: string, document: ForegroundDocumentToAdd) {
    return await instance.post(`api/v1/companies/${companySlug}/documents/`, document, { headers: Utils.getAuthHeader() });
  }

  static async deleteDocument(documentId: number) {
    return await instance.delete(`api/v1/documents/${documentId}`, { headers: Utils.getAuthHeader() });
  }

  static async createDeviceDocument(companySlug: string, deviceSlug: string, document: ForegroundDocumentToAdd) {
    return await instance.post(`api/v1/companies/${companySlug}/devices/${deviceSlug}/documents/`, document, { headers: Utils.getAuthHeader() });
  }
}
