import axios from 'axios';
import Cookie from 'js-cookie';
import { GENERAL_ERROR } from '../models/constants';

axios.defaults.headers.common['X-CSRF-TOKEN'] = Cookie.get('XSRF-TOKEN');

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  withCredentials: false,
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('isReloading');
      window.location.href = '/login';
    } else if (error.toString() === 'Error: Network Error') {
      document.dispatchEvent(
        new CustomEvent('addNotification', { detail: { type: 'danger', category: 'error', content: 'Network error: Connection failed with API...' } })
      );
      return Promise.reject({ message: 'Network error' });
    } else {
      document.dispatchEvent(new CustomEvent('addNotification', { detail: { type: 'danger', category: 'error', content: 'An error occured...' } }));
      return Promise.reject({ message: GENERAL_ERROR });
    }
  }
);
export default instance;
