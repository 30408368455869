export const updateUserToken = (token) => {
  return { type: 'UPDATE_USER_TOKEN', token: token };
};
export const updateUserFirstName = (firstname) => {
  return { type: 'UPDATE_USER_FIRST_NAME', firstname: firstname };
};
export const updateUserLastName = (lastname) => {
  return { type: 'UPDATE_USER_LAST_NAME', lastname: lastname };
};
export const updateUserScope = (scope) => {
  return { type: 'UPDATE_USER_SCOPE', scope: scope };
};
export const updateUserId = (userid) => {
  return { type: 'UPDATE_USER_ID', userid: userid };
};
export const updateCompanySlug = (companySlug) => {
  return { type: 'UPDATE_COMPANY_SLUG', companySlug: companySlug };
};

export const updateUserCgu = (cgu) => {
  return { type: 'UPDATE_USER_CGU', cgu: cgu };
};

export const updateUserCanDeleteCompaniesAndDevices = (canDeleteCompaniesAndDevices) => {
  return { type: 'UPDATE_USER_CAN_DELETE_COMPAGNIES_AND_DEVICES', canDeleteCompaniesAndDevices: canDeleteCompaniesAndDevices };
};
