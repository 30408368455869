import React, { Component } from 'react';
import './company-management.css';
import 'react-datepicker/dist/react-datepicker.css';
import { CompanyWizardStepProps } from './CompanyWizard';

class CompanyESGCSRStepState {
  public errorMessage: string;
  esgCSRPolicy: boolean;
  esgCSRAdditionalInformation: string;
  csrPolicyEvaluated: boolean;
  sustainableProcurementPolicy: boolean;
  sustainableProcurementAdditionalInformation: string;
  esgCSRContact: string;
  isErrorOnEsgCSRPolicy: boolean;
  isErrorOnProcurementPolicy: boolean;

  constructor() {
    this.errorMessage = '';
    this.esgCSRPolicy = false;
    this.esgCSRAdditionalInformation = '';
    this.csrPolicyEvaluated = false;
    this.sustainableProcurementPolicy = false;
    this.sustainableProcurementAdditionalInformation = '';
    this.esgCSRContact = '';
    this.isErrorOnEsgCSRPolicy = false;
    this.isErrorOnProcurementPolicy = false;
  }
}

export class CompanyESGCSRStep extends Component<CompanyWizardStepProps, CompanyESGCSRStepState> {
  constructor(props) {
    super(props);
    this.state = new CompanyESGCSRStepState();
  }

  async componentDidMount() {
    try {
      this.setState({
        esgCSRPolicy: !!this.props.company.sustainableDevelopment.esgCSRPolicy,
        esgCSRAdditionalInformation: this.props.company.sustainableDevelopment.esgCSRAdditionalInformation,
        csrPolicyEvaluated: !!this.props.company.sustainableDevelopment.csrPolicyEvaluated,
        sustainableProcurementPolicy: !!this.props.company.sustainableDevelopment.sustainableProcurementPolicy,
        sustainableProcurementAdditionalInformation: this.props.company.sustainableDevelopment.sustainableProcurementAdditionalInformation,
        esgCSRContact: this.props.company.sustainableDevelopment.esgCSRContact,
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleClickSwitch1(bool: boolean) {
    this.setState({ esgCSRPolicy: bool });
    this.props.company.sustainableDevelopment.esgCSRPolicy = bool;
    if (!bool) {
      this.onComment1Change('');
    }
  }

  onComment1Change(comment: string) {
    this.setState({ esgCSRAdditionalInformation: comment });
    this.props.company.sustainableDevelopment.esgCSRAdditionalInformation = comment;
  }

  handleClickSwitch2(bool: boolean) {
    this.setState({ csrPolicyEvaluated: bool });
    this.props.company.sustainableDevelopment.csrPolicyEvaluated = bool;
  }

  handleClickSwitch3(bool: boolean) {
    this.setState({ sustainableProcurementPolicy: bool });
    this.props.company.sustainableDevelopment.sustainableProcurementPolicy = bool;
    if (!bool) {
      this.onComment3Change('');
    }
  }

  onComment3Change(comment: string) {
    this.setState({ sustainableProcurementAdditionalInformation: comment });
    this.props.company.sustainableDevelopment.sustainableProcurementAdditionalInformation = comment;
  }

  onComment4Change(contact: string) {
    this.setState({ esgCSRContact: contact });
    this.props.company.sustainableDevelopment.esgCSRContact = contact;
  }

  validate() {
    if (this.props.isReadOnly) {
      return true;
    }
    if (!!this.state.esgCSRPolicy) {
      if (!!!this.state.esgCSRAdditionalInformation) {
        this.setState({
          isErrorOnEsgCSRPolicy: true,
          errorMessage: 'Please enter information regarding the ESG/CSR policy',
        });
        return false;
      }
    }
    if (!!this.state.sustainableProcurementPolicy) {
      if (!!!this.state.sustainableProcurementAdditionalInformation) {
        this.setState({
          isErrorOnProcurementPolicy: true,
          errorMessage: 'Please enter information regarding the sustainable procurement policy',
        });
        return false;
      }
    }
    this.setState({ isErrorOnEsgCSRPolicy: false, isErrorOnProcurementPolicy: false, errorMessage: '' });
    return true;
  }

  render() {
    if (this.props.shouldDisplay) {
      return (
        <div>
          <div className="row">
            <div className='col-md-12'>
              <div className='mb-3 mt-1'>All mandatory fields are marked with an *</div>
            </div>
          </div>
          <div className='row'>
            <strong>ESG : Environmental, social and corporate governance</strong>
            <strong>CSR : Corporate social responsibility</strong>
          </div>
          <div className='row mt-3'>
            <div className='col-md-12'>
              <label>Company sustainable development policy (human rights, labour and environment)</label>
            </div>
          </div>
          {/* ESG/CSR policy */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-switch m-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switch1"
                  name="switch1"
                  checked={this.state.esgCSRPolicy}
                  onChange={() => this.handleClickSwitch1(!this.state.esgCSRPolicy)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-label" htmlFor="switch1">
                  1 - Has your company initiated an ESG/CSR policy?
                </label>
              </div>
              <div className="form-group m-2 col-md-9">
                <label htmlFor="comment1" className="form-label">
                  You can add any information or link to document
                </label>
                <textarea
                  rows={5}
                  className="form-control mt-1"
                  id="comment1"
                  name="comment1"
                  defaultValue={!!this.state.esgCSRAdditionalInformation ? this.state.esgCSRAdditionalInformation : ''}
                  onChange={(e) => this.onComment1Change(e.target.value)}
                  onFocus={() => this.setState({ isErrorOnEsgCSRPolicy: false })}
                  disabled={this.props.isReadOnly || !this.state.esgCSRPolicy}
                  readOnly={this.props.isReadOnly || !this.state.esgCSRPolicy}
                ></textarea>
                {this.state.isErrorOnEsgCSRPolicy ? (
                  <p id="error-expiration-nda" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          {/* Policy Evaludated */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-switch m-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switch2"
                  name="switch2"
                  checked={this.state.csrPolicyEvaluated}
                  onChange={() => this.handleClickSwitch2(!this.state.csrPolicyEvaluated)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-label" htmlFor="switch2">
                  2 - Is your CSR policy evaluated by Ecovadis or by a similar organism?
                </label>
              </div>
              <div className="form-group m-2 col-md-9">
                <label htmlFor="comment1" className="form-label">
                  If yes, you can add the report on the next page.
                </label>
              </div>
            </div>
          </div>
          {/* Procurement Policy */}
          <div className="mt-2">
            <div className="form-row">
              <div className="form-check form-switch m-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switch3"
                  name="switch3"
                  checked={this.state.sustainableProcurementPolicy}
                  onChange={() => this.handleClickSwitch3(!this.state.sustainableProcurementPolicy)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label form-label" htmlFor="switch3">
                  3 - Do you have a sustainable procurement policy? (for example according to standard ISO 20400)
                </label>
              </div>
              <div className="form-group m-2 col-md-9">
                <label htmlFor="comment3" className="form-label">
                  If yes, you can add any information or link
                </label>
                <textarea
                  rows={5}
                  className="form-control mt-1"
                  id="comment3"
                  name="comment3"
                  defaultValue={
                    !!this.state.sustainableProcurementAdditionalInformation ? this.state.sustainableProcurementAdditionalInformation : ''
                  }
                  onChange={(e) => this.onComment3Change(e.target.value)}
                  onFocus={() => this.setState({ isErrorOnProcurementPolicy: false })}
                  disabled={this.props.isReadOnly || !this.state.sustainableProcurementPolicy}
                  readOnly={this.props.isReadOnly || !this.state.sustainableProcurementPolicy}
                ></textarea>
                {this.state.isErrorOnProcurementPolicy ? (
                  <p id="error-expiration-nda" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          {/* Contact */}
          <div className="mt-4">
            <div className="form-row">
              <div className="form-group m-2 col-md-9">
                <label htmlFor="comment4" className="form-label">
                  Who is/would be the ESG/CSR contact in your organisation?
                </label>
                <textarea
                  rows={3}
                  className="form-control mt-1"
                  id="comment4"
                  name="comment4"
                  placeholder="Name and email"
                  defaultValue={!!this.state.esgCSRContact ? this.state.esgCSRContact : ''}
                  onChange={(e) => this.onComment4Change(e.target.value)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
