import { Component } from 'react';
import { AppProps } from '../models/Props';
import UserService from '../services/user.service';
import { connect } from 'react-redux';
import './cgu.css';
import { updateUserCgu } from '../actions/users.actions';

export class CguProps {
  public appProps: AppProps;
  public history: any;
  updateUserCgu(cgu: boolean) {}
}

export class Cgu extends Component<CguProps> {
  async submit(e) {
    e.preventDefault();

    const res = await UserService.updateCgu(this.props.appProps.userid);
    if (res.status === 204) {
      this.props.updateUserCgu(true);
      this.props.history.push('/products');
    }
  }

  render() {
    document.title = 'CGU - Foreground V3';
    return (
      <div id="administratif_cgu" className="container">
        <div className="row no-margin h-fullscreen">
          <div className="col-12">
            <div className=" card-transparent mx-auto">
              <h1 className="text-uppercase text-center">Foreground CGU</h1>

              <h2>Legal information</h2>
              <p className="font-size-20">(Please read and accept by clicking on the bottom button)</p>

              <h3>Legal Notice</h3>
              <p className="lead">Editor and hosting :</p>

              <p className="font-size-20">Orange</p>
              <p className="lead">
                Public stock corporation (société anonyme) incorporated in France with registered share capital of Euro 10,640,226,396
                <br />
                RCS Nanterre 380 129 866
                <br />
                Headquarters: 111, Quai du Président Roosevelt, Issy-les-moulineaux 92130, France
                <br />
                Tel: + 33 (0)1 44 44 22 22
                <br />
                Publishing Director: Christel HEYDEMANN
              </p>

              <p className="font-size-20">Contact us</p>
              <p className="lead">
                <a href={`mailto:${process.env.REACT_APP_MAIL_TO}`}>{process.env.REACT_APP_MAIL_TO}</a>
              </p>

              <p className="font-size-20">Sites Terms and Conditions</p>
              <p className="lead">
                The website called “Orange Foreground” is edited and operated by Orange SA (“Orange”).
                <br />
                By accessing, browsing and/or using the Sites, you agree to the following terms:
              </p>

              <h3>1. What is the Purpose of the Sites?</h3>
              <p className="lead">Orange Foreground is a website enabling end-users (called Partners) :</p>
              <ul>
                <li className="lead">To provide their company and products public information;</li>
                <li className="lead">To access and update their company and products public information;</li>
                <li className="lead">
                  to have a private space on Orange Foreground, called “Profile”, to edit the information supplied during sign-up.
                </li>
              </ul>

              <h3>2. Content</h3>
              <p className="lead">
                The content (including all information, data, documents, pictures, videos, drawings, audio tracks) (the “Content”) contained on or
                available through the Site is provided by Orange, its affiliates, independent content providers or third parties. It is protected by
                copyright of Orange, its affiliates, independent content providers or third parties. All rights are reserved and any reproduction,
                copy, modification, transfer, distribution or storage of part or all of the Content in any form or by any means without the prior
                written permission of Orange is prohibited.
              </p>

              <h3>3. Orange Rights</h3>
              <p className="lead">
                Orange is and will remain the owner of the Sites (including software, software applications, graphics, design, brands and logos,
                databases) and Content published on the Sites or available through the Sites.
              </p>
              <p className="lead">
                ORANGE, the ORANGE logo and any “Orange” product or service name referred to on the Sites or any Contents published on the Sites or
                available through the Sites are trademarks of Orange or its affiliates. Other products or names mentioned in these Sites or any
                Contents published on the Sites or available through the Sites may be trademarks or trade names of their respective owners. Your
                access to the Sites should not be construed as granting, by implication or otherwise, any licence or right to use any marks appearing
                on the Sites without the prior written consent of Orange or the third party owner thereof.
              </p>

              <h3>4. Right of Use (License)</h3>
              <p className="lead">
                Orange grants you a non-exclusive, non-transferable, revocable, worldwide and non-sublicensable right to use the Sites (including the
                Content) for your personal use only. This right to use is subject to compliance with these Sites Terms and Conditions.
              </p>
              <p className="lead">The right to use does not allow you:</p>
              <ul>
                <li className="lead">1. to access or use the Sites sources codes and/or any of its software components;</li>
                <li className="lead">2. to use, copy, modify or distribute the Sites and/or any of its software components;</li>
                <li className="lead">
                  3. dismantle, decompile or translate the Sites and/or any of its software components, except as expressly authorized by the
                  applicable laws or regulations;
                </li>
                <li className="lead">4. to sub-license or concede the Sites and/or any of its software components.</li>
              </ul>

              <h3>5. Supply of information other than personal information</h3>
              <p className="lead">
                By submitting any non-personal information or material (for example, description of your service or application) to Orange, you grant
                Orange a restricted right to 1use such non-personal information or material for the sole purpose of putting you in contact with
                relevant people at Orange and/or at its affiliates and/or at Orange’s business partners. Therefore, you accept that Orange discloses
                your non-personal information or material to any of its affiliated companies and/or Orange’s business partners.
              </p>
              <p className="lead">
                Your non-personal information or material will not be used for any other purposes and will remain confidential within Orange, its
                affiliates and Orange’s business partners. In case you have the opportunity to work with Orange, any of its affiliates and/or any
                Orange’s business partners and wish to use any services provided by Orange (including the distribution of your service or
                application), specific agreements will apply to such services and suitable rights to use your non-personal information or material
                will be granted.
              </p>

              <h3>6. Orange Liability</h3>
              <p className="lead">
                The Sites and their Content are provided as a convenience to you. The Sites and their Content are provided on an “as is” and “as
                available” basis. Orange does not guarantee that the Sites’ web pages will be uninterrupted or error-free or fit for any particular
                purpose. Orange reserves the right, at any time and at its own discretion, to revise the Sites’ pages or withdraw access to the Sites
                or any part of it. NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR NON-INFRINGEMENT
                OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, IS MADE IN RELATION TO THE AVAILABILITY, ACCURACY,
                RELIABILITY OR CONTENT OF THE SITES. ORANGE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES, LOST
                PROFITS OR FOR BUSINESS INTERRUPTION ARISING OUT OF THE USE OF OR INABILITY TO USE THESE SITES EVEN IF ORANGE HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. Some jurisdictions do not allow exclusions of certain warranties or limitations of liability, so the
                above limitations or exclusions may not apply to you. The liability of Orange would in such case be limited to the greatest extent
                permitted by law.
              </p>

              <h3>7. Third Parties’ Sites</h3>
              <p className="lead">
                For your easy accessibility Orange may include links to sites on the internet that are owned or operated by third parties. You agree
                to review and agree to any such sites rules of use before using such sites. You also agree that Orange has no control over the content
                of any such sites and cannot assume any responsibility for material created or published by such third party sites. In addition, a
                link to a non-Orange site does not imply that Orange endorses the site, the contents published on such third party site or the
                products or services referenced in such third party site.
              </p>

              <h3>8. Use of the Internet</h3>
              <p className="lead">
                The Sites are accessed via the Internet which is independent of Orange. Use of the Internet is solely at your own risk and is subject
                to all applicable national and international laws and regulations. Orange has no responsibility for any information or service
                obtained by you on the Internet.
              </p>

              <h3>9. Changing the Site</h3>
              <p className="lead">
                Orange may suspend or vary the whole or any part of the Sites for any reason, at any time at its sole discretion.
              </p>

              <h3>10. Change of the Site Terms and Conditions</h3>
              <p className="lead">
                Orange reserves the right to vary from time to time the terms of these Sites Terms and Conditions, such variations becoming effective
                immediately upon posting of the varied Sites Terms and Conditions on the Sites. By continuing to use the Sites, you will be deemed to
                have accepted the updated Sites Terms and Conditions available on the Sites.
              </p>

              <h3>11. Choice of Law – Jurisdiction</h3>
              <p className="lead">
                These Sites Terms and Conditions shall be governed by and construed in accordance with the laws of France. You and Orange agree to
                submit to the jurisdiction of the courts of Paris, France, for any claim or matter arising under or in connection with these Sites
                Terms and Conditions.
              </p>

              <h2>Privacy Policy</h2>
              <p className="lead">
                This Privacy Policy explains how Orange SA (“Orange”) uses, shares and protects your personal information and how this protection
                applies to the websites called “Orange Foreground” (the “Sites”). By accessing, browsing and/or using the Sites, you agree to the
                terms of this Privacy Policy.
              </p>

              <h3>1. Your personal information</h3>
              <p className="lead">
                If you wish to register with Orange Foreground or only to contact Orange Foreground, you are required to provide some personal
                information (such as first name, family name, email address). This information will be used (i) to put you in contact with relevant
                people at Orange and/or its affiliates or (ii) to answer your questions.
              </p>
              <p className="lead">
                If you wish to receive newsletters of Orange and information in relation to products and services which Orange considers may be of
                interest to you, you are required to provide personal information (such as your first name, family name, email address). In accordance
                with the European Directive 95/46/EC on Personal Data Protection and French law on personal data dated 6th January 1978, as amended,
                you have the right to access, alter, correct or delete any data that relates to you. You have a right to ask for a copy of your
                information (for which Orange will charge a small fee).
              </p>

              <h3>2. Supply and accuracy of information</h3>
              <p className="lead">
                You guarantee that the information provided by you to Orange is true and accurate and undertake to notify Orange of any changes to the
                information.
              </p>

              <h3>3. Disclosure of your information to third parties</h3>
              <p className="lead">
                In addition to the disclosures referred to above, Orange may disclose your personal data acting in good faith if it believes that such
                action is necessary to: (i) conform with a legal requirement; or (ii) comply with a legal process; or (iii) defend the rights or
                property of Orange.
              </p>

              <h3>4. Cookies</h3>
              <p className="lead">
                A cookie is a small text file sent by a web server when you visit a website and that is stored by your web browser’s device (pc or
                other); so that it can later be read back from that browser. Cookies may store your preferences and other information but will not
                identify you as an individual. Orange uses cookies to help you save time (for instance, to save you from having to type your login
                and/or password again) and to provide, for the purpose of Orange’s internal usage only, some audience statistics in relation to the
                Sites. You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify
                your browser setting to decline cookies if you prefer. If you chose to decline cookies some of the interactive features of these Sites
                may not be available to you. Orange is committed to protecting your privacy and only uses cookies to improve the performance of the
                Sites. The usage patterns tracked by cookies remain confidential and Orange does not share or sell any cookie-generated information
                with third parties.
              </p>

              <h3>5. Minors</h3>
              <p className="lead">
                No information should be submitted to the Sites by persons under the age of 18 years. By registering with Orange Foreground, you
                warrant that you are 18 years of age or over.
              </p>

              <h3>6. Change of the Privacy Policy</h3>
              <p className="lead">
                Orange reserves the right to vary from time to time the terms of this Privacy Policy, such variations becoming effective immediately
                upon posting of the varied Privacy Policy on the Sites. By continuing to use the Sites, you will be deemed to have accepted the
                updated Privacy Policy available on the Sites.
              </p>

              <h3>7. Choice of Law – Jurisdiction</h3>
              <p className="lead">
                This Privacy Policy shall be governed by and construed in accordance with the laws of France. You and Orange agree to submit to the
                jurisdiction of the courts of Paris, France, for any claim or matter arising under or in connection with this Privacy Policy.
              </p>

              <br />
              <br />

              <p className="lead">
                If you have any questions about the handling or protection of your personal data,{' '}
                <a href={`mailto:${process.env.REACT_APP_MAIL_TO}`}>please contact the Orange Foreground Communications Manager at {process.env.REACT_APP_MAIL_TO}</a>
              </p>

              <p className="lead">
                For more information about Orange’s commitments with respect to personal data,{' '}
                <a href="http://www.orange.com/en/privacy-policy" target="_blank">
                  please read our company privacy policy
                </a>
              </p>

              <p className="lead">© Orange 2023</p>

              <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center fs-14">
                <li className="nav-item">
                  <form method="post" onSubmit={(e) => this.submit(e)}>
                    <input type="hidden" value="ok" name="cgu" />
                    <button type="submit" className="btn btn-primary-outline">
                      I read and accept above conditions
                    </button>
                  </form>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 align-self-end text-center font-size-13 mt-4">Copyright © 2023 - Orange. All rights reserved.</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { cgu: state.cgu };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserCgu: (cgu) => {
      dispatch(updateUserCgu(cgu));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cgu);
