import { Country } from './country';

export class UserTypes {
  constructor(public id: number, public label: string) {}
}
export class UserJobs {
  constructor(public id: number, public label: string, public isOrangeJob: boolean) {}
}

export class UserCompany {
  constructor(public id: number, public name: string, public slug: string) {}
}

export class User {
  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public slug: string,
    public email: string,
    public country: Country,
    public phone: string,
    public type: UserTypes,
    public job: UserJobs,
    public company: UserCompany,
    public lastLoginAt: string
  ) {}
}

export class UserToUpdate {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public country: Country,
    public phone: string,
    public type: UserTypes,
    public job: UserJobs,
    public company: UserCompany
  ) {}
}

export class passwordByToken {
  constructor(public email: string, public newPassword: string, public token: string) {}
}
