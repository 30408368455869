import Utils from '../helpers/Utils';
import instance from '../helpers/InterceptorAxios';
export default class UserService {
  /**
   * Authenticate user
   * @param {} body
   */
  static async authenticate(body) {
    return await instance.post(`/api/v1/login`, body);
  }

  /**
   * Get list of Partner users
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async partnerUsers() {
    return await instance.get(`/api/v1/users?typeUsers=PARTNER`, { headers: Utils.getAuthHeader() });
  }

  /**
   * Get list of Plateform users
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async orangeUsers() {
    return await instance.get(`/api/v1/users?typeUsers=ORANGE`, { headers: Utils.getAuthHeader() });
  }

  /**
   * Get details of user
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async details(id) {
    return await instance.get(`/api/v1/users/${id}`, { headers: Utils.getAuthHeader() });
  }

  /**
   * Delete user
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async delete(id) {
    return await instance.delete(`/api/v1/users/${id}`, { headers: Utils.getAuthHeader() });
  }

  /**
   * Update user
   * @param {String} id
   * @param body
   */
  static async updateUser(id, body) {
    return await instance.put(`/api/v1/users/${id}`, body, { headers: Utils.getAuthHeader() });
  }

  /**
   * Update user password
   * @param {String} id
   * @param body
   */
  static async updatePassword(id, body) {
    return await instance.put(`api/v1/users/${id}/password`, body, { headers: Utils.getAuthHeader() });
  }

  static logoutExpiredJwt(response) {
    console.log('logoutExpiredJwt');
    if (response.status === 401 && response.data.message === 'JWT Expired') {
      localStorage.removeItem('token');
      localStorage.removeItem('isReloading');
      window.location.href = '/login';
    }
  }

  /**
   * List user jobs
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async jobsList() {
    return await instance.get(`api/v1/userJobs`, { headers: Utils.getAuthHeader() });
  }

  /**
   * List user types
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async userTypesList() {
    return await instance.get(`api/v1/userTypes`, { headers: Utils.getAuthHeader() });
  }

  /**
   * User ask to register
   * @param body
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async register(body) {
    return await instance.post(`api/v1/register`, body, { headers: Utils.getAuthHeader() });
  }

  /**
   * User asks to reset password
   * @param {} body
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async passwordReset(body) {
    return await instance.post('api/v1/passwordReset', body);
  }

  /**
   * User asks to modify password by token
   * @param {} body
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async passwordByToken(body) {
    return await instance.put('api/v1/passwordByToken', body);
  }

  /**
   * Create User
   * @param body
  
   */
  static async createUser(body) {
    return await instance.post(`api/v1/users`, body, { headers: Utils.getAuthHeader() });
  }

  static async updateCgu(userId: string) {
    return await instance.patch(`api/v1/users/${userId}`, { cgu: true }, { headers: Utils.getAuthHeader() });
  }
}
