import React, { Component } from 'react';
import './pageHeader.css';

class PageHeaderProps {
  title: string;
}

export class PageHeader extends Component<PageHeaderProps> {
  render() {
    return (
      <div className="p-3 bg-white header-div">
        <h1 id="page-title" className="display-2 mt-3">
          {this.props.title}
        </h1>
      </div>
    );
  }
}
