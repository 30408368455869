import { Component } from 'react';
import './ImageLoader.css';
import { Buffer } from 'buffer';

export class ImageLoaderProps {
  public imageUrl: string;
  public altLabel: string;
  public isError: boolean;
  public isReadOnly: boolean;
  public handleImageChanged(imageContent: string, fileName: string) {}
}

export class ImageLoaderState {
  public currentFile: any = null;
  public imgSrc: any;
}

export class ImageLoader extends Component<ImageLoaderProps, ImageLoaderState> {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = new ImageLoaderState();
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const files = e.target.files;
    if (!!files && files.length > 0) {
      const reader = new FileReader();
      reader.readAsBinaryString(files[0]);
      reader.onloadend = function (e) {
        const imageContent = Buffer.from(reader.result.toString(), 'binary').toString('base64');
        this.props.handleImageChanged(imageContent, files[0].name);
      }.bind(this);

      const imageReader = new FileReader();
      imageReader.readAsDataURL(files[0]);
      imageReader.onloadend = function (e) {
        this.setState({
          imgSrc: [imageReader.result],
          currentFile: files[0],
        });
      }.bind(this);
    } else {
      this.setState({
        imgSrc: null,
        currentFile: null,
      });
      this.props.handleImageChanged(null, null);
    }
  }

  render() {
    const showImg = !!this.props.imageUrl || !!this.state.currentFile;
    const imageUrl = !!this.state.currentFile ? this.state.imgSrc : this.props.imageUrl;
    return (
      <div className="image-loader-div-container border border-dark">
        {!this.props.isReadOnly ? (
          <div className="d-flex justify-content-center ">
            <label className="custom-file-upload">
              <input id="uploadImg" type="file" aria-label="Upload a new image" onChange={this.onChange} />
              <i className="icon foreground-Image" />
              <span className="visually-hidden">Upload a new image</span>
            </label>
          </div>
        ) : null}
        <div className="image-loader-div-img">
          {!!showImg ? (
            <img
              className="image-loader-img"
              src={imageUrl}
              alt={this.props.altLabel}
              onError={(e) => {
                (e.target as HTMLImageElement).onerror = null;
              }}
            />
          ) : (
            <div className={`m-auto ${this.props.isError ? 'errorMessage' : ''}`}>
              <p>Upload a new image</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
