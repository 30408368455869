import React, { Component } from 'react';
import { NUMERIC_FIELD_ERROR, NUMERIC_REGEX, UNIT_OPTIONS } from '../../models/constants';
import './company-management.css';
import { ForegroundOption, ForegroundSelectSingle } from '../../components/ForegroundSelect';
import { CompanyWizardStepProps } from './CompanyWizard';

class CompanyRDPolicyStepState {
  errorMessage: string;
  isErrorOnNbEngineersRd: boolean;
  isErrorOnRdAnnualSpending: boolean;
  rdAnnualSpendingUnit: ForegroundOption;

  constructor() {
    this.errorMessage = '';
    this.isErrorOnNbEngineersRd = false;
    this.isErrorOnRdAnnualSpending = false;
    this.rdAnnualSpendingUnit = UNIT_OPTIONS[0];
  }
}

export class CompanyRDPolicyStep extends Component<CompanyWizardStepProps, CompanyRDPolicyStepState> {
  constructor(props) {
    super(props);
    this.state = new CompanyRDPolicyStepState();
    this.handleChangeRdAnnualSpendingUnit = this.handleChangeRdAnnualSpendingUnit.bind(this);
  }

  async componentDidMount() {
    const isCreation = !!!this.props.company.id;
    if (!isCreation) {
      const rdAnnualSpendingUnit = {
        label: this.props.company.rdPolicy.rdAnnualSpendingMoney,
        value: this.props.company.rdPolicy.rdAnnualSpendingMoney,
      };

      this.setState({
        rdAnnualSpendingUnit: rdAnnualSpendingUnit,
      });
    }
    try {
    } catch (e) {
      console.error(e);
    }
  }

  validate() {
    if (this.props.isReadOnly) {
      return true;
    }
    if (this.state.isErrorOnNbEngineersRd || this.state.isErrorOnRdAnnualSpending) {
      return false;
    }
    return true;
  }

  handleChangeRdAnnualSpendingUnit(event: ForegroundOption) {
    this.props.company.rdPolicy.rdAnnualSpendingMoney = event.value;
    this.setState({ rdAnnualSpendingUnit: event });
  }

  render() {
    if (this.props.shouldDisplay) {
      const isCreation = !!!this.props.company.id;
      return (
        <>
          <div className="row">
            <div className='col-md-12'>
              <div className='mb-3 mt-1'>All mandatory fields are marked with an *</div>
              <label htmlFor='rdLocation'>R&D location</label>
              <input
                type='text'
                id='rdLocation'
                name='rdLocation'
                className='form-control mt-2'
                defaultValue={this.props.company.rdPolicy.rdLocation}
                onChange={(e) => {
                  this.props.company.rdPolicy.rdLocation = e.target.value;
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></input>
            </div>
          </div>
          <div className='row mt-4'>
            <div className="col-md-12">
              <label htmlFor="nbEngineersRd">Number of engineers in R&d</label>
              <input
                type="text"
                id="nbEngineersRd"
                name="nbEngineersRd"
                className="form-control mt-2"
                defaultValue={this.props.company.rdPolicy.nbEngineersRd}
                onChange={(e) => {
                  if (NUMERIC_REGEX.test(e.target.value)) {
                    this.props.company.rdPolicy.nbEngineersRd = parseInt(e.target.value);
                    this.setState({ isErrorOnNbEngineersRd: false });
                  } else {
                    this.setState({ isErrorOnNbEngineersRd: true, errorMessage: NUMERIC_FIELD_ERROR });
                  }
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnNbEngineersRd ? 'error-nbEngineersRd' : ''}
                aria-invalid={this.state.isErrorOnNbEngineersRd}
              ></input>
              {this.state.isErrorOnNbEngineersRd ? <p id='error-nbEngineersRd' className="errorMessage">{this.state.errorMessage}</p> : null}
            </div>
          </div>
          <div className="row  mt-4">
            <div className="col-md-7">
              <label htmlFor="rdAnnualSpending">R&D annual spending</label>
              <input
                className="w-100 form-control mt-2"
                type="text"
                id="rdAnnualSpending"
                defaultValue={this.props.company.rdPolicy.rdAnnualSpending}
                onChange={(e) => {
                  if (NUMERIC_REGEX.test(e.target.value)) {
                    this.props.company.rdPolicy.rdAnnualSpending = parseInt(e.target.value);
                    this.setState({ isErrorOnRdAnnualSpending: false });
                  } else {
                    this.setState({ isErrorOnRdAnnualSpending: true, errorMessage: NUMERIC_FIELD_ERROR });
                  }
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
                aria-describedby={this.state.isErrorOnRdAnnualSpending ? 'error-rdAnnualSpending' : ''}
                aria-invalid={this.state.isErrorOnRdAnnualSpending}
              ></input>
              {this.state.isErrorOnRdAnnualSpending ? <p id='error-rdAnnualSpending' className="errorMessage">{this.state.errorMessage}</p> : null}
            </div>
            <div className="col-md-5">
              <label className="" htmlFor="rdAnnualSpendingMoney">
                R&D annual spending unit
              </label>
              <ForegroundSelectSingle
                inputId="rdAnnualSpendingMoney"
                value={this.state.rdAnnualSpendingUnit}
                options={UNIT_OPTIONS}
                isClearable={false}
                onChange={(e) => this.handleChangeRdAnnualSpendingUnit(e)}
                isDisabled={this.props.isReadOnly}
                placeholder="Please select unit for R&D annual spending"
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <label htmlFor="patentPortfolio">Patent portfolio</label>
              <textarea
                className="w-100 form-control mt-2"
                rows={5}
                id="patentPortfolio"
                defaultValue={this.props.company.rdPolicy.patentPortfolio}
                onChange={(e) => {
                  this.props.company.rdPolicy.patentPortfolio = e.target.value;
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></textarea>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <label htmlFor="isoCertificationPolicy">Iso certification policy</label>
              <textarea
                className="w-100 form-control mt-2"
                rows={5}
                id="isoCertificationPolicy"
                defaultValue={this.props.company.qualityPolicyProduction.isoCertificationPolicy}
                onChange={(e) => {
                  this.props.company.qualityPolicyProduction.isoCertificationPolicy = e.target.value;
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></textarea>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <label htmlFor="productionCapacityAndLocation">Production capacity and location</label>
              <textarea
                className="w-100 form-control mt-2"
                rows={5}
                id="productionCapacityAndLocation"
                defaultValue={this.props.company.qualityPolicyProduction.productionCapacityAndLocation}
                onChange={(e) => {
                  this.props.company.qualityPolicyProduction.productionCapacityAndLocation = e.target.value;
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></textarea>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <label htmlFor="productionPartners">Production partners</label>
              <textarea
                className="w-100 form-control mt-2"
                rows={5}
                id="productionPartners"
                defaultValue={this.props.company.qualityPolicyProduction.productionPartners}
                onChange={(e) => {
                  this.props.company.qualityPolicyProduction.productionPartners = e.target.value;
                }}
                readOnly={this.props.isReadOnly}
                disabled={this.props.isReadOnly}
              ></textarea>
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}
