import React, { Component } from 'react';
import './wizard.css';

class WizardHeaderItemProps {
  public currentStep: number;
  public index: number;
  public label: string;
  public nbMaxItem: number;
  public onStepChange(index: number) {}
}

class WizardHeaderItem extends Component<WizardHeaderItemProps> {
  render() {
    const { currentStep, index, label, nbMaxItem } = this.props;

    return (
      <li
        id={`li-${index}`}
        className={`stepped-process-item ${index + 1 === currentStep ? 'active' : ''}`}
        style={{ zIndex: `${nbMaxItem - index}` }}
        role="button"
        onClick={() => {
          this.props.onStepChange(index + 1);
        }}
      >
        <div id={`div-step-${index}`} className=" div-header-step">
          <span id={`span-step-${index}`} className={`span-header-step ${index + 1 < currentStep ? 'text-white' : ''} `}>{`${index + 1}.`}</span>
        </div>
        <div id={`div-step-label-${index}`} className="div-header">
          <div id={`div1-step-label-${index}`} className="span-header d-flex align-content-start ">
            <span id={`span-step-label-${index}`} className={`text-start ${index + 1 < currentStep ? 'text-white' : ''} `}>{`${label}`}</span>
          </div>
        </div>
      </li>
    );
  }
}

class WizardHeaderProps {
  public currentStep: number;
  public canGoForward: boolean;
  public stepLabels: string[];
  public onStepChange(index: number) {}
}

export class WizardHeader extends Component<WizardHeaderProps> {
  constructor(props) {
    super(props);

    this.handleStepChange = this.handleStepChange.bind(this);
  }

  handleStepChange(step) {
    const currentStep = this.props.currentStep;
    if (step <= currentStep || (step > currentStep && this.props.canGoForward)) {
      this.props.onStepChange(step);
    }
  }

  render() {
    const { currentStep } = this.props;
    return (
      <nav className="stepped-process" aria-label="Checkout process">
        <p className="float-start mt-2 me-2 fw-bold d-sm-none">Step</p>
        <ol>
          {!!this.props.stepLabels
            ? this.props.stepLabels.map((label, index) => {
                return (
                  <WizardHeaderItem
                    key={index}
                    index={index}
                    label={label}
                    nbMaxItem={this.props.stepLabels.length}
                    currentStep={currentStep}
                    onStepChange={this.handleStepChange}
                  ></WizardHeaderItem>
                );
              })
            : null}
        </ol>
      </nav>
    );
  }
}
