import { Component } from 'react';
import UserService from '../../services/user.service';
import ModalDeleteUser from './DeleteUserModal';
import LoadingSpinner from '../LoadingSpinner';
import './UserManagement.css';
import { User } from '../../models/user';
import { PageHeader } from '../PageHeader';
import ForcePasswordChange from './UserPasswordChangeModal';
import {
  GENERAL_ERROR,
  INTERNAL_ERROR,
  LISTING_ERROR,
  NOT_VALID_PASSWORD,
  PASSWORD_FORMAT,
  PASSWORD_LENGTH,
  PASSWORD_REGEX,
  USER_NOT_FOUND,
} from '../../models/constants';

enum ClickType {
  PasswordReset = 'passwordReset',
  Delete = 'delete',
  Unknown = 'unknown',
}

class PartnerUsersListProps {}

class PartnerUsersListState {
  public userList: Array<User>;
  public showConfirm: boolean;
  public id: number;
  public firstName: string;
  public lastName: string;
  public isLoading: boolean;
  public clickType: ClickType;
  public newPassword: string;
  public isErrorOnMyNewPassword: boolean;
  public messageError: string;

  constructor() {
    this.userList = [];
    this.showConfirm = false;
    this.id = null;
    this.firstName = null;
    this.lastName = null;
    this.isLoading = true;
    this.clickType = ClickType.Unknown;
    this.newPassword = null;
    this.isErrorOnMyNewPassword = null;
    this.messageError = null;
  }
}

export class PartnerUsersList extends Component<PartnerUsersListProps, PartnerUsersListState> {
  constructor(props) {
    super(props);
    this.state = new PartnerUsersListState();
  }

  async componentDidMount() {
    try {
      let response = await UserService.partnerUsers();
      this.setState({
        userList: response.data,
        isLoading: false,
      });
    } catch (error) {
      console.log('PartnerUsersList : ' + JSON.stringify(error));
      this.setState({ isLoading: false });
    }
  }

  async handleCloseDlg() {
    this.setState({ showConfirm: false });
  }

  async handleDelete() {
    try {
      this.setState({ showConfirm: false });
      await UserService.delete(this.state.id);

      const index = this.state.userList.findIndex((u) => u.id === this.state.id);
      this.state.userList.splice(index, 1);
      this.setState({ userList: this.state.userList });
      document.dispatchEvent(
        new CustomEvent('addNotification', {
          detail: {
            type: 'success',
            content: `The user ${this.state.firstName} ${this.state.lastName} has been deleted.`,
          },
        })
      );
    } catch (error) {
      console.log('PartnerUsersList : ' + JSON.stringify(error));
    }
  }

  handleChangePassword(e) {
    this.setState({ newPassword: e.target.value });
  }

  async onConfirm() {
    if (!PASSWORD_REGEX.test(this.state.newPassword) || this.state.newPassword.length < PASSWORD_LENGTH) {
      this.setState({ isErrorOnMyNewPassword: true, messageError: PASSWORD_FORMAT });
      return false;
    }
    try {
      let response;
      let userid = this.state.id;
      let body = { newPassword: this.state.newPassword };
      response = await UserService.updatePassword(userid, body);
      switch (response.status) {
        case 204:
          document.dispatchEvent(
            new CustomEvent('addNotification', {
              detail: {
                type: 'success',
                content: `The password for  ${this.state.firstName} ${this.state.lastName} has been modified.`,
              },
            })
          );
          break;
        case 403:
          switch (response.data.code) {
            case 1950:
              this.setState({ isErrorOnMyNewPassword: true, messageError: NOT_VALID_PASSWORD });
              break;
            default:
              this.setState({ isErrorOnMyNewPassword: true, messageError: response.data.message });
              break;
          }
          return false;
        case 404:
          this.setState({ isErrorOnMyNewPassword: true, messageError: USER_NOT_FOUND });
          return false;
        case 500:
          this.setState({ isErrorOnMyNewPassword: true, messageError: INTERNAL_ERROR });
          return false;
        default:
          this.setState({ isErrorOnMyNewPassword: true, messageError: response.data.message });
          return false;
      }
    } catch (e) {
      console.log(`err ${JSON.stringify(e)}`);
      this.setState({ isErrorOnMyNewPassword: true, messageError: GENERAL_ERROR });
      return false;
    }
    this.setState({ showConfirm: false });
    this.setState({ isErrorOnMyNewPassword: false, messageError: '' });
    return true;
  }

  renderTable() {
    let errorMessage = !!this.state.userList && Array.isArray(this.state.userList) ? '' : LISTING_ERROR;

    return (
      <>
        {!!errorMessage ? (
          <p id="error-listing" className="errorMessage mt-2">
            {errorMessage}
          </p>
        ) : null}
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Company</th>
              <th>Job</th>
              <th>Phone</th>
              <th>Last Login</th>
              <th>Password Change</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {!!this.state.userList && Array.isArray(this.state.userList)
              ? this.state.userList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td valign={'middle'}>
                        <span>
                          {item.firstName} {item.lastName}
                        </span>
                      </td>
                      <td valign={'middle'}>
                        <a className="not_underlined" href={`mailto:${item.email}`}>
                          {item.email}
                        </a>
                      </td>
                      <td valign={'middle'}>
                        {!!item.company ?
                        <a className="not_underlined" href={`/companies/${item?.company?.slug}`}>
                          {item.company.name}
                        </a> : null}
                      </td>
                      <td valign={'middle'}>
                        <span>{!!item.job ? item.job.label : 'Job Not defined'}</span>
                      </td>
                      <td valign={'middle'}>
                        <span>{!!item.phone ? item.phone : 'Not defined'}</span>
                      </td>
                      <td valign={'middle'}>
                        <span>{!!item.lastLoginAt ? item.lastLoginAt : ''}</span>
                      </td>
                      <td valign={'middle'}>
                        <button
                          id={`passwordUser-${item.id}`}
                          aria-label={`change password for ${item.firstName} ${item.lastName}`}
                          className="btn icon foreground-Password delete_button not_underlined"
                          onClick={() => {
                            this.setState({
                              firstName: item.firstName,
                              lastName: item.lastName,
                              id: item.id,
                              showConfirm: true,
                              clickType: ClickType.PasswordReset,
                            });
                          }}
                        ></button>
                      </td>
                      <td valign={'middle'}>
                        <button
                          id={`deleteUser-${item.id}`}
                          aria-label={`delete ${item.firstName} ${item.lastName} user`}
                          className="btn icon foreground-Trash delete_button not_underlined"
                          onClick={() => {
                            this.setState({
                              firstName: item.firstName,
                              lastName: item.lastName,
                              id: item.id,
                              showConfirm: true,
                              clickType: ClickType.Delete,
                            });
                          }}
                        ></button>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </>
    );
  }

  render() {
    let modal;
    switch (this.state.clickType) {
      case ClickType.Delete:
        modal = (
          <ModalDeleteUser
            show={this.state.showConfirm}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            onClose={() => this.handleCloseDlg()}
            onDelete={() => this.handleDelete()}
          ></ModalDeleteUser>
        );
        break;
      case ClickType.PasswordReset:
        modal = (
          <ForcePasswordChange
            show={this.state.showConfirm}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            onClose={() => this.handleCloseDlg()}
            handleChangePassword={(e) => this.handleChangePassword(e)}
            onConfirm={() => this.onConfirm()}
            isErrorOnMyNewPassword={this.state.isErrorOnMyNewPassword}
            messageError={this.state.messageError}
          ></ForcePasswordChange>
        );
        break;
    }
    return (
      <div className="p-3 bg-white">
        <PageHeader title="Partner users list"></PageHeader>

        <a id="addPartner" className="btn btn-primary mt-2" href="/partner-users/add">
          Add Partner User
        </a>
        {this.state.isLoading ? <LoadingSpinner /> : this.renderTable()}

        {/*Modal call*/}
        {modal}
      </div>
    );
  }
}
