import React, { Component } from 'react';
import './PasswordInput.css';

class PasswordInputProps {
  public inputId: string;
  public placeholder: string;
  public defaultValue?: string;

  public handleChangePassword(e) {}
}

class PasswordInputState {
  public show: boolean;
  isValidPassword: boolean;
  messagePasswordError: string;
  constructor(show: boolean) {
    this.show = show;
    this.isValidPassword = true;
    this.messagePasswordError = '';
  }
}

export class PasswordInput extends Component<PasswordInputProps, PasswordInputState> {
  private htmlRef: React.RefObject<any>;
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.htmlRef = React.createRef();
    this.state = new PasswordInputState(false);
  }

  showNoShow = (e) => {
    e.preventDefault();
    this.setState({ show: !this.state.show });
    this.htmlRef.current.type = this.htmlRef.current.type === 'password' ? 'text' : 'password';
  };

  /**
   * Change valid pasword
   * @param e
   */
  handleChangePassword(e) {
    if (e.target.value.trim().length === 0) {
      this.setState({ isValidPassword: false, messagePasswordError: 'Password must be not empty' });
    } else {
      this.setState({ isValidPassword: true, messagePasswordError: '' });
      this.props.handleChangePassword(e);
    }
  }

  onKeyDownValue(event) {
    if (event.key === 'Enter') {
      this.showNoShow(event);
    }
  }

  render() {
    return (
      <div>
        <div className="d-flex flex-row mt-1">
          <input
            type="password"
            className={`form-control border-right-0 ${this.state.isValidPassword ? '' : 'error'}  has-icon`}
            id={this.props.inputId}
            ref={this.htmlRef}
            onChange={(e) => this.handleChangePassword(e)}
            onFocus={(e) => this.handleChangePassword(e)}
            onBlur={(e) => this.handleChangePassword(e)}
            defaultValue={this.props.defaultValue}
            placeholder={this.props.placeholder}
            aria-describedby={this.state.isValidPassword ? '' : 'error-password'}
            aria-invalid={!this.state.isValidPassword}
            aria-required="true"
          ></input>
          <div className="position-relative" id={`show-${this.props.inputId}`} aria-label={`Show ${this.props.placeholder}`}>
            <i
              onKeyDown={(e) => this.onKeyDownValue(e)}
              onClick={(e) => this.showNoShow(e)}
              tabIndex={0}
              className={`eye-position ${this.state.show ? 'foreground-HiddenEye' : 'foreground-ic_Vision'} icon-27`}
              role={`button`}
            >
              <span className="visually-hidden">`${this.state.show ? 'Hide' : 'Show'}`</span>
            </i>
          </div>
        </div>
        {this.state.isValidPassword ? null : (
          <div>
            <p id="error-password" className="errorMessage">
              {this.state.messagePasswordError}
            </p>
          </div>
        )}
      </div>
    );
  }
}
