import './pagination.css';
import ReactPaginate from 'react-paginate';

export default function Pagination(props) {
  return (
    <nav aria-label="Page navigation" className="mt-4">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={props.handlePageClick}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        pageCount={props.nbPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
        breakClassName="paginateBreakClassName"
        pageClassName="paginatePageClassName"
        previousClassName="paginatePageClassName"
        nextClassName="paginatePageClassName"
        pageLinkClassName="paginatePageLinkClassName"
        breakLinkClassName="paginateBreakLinkClassName"
        previousLinkClassName="paginateNextPreviousLinkClassName"
        nextLinkClassName="paginateNextPreviousLinkClassName"
        containerClassName="paginateContainerClassName"
        disabledClassName="paginateDisabledClassName"
        disabledLinkClassName="paginateDisabledLinkClassName"
        activeClassName="paginateActiveClassName"
        activeLinkClassName="paginateActiveLinkClassName"
        forcePage={props.currentPage}
      />
    </nav>
  );
}
