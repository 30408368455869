import React, { Component } from 'react';
import './report/boosted-grid.min.css';
import './report/pie.css';
import './report/style.css';

export class Accessibility extends Component {
  render() {
    return (
      <>
        <div className='container-md content'>
          <h1>Accessibility statement : Foreground </h1>
          <div className='summary'>
            <div className='row'>
              <div className='col-lg-12'>
                <h2 className='h4 mt-4'>Context</h2>
                <p>Orange undertakes to make its websites internet, intranet, extranet and its mobile applications
                  accessible in accordance with article 47 of Law No. 2005-102 of February 11, 2005.</p>
                <p>To this end, it implements the following strategy and actions :</p>
                <p><a href='https://oran.ge/accessibilite-schema'
                      title='the pluri-annual accessibility framework (French) (PDF, 230Ko)'>the pluri-annual
                  accessibility framework (French)</a>.</p>
                <p>This accessibility statement applies to Foreground
                  https://foreground-v3-pre-prod.apps.fr01.paas.tech.orange/</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg'>
              <h2 className='h4 mt-4'>Conformity level:</h2>
              <p>"Foreground" is partially compliant to the criteria with <a href='https://www.w3.org/TR/WCAG22/'><abbr
                lang='en' title='Web Content Accessibility Guidelines'>WCAG</abbr> version 2.2, level AA</a>.</p>
              <p>The Orange Group Accessibility Expertise Centre refers to the WCAG international recommendations. <a
                href='https://a11y-guidelines.orange.com/fr/cadrage/correspondance-rgaa-wcag/'>Here is the
                correspondence
                link between the RGAA and the WCAG</a>.</p>
              <p>The audit was carried out via <a href='https://la-va11ydette.orange.com/?lang=en'>la Va11ydette</a>.
              </p>
              <h3>Tests result</h3>
              <div className='row summary'>
                <div className='col-lg-3'>
                  <h4 className='pie' data-value='55'>
                    <span className='visually-hidden'>Global compliance </span>
                    <span className='pie-val'>55%</span>
                  </h4>
                  <p className='lead'>Global compliance
                  </p>
                </div>
                <div className='col-lg-3'>
                  <h4 className='pie' data-value='73'>
                    <span className='visually-hidden'>Average compliance </span>
                    <span className='pie-val'>73%</span>
                  </h4>
                  <p className='lead'>Average compliance</p>
                </div>
              </div>
              <p>The compliance audit carried out by The Orange Group Accessibility Expertise Centre reveals that :</p>
              <ul>
                <li>55% of WCAG level A and AA criteria are met.</li>
                <li>undefined 73%.</li>
              </ul>
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <caption>Summary by levels</caption>
                  <thead>
                  <tr>
                    <th scope='row'>Level</th>
                    <th scope='col' className='text-center'>A</th>
                    <th scope='col' className='text-center'>AA</th>
                    <th scope='col' className='text-center'>Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th scope='row' className='font-weight-bold'>Number of criteria</th>
                    <td className='text-center'>32</td>
                    <td className='text-center'>24</td>
                    <td className='text-center'>56</td>
                  </tr>
                  <tr>
                    <th scope='row' className='font-weight-bold'>Compliant</th>
                    <td className='text-center'>12</td>
                    <td className='text-center'>9</td>
                    <td className='text-center'>21</td>
                  </tr>
                  <tr>
                    <th scope='row' className='font-weight-bold'>Non-compliant</th>
                    <td className='text-center'>10</td>
                    <td className='text-center'>7</td>
                    <td className='text-center'>17</td>
                  </tr>
                  <tr>
                    <th scope='row' className='font-weight-bold'>Not applicable</th>
                    <td className='text-center'>10</td>
                    <td className='text-center'>8</td>
                    <td className='text-center'>18</td>
                  </tr>
                  <tr>
                    <th scope='row' className='font-weight-bold bg-light'>Compliance</th>
                    <td className='text-center bg-light'>
                      55%
                    </td>
                    <td className='text-center bg-light'>
                      56%
                    </td>
                    <td className='text-center bg-light'>
                      55%
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <caption>Compliance rate by pages according to the two levels of criteria A and AA</caption>
                  <tbody>
                  <tr>
                    <th scope='row'>Criteria / Level</th>
                    <th scope='col' className='text-center'>Compliant / A</th>
                    <th scope='col' className='text-center'>Compliant / AA</th>
                    <th scope='col' className='text-center'>Non-compliant / A</th>
                    <th scope='col' className='text-center'>Non-compliant / AA</th>
                    <th scope='col' className='text-center'>Not applicable / A</th>
                    <th scope='col' className='text-center'>Not applicable / AA</th>
                    <th scope='col' className='text-center bg-light'>Compliance rate</th>
                  </tr>
                  <tr>
                    <th scope='row'><span className='visually-hidden'>Page : </span>Connexion</th>
                    <td>13</td>
                    <td>11</td>
                    <td>5</td>
                    <td>3</td>
                    <td>14</td>
                    <td>10</td>
                    <td className='background-color:#ddd !important;'>75 %</td>
                  </tr>
                  <tr>
                    <th scope='row'><span className='visually-hidden'>Page : </span>Accueil</th>
                    <td>12</td>
                    <td>11</td>
                    <td>6</td>
                    <td>2</td>
                    <td>14</td>
                    <td>11</td>
                    <td className='background-color:#ddd !important;'>74 %</td>
                  </tr>
                  <tr>
                    <th scope='row'><span className='visually-hidden'>Page : </span>Products</th>
                    <td>13</td>
                    <td>9</td>
                    <td>5</td>
                    <td>4</td>
                    <td>14</td>
                    <td>11</td>
                    <td className='background-color:#ddd !important;'>71 %</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg'>
              <h2 className='h4 mt-4'>Content not accessible</h2>
              <p>The contents listed below are not accessible for the following reasons.</p>
              <h3 className='h6 mt-4'>Non-compliances</h3>
              <p>List of non-compliant WCAG criteria :</p>
              <ul>
                <li>
                  1.1.1 Non-text Content, Level A
                </li>
                <li>
                  1.2.2 Captions (Prerecorded), Level A
                </li>
                <li>
                  1.2.3 Audio Description or Media Alternative (Prerecorded), Level A
                </li>
                <li>
                  1.2.5 Audio Description (Prerecorded), Level AA
                </li>
                <li>
                  1.3.1 Info and Relationships, Level A
                </li>
                <li>
                  1.3.5 Identify Input Purpose, Level AA
                </li>
                <li>
                  1.4.3 Contrast (Minimum), Level AA
                </li>
                <li>
                  1.4.4 Resize text, Level AA
                </li>
                <li>
                  1.4.5 Images of Text, Level AA
                </li>
                <li>
                  2.1.1 Keyboard, Level A
                </li>
                <li>
                  2.4.1 Bypass Blocks, Level A
                </li>
                <li>
                  2.4.2 Page Titled, Level A
                </li>
                <li>
                  2.4.6 Headings and Labels, Level AA
                </li>
                <li>
                  3.3.1 Error Identification, Level A
                </li>
                <li>
                  3.3.2 Labels or Instructions, Level A
                </li>
                <li>
                  4.1.2 Name, Role, Value, Level A
                </li>
                <li>
                  4.1.3 Status Messages, Level AA
                </li>
              </ul>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg'>
              <h2 className='h4 mt-4'>Establishment of this accessibility statement</h2>
              <p>This declaration was established on 5/2/2024. </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg'>
              <h3>Technical specifications of the site</h3>
              <ul>
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
              </ul>
              <h3>Testing environment</h3>
              <p>The verifications were performed with the following software combinations:</p>
              <ul>
                <li>Chrome XX and JAWS 2019</li>
                <li>Firefox XX and NVDA 2020.4</li>
              </ul>
              <h3>Methods and Tools to evaluate accessibility</h3>
              <ul>
                <li><strong>Automatic testing:</strong> aXe 4.9.3, Wave 3.1.3</li>
                <li><strong>Functional testing:</strong> NVDA 2020.4, Keyboard navigation</li>
              </ul>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg'>
              <h3>Pages which have been the subject of compliance verification</h3>
              <p>The verification audit was performed on the following pages using the Orange va11ydette: </p>
              <ol>
                <li><strong>Connexion : </strong>https://foreground-v3-pre-prod.apps.fr01.paas.tech.orange/login</li>
                <li><strong>Accueil : </strong>https://foreground-v3-pre-prod.apps.fr01.paas.tech.orange/</li>
                <li><strong>Products : </strong>https://foreground-v3-pre-prod.apps.fr01.paas.tech.orange/products</li>
              </ol>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg'>
              <h2 className='h4 mt-4'>Feedback and contact information</h2>
              <p>If you are unable to access content or a service, you can contact the site manager to be directed to an
                accessible alternative or obtain the content in another form.</p>
              <p>We welcome your feedback on the accessibility of this site. Please let us know if you encounter
                accessibility problems by sending an email to <a
                  href='mailto:accessibilite.france@orange.com'>accessibilite.france@orange.com</a>.</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg'>
              <h2 className='h4 mt-4'>Legal remedies</h2>
              <p>You have reported to the website manager an accessibility problem that prevents you from accessing
                content or one of the portal's services and you have not received a satisfactory response, in this
                case:</p>
              <ul>
                <li>Write a message to the <a href='https://formulaire.defenseurdesdroits.fr/'>Défenseurs des Droits</a>.
                </li>
                <li>Or contact the <a href='https://www.defenseurdesdroits.fr/saisir/delegues'>Défenseurs des Droits
                  delegate in your department</a>.
                </li>
                <li>Or send a letter by post (free of charge, do not put a stamp) <br />
                  <address> Défenseur des droits <br /> Libre réponse 71120 <br /> 75342 Paris CEDEX 07</address>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>);
  }
}