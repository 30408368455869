import { Component } from 'react';
import CompanyService from '../../services/company.service';
import { createBrowserHistory } from 'history';
import LoadingSpinner from '../../components/LoadingSpinner';
import { CompanyCard } from './CompanyCard';
import './company-management.css';
import Pagination from '../../components/Pagination';
import { ORANGE_ADMIN_SCOPE } from '../../models/constants';
import {
  ForegroundSelectSingle,
  ForegroundSelectMulti,
  SELECT_ACTION,
  ForegroundOption,
  ForegroundSelectMetaAction,
} from '../../components/ForegroundSelect';
import { Company, CompanyCount } from '../../models/company';
import { AppProps } from '../../models/Props';
import { LISTING_ERROR } from '../../models/constants';
import { PageHeader } from '../../components/PageHeader';
import Utils from '../../helpers/Utils';

const NB_PARTNER_PER_PAGE = 18;

const canBeOrderBy = [new ForegroundOption('Last updated', 'updatedAt'), new ForegroundOption('Creation order', 'id')];

export class CompaniesListProps {
  public appProps: AppProps;
  public history: any;
}

class CompaniesListState {
  companiesList: Company[];
  companiesCount: CompanyCount;
  isLoading: boolean;
  nbPages: number;
  nbCompanies: number;
  nbTotalCompanies: number;
  currentOrder: ForegroundOption;
  currentStatusFilter: ForegroundOption[];
  currentCollaborationFilter: ForegroundOption[];
  currentCountriesFilter: ForegroundOption[];
  companiesStatus: ForegroundOption[];
  companiesCollaboration: ForegroundOption[];
  companyCountries: ForegroundOption[];
  currentPage: number;
  companyName: string;
  errorMessage: string;

  constructor() {
    this.companiesList = [];
    this.companiesCount = new CompanyCount();
    this.isLoading = true;
    this.nbPages = 1;
    this.nbCompanies = 0;
    this.nbTotalCompanies = 0;
    this.currentOrder = canBeOrderBy[0];
    this.currentStatusFilter = [];
    this.currentCollaborationFilter = [];
    this.currentCountriesFilter = [];
    this.companiesStatus = [];
    this.companiesCollaboration = [];
    this.companyCountries = [];
    this.currentPage = 0;
    this.companyName = '';
    this.errorMessage = '';
  }
}

export class CompaniesList extends Component<CompaniesListProps, CompaniesListState> {
  constructor(props) {
    super(props);
    this.state = new CompaniesListState();
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleChangeFilterStatus = this.handleChangeFilterStatus.bind(this);
    this.handleChangeFilterCollaboration = this.handleChangeFilterCollaboration.bind(this);
    this.handleChangeFilterCountries = this.handleChangeFilterCountries.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChangeCompanyName = this.handleChangeCompanyName.bind(this);
    this.reset = this.reset.bind(this);
    this.handleApplyFilter = this.handleApplyFilter.bind(this);
  }

  async componentDidMount() {
    let companyName = Utils.getFilter(Utils.COMPANYFILTERNAME);
    if(companyName) {
      this.setState({ companyName: companyName});
    }
    let companyStatus = Utils.getFilter(Utils.COMPANYFILTERSTATUS);
    if(companyStatus) {
      this.setState({ currentStatusFilter: companyStatus});
      companyStatus = companyStatus.map((s) => s.value).join(',');
    }
    let collaboration = Utils.getFilter(Utils.COMPANYFILTERCOLLABORATIONS);
    if(collaboration) {
      this.setState({ currentCollaborationFilter: collaboration});
      collaboration = collaboration.map((s) => s.value).join(',');
    }
    let countries = Utils.getFilter(Utils.COMPANYFILTERCOUNTRIES);
    if(countries) {
      this.setState({ currentCountriesFilter: countries});
      countries = countries.map((s) => s.value).join(',');
    }

    try {
      let response = await Promise.all([
        CompanyService.companiesList(0, NB_PARTNER_PER_PAGE, this.state.currentOrder.value, companyStatus, collaboration, countries, companyName),
        CompanyService.companiesCount(),
      ]);

      const nbCompanies = response[0].headers['x-total-count'];
      const nbPages = Math.ceil(nbCompanies / NB_PARTNER_PER_PAGE);
      const companiesStatus = !!response[1].data.companyStatus
        ? response[1].data.companyStatus.map((item) => {
            return new ForegroundOption(`${item.label} (${item.nbCompanies})`, item.id);
          })
        : [];
      const companiesCollaboration = !!response[1].data.companyCollaboration
        ? response[1].data.companyCollaboration.map((item) => {
            return new ForegroundOption(`${item.label} (${item.nbCompanies})`, item.id);
          })
        : [];

      const companyCountries = !!response[1].data.companyCountries
        ? response[1].data.companyCountries.map((item) => {
            return new ForegroundOption(`${item.label} (${item.nbCompanies})`, item.id);
          })
        : [];

      let errorMessage = '';
      let companiesList = !!response[0] && !!response[0].data ? response[0].data : [];
      this.setState({
        companiesList: companiesList,
        companiesCount: response[1].data,
        isLoading: false,
        nbPages: nbPages,
        nbCompanies: nbCompanies,
        nbTotalCompanies: nbCompanies,
        companiesStatus: companiesStatus,
        companiesCollaboration: companiesCollaboration,
        companyCountries: companyCountries,
        errorMessage: errorMessage,
      });
    } catch (error) {
      console.log(`ERROR CompaniesList : ${JSON.stringify(error)}`);
      this.setState({ isLoading: false, errorMessage: LISTING_ERROR });
    }
  }

  private setStoredFilters(companyName, statusFilter, collaborationFilter, countries) {
    Utils.setFilter(Utils.COMPANYFILTERNAME,companyName);
    Utils.setFilter(Utils.COMPANYFILTERSTATUS,statusFilter);
    Utils.setFilter(Utils.COMPANYFILTERCOLLABORATIONS,collaborationFilter);
    Utils.setFilter(Utils.COMPANYFILTERCOUNTRIES,countries);
  }

  private deleteStoredFilters() {
    Utils.deleteFilter(Utils.COMPANYFILTERNAME);
    Utils.deleteFilter(Utils.COMPANYFILTERSTATUS);
    Utils.deleteFilter(Utils.COMPANYFILTERCOLLABORATIONS);
    Utils.deleteFilter(Utils.COMPANYFILTERCOUNTRIES);
  }

  reset() {
    // do NOT remove this instruction, which seems redundant with this.refresh()
    // otherwise, companiesList.test.tsx won't be successful anymore (?!)
    this.setState({
      currentPage: 0,
      currentStatusFilter: [],
      currentCollaborationFilter: [],
      currentCountriesFilter: [],
      currentOrder: canBeOrderBy[0],
      companyName: '',
    });
    this.deleteStoredFilters();
    this.refresh(canBeOrderBy[0], [], [], [], 0, '');
  }

  handleApplyFilter() {
    const currentCollaborationFilter = this.state.currentCollaborationFilter;
    const currentCountriesFilter = this.state.currentCountriesFilter;
    const currentStatusFilter = this.state.currentStatusFilter;
    const currentOrder = this.state.currentOrder;
    const currentCompanyName = this.state.companyName;
    const currentPage = this.state.currentPage;

    this.setStoredFilters(currentCompanyName, currentStatusFilter, currentCollaborationFilter, currentCountriesFilter);

    this.refresh(currentOrder, currentStatusFilter, currentCollaborationFilter, currentCountriesFilter, currentPage, currentCompanyName);
  }

  async CSVPartnerExport() {
    this.setState({ isLoading: true });
      const SEP = ',', LINESEP = '\r\n';
      const header = 'company name' + SEP + 'address' + SEP + 'zip code' + SEP + 'city' + SEP + 'country' + SEP + 'number of products' + SEP + 'registered date' + SEP + 'on-going CSC' + SEP + 'CSC date of signature' + SEP + 'CSC date of expiration' + LINESEP;

      const statusFilter = this.state.currentStatusFilter.map((s) => s.value).join(',');
      const collaborationFilter = this.state.currentCollaborationFilter.map((s) => s.value).join(',');
      const countriesFilter = this.state.currentCountriesFilter.map((s) => s.value).join(',');

      try {
        let response = await CompanyService.companiesList(null,null,
          this.state.currentOrder.value,
          statusFilter,
          collaborationFilter,
          countriesFilter,
          this.state.companyName
        )
        const CSVData =
          header +
          response.data
            .map(
              (item) =>
                Utils.formatCSVData(item.name) +
                SEP +
                Utils.formatCSVData(item.address) +
                SEP +
                Utils.formatCSVData(item.zipcode) +
                SEP +
                Utils.formatCSVData(item.city) +
                SEP +
                Utils.formatCSVData(item.country) +
                SEP +
                Utils.formatCSVData(item.nbDevices) +
                SEP +
                Utils.formatCSVData(Utils.formatDateToWeb(item.createdAt)) +
                SEP +
                Utils.getCSCStatus(item.corporateSourcingContract, SEP) +
                LINESEP
            )
            .join('');
        const url = window.URL.createObjectURL(new Blob([CSVData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', Utils.formatDateToWebWithTime(Date()).replace(/\s/g, '') + '.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        document.dispatchEvent(
          new CustomEvent('addNotification', {
            detail: { type: 'success', content: 'CSV Export successful. The file has been downloaded on your computer!' },
          })
        );
      }
      catch (error) {
        console.log(`ERROR CompaniesList : ${JSON.stringify(error)}`);
        document.dispatchEvent(
          new CustomEvent('addNotification', {
            detail: {
              type: 'danger',
              category: 'error',
              content: 'CSV Export failed. Try again later!'
            }
          })
        )
      }
    this.setState({ isLoading: false });
  }

  async refresh(
    currentOrder: ForegroundOption,
    currentStatusFilter: ForegroundOption[],
    currentCollaborationFilter: ForegroundOption[],
    currentCountriesFilter: ForegroundOption[],
    currentPage: number,
    companyName: string
  ) {
    const statusFilter = currentStatusFilter.map((s) => s.value).join(',');
    const collaborationFilter = currentCollaborationFilter.map((s) => s.value).join(',');
    const countriesFilter = currentCountriesFilter.map((s) => s.value).join(',');

    try {
      let response = await CompanyService.companiesList(
        currentPage * NB_PARTNER_PER_PAGE,
        NB_PARTNER_PER_PAGE,
        currentOrder.value,
        statusFilter,
        collaborationFilter,
        countriesFilter,
        companyName
      );
      const nbCompanies = response.headers['x-total-count'];
      const nbPages = Math.ceil(nbCompanies / NB_PARTNER_PER_PAGE);
      const asNoFilter =
        currentStatusFilter.length === 0 && currentCollaborationFilter.length === 0 && currentCountriesFilter.length === 0 && companyName === '';
      const nbTotalCompanies = asNoFilter ? nbCompanies : this.state.nbTotalCompanies;

      let companiesList = !!response.data ? response.data : [];
      this.setState({
        companiesList: companiesList,
        isLoading: false,
        nbPages: nbPages,
        nbCompanies: nbCompanies,
        nbTotalCompanies: nbTotalCompanies,
      });
    } catch (error) {
      console.log(`ERROR CompaniesList (refresh) : ${JSON.stringify(error)}`);
      this.setState({ isLoading: false, errorMessage: LISTING_ERROR });
    }
  }

  handlePageClick(event) {
    this.setState({ currentPage: event.selected });
    const currentCollaborationFilter = this.state.currentCollaborationFilter;
    const currentCountriesFilter = this.state.currentCountriesFilter;
    const currentStatusFilter = this.state.currentStatusFilter;
    const currentOrder = this.state.currentOrder;
    const currentCompanyName = this.state.companyName;
    this.refresh(currentOrder, currentStatusFilter, currentCollaborationFilter, currentCountriesFilter, event.selected, currentCompanyName);
  }
  handleChangeOrder(event: ForegroundOption) {
    const currentCollaborationFilter = this.state.currentCollaborationFilter;
    const currentCountriesFilter = this.state.currentCountriesFilter;
    const currentStatusFilter = this.state.currentStatusFilter;
    this.setState({ currentOrder: event, currentPage: 0 });
    const currentCompanyName = this.state.companyName;

    this.refresh(event, currentStatusFilter, currentCollaborationFilter, currentCountriesFilter, 0, currentCompanyName);
  }

  handleChangeFilterStatus(event: ForegroundOption[], action: ForegroundSelectMetaAction) {
    const currentStatusFilter = this.state.currentStatusFilter;
    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        this.setState({ currentStatusFilter: event, currentPage: 0 });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentStatusFilter.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentStatusFilter: newSelection, currentPage: 0 });
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentStatusFilter: [], currentPage: 0 });
        break;
      default:
    }
  }

  handleChangeFilterCollaboration(event: ForegroundOption[], action: ForegroundSelectMetaAction) {
    const currentCollaborationFilter = this.state.currentCollaborationFilter;
    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        this.setState({ currentCollaborationFilter: event, currentPage: 0 });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentCollaborationFilter.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentCollaborationFilter: newSelection, currentPage: 0 });
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentCollaborationFilter: [], currentPage: 0 });
        break;
      default:
    }
  }

  handleChangeFilterCountries(event: ForegroundOption[], action: ForegroundSelectMetaAction) {
    const currentCountriesFilter = this.state.currentCountriesFilter;
    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        this.setState({ currentCountriesFilter: event, currentPage: 0 });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentCountriesFilter.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentCountriesFilter: newSelection, currentPage: 0 });
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentCountriesFilter: [], currentPage: 0 });
        break;
      default:
    }
  }

  handleChangeCompanyName(value: string) {
    this.setState({ companyName: value });
  }

  renderTable() {
    let currentOrder = this.state.currentOrder;
    const companiesStatus = this.state.companiesStatus;
    const companiesCollaboration = this.state.companiesCollaboration;

    const companyCountries = this.state.companyCountries;
    const scope = this.props.appProps.scope;

    let { nbCompanies, nbTotalCompanies, companiesList, errorMessage } = this.state;

    // In case of missing data, in order to avoid uncaught runtime errors
    if (!!!companiesList || !Array.isArray(companiesList)) {
      companiesList = [];
      errorMessage = LISTING_ERROR;
    }
    if (!!!nbCompanies) nbCompanies = 0;
    if (!!!nbTotalCompanies) nbTotalCompanies = 0;

    return (
      <div id="background" className="container mt-4">
        <div className="">
          <p className="catalog-total-numbers mt-4">{`${nbTotalCompanies} ${nbTotalCompanies > 1 ? 'partners' : 'partner'}`}</p>
          <p className="catalog-criterias-numbers">
            {`${nbCompanies} ${nbCompanies > 1 ? 'partners matching your criterias' : 'partner matching your criterias'}`}{' '}
          </p>
          {!!errorMessage ? (
            <p id="error-listing" className="errorMessage mt-2">
              {errorMessage}
            </p>
          ) : null}
        </div>
        {scope === ORANGE_ADMIN_SCOPE ? (
          <div className="row mt-4 mb-4">
            <a id="addPartnerButton" className="add btn btn-primary m-1" href="/companies/add">
              Add Partner
            </a>
            <button id="CSVPartnerButton" onClick={() => this.CSVPartnerExport()} className="add btn btn-primary m-1">
              Filtered CSV Export
            </button>
          </div>
        ) : (
          ''
        )}
        <div className="row">
          <div id="filterDiv" className="col-md-3">
            <p id="filterTitle" className="filter-title">
              Filter
            </p>
            <label className="orderby-title" htmlFor="name_filter">
              Company name
            </label>
            <input
              className=" form-control mt-2 mb-3"
              type="text"
              id="name_filter"
              placeholder="Write company name"
              maxLength={191}
              value={this.state.companyName}
              onChange={(e) => {
                this.handleChangeCompanyName(e.target.value);
              }}
            ></input>
            <label id="statusTitle" className="orderby-title" htmlFor="status_filter">
              Status
            </label>
            <div id="statusDiv" className="mb-3">
              <ForegroundSelectMulti
                inputId="status_filter"
                placeholder="Select status"
                value={this.state.currentStatusFilter}
                options={companiesStatus}
                onChange={(e, a) => this.handleChangeFilterStatus(e, a)}
              />
            </div>
            <label className="orderby-title" htmlFor="collaboration_filter">
              Collaborations
            </label>
            <div id="collaborationDiv" className="mb-3">
              <ForegroundSelectMulti
                inputId="collaboration_filter"
                placeholder="Select collaboration"
                value={this.state.currentCollaborationFilter}
                options={companiesCollaboration}
                onChange={(e, a) => this.handleChangeFilterCollaboration(e, a)}
              />
            </div>
            <label className="orderby-title" htmlFor="country_filter">
              Countries
            </label>
            <ForegroundSelectMulti
              inputId="country_filter"
              placeholder="Select country"
              value={this.state.currentCountriesFilter}
              options={companyCountries}
              onChange={(e, a) => this.handleChangeFilterCountries(e, a)}
            />
            <div className="row mt-3">
              <div className="col-md-3">
                <button id="reset-1" onClick={() => this.reset()} className="me-3 btn btn-secondary">
                  Reset
                </button>
              </div>
              <div className="col-md-3 ms-3">
                <button id="apply-1" onClick={() => this.handleApplyFilter()} className="btn btn-primary">
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-8 offset-md-1">
            <div className="row justify-content-end orderby-div">
              <div id="orederByDiv" className="form-group col-md-4">
                <label className="orderby-title " htmlFor="orderbyInput">
                  Order By
                </label>
                <ForegroundSelectSingle
                  inputId="orderbyInput"
                  value={currentOrder}
                  options={canBeOrderBy}
                  isClearable={false}
                  onChange={(e) => this.handleChangeOrder(e)}
                  placeholder="Select order"
                />
              </div>
            </div>
            <div className="companies-list ">
              {!!companiesList && Array.isArray(companiesList)
                ? companiesList.map((partner) => {
                    return <CompanyCard partner={partner} history={this.props.history} key={partner.id}></CompanyCard>;
                  })
                : null}
            </div>
          </div>
        </div>
        {this.state.nbPages > 1 ? (
          <Pagination handlePageClick={this.handlePageClick} nbPages={this.state.nbPages} currentPage={this.state.currentPage} />
        ) : (
          ''
        )}
      </div>
    );
  }

  render() {
    document.title = 'Partners list - Foreground V3';
    return (
      <div className="p-3 bg-white">
        <PageHeader title="Partners list"></PageHeader>
        {this.state.isLoading ? <LoadingSpinner /> : this.renderTable()}
      </div>
    );
  }
}

export default createBrowserHistory();
