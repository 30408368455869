import React, { Component } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { PageHeader } from './PageHeader';
import { MANDATORY_AT_LEAST_ON_FIELD, MANDATORY_FIELD_MISSING } from '../models/constants';
import { ImageLoader } from './ImageLoader';
import { DeviceTechnology, DeviceTechnologyImage } from '../models/device';
import ProductService from '../services/product.service';
import './ReferenceData.css';

class AddEditTechnologyProps {
  public history: any;
}

class AddEditTechnologyState {
  public isLoading: boolean;
  public label: string;
  public isErrorOnLabel: boolean;
  public isError: boolean;
  public errorMessage: string;
  public isImageError: boolean;
  public orderIndex: number;
  public imageName: string;
  public imageContent: string;
  public imageLink: string;
  public editMode: boolean;
  public id: number;
  public baseImgURL: string;

  constructor() {
    this.isLoading = true;
    this.label = '';
    this.isErrorOnLabel = false;
    this.isError = false;
    this.errorMessage = '';
    this.isImageError = false;
    this.orderIndex = 0;
    this.imageName = '';
    this.imageContent = '';
    this.imageLink = '';
    this.editMode = false;
    this.id = 0;
    this.baseImgURL = '';
  }
}

export class AddEditTechnology extends Component<AddEditTechnologyProps, AddEditTechnologyState> {
  orderIndex: number;
  techno: DeviceTechnology;
  baseImgURL: string;

  constructor(props) {
    super(props);
    if (props.location && props.location.state) {
      this.orderIndex = props.location.state.orderIndex;
      this.techno = props.location.state.techno;
      this.baseImgURL = props.location.state.baseImgURL;
    }
    this.state = new AddEditTechnologyState();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImageChanged = this.handleImageChanged.bind(this);
  }

  async componentDidMount() {
    try {
      if (this.techno) {
        this.setState({
          label: this.techno.label,
          isLoading: false,
          editMode: true,
          id: this.techno.id,
          baseImgURL: this.baseImgURL,
          imageLink: this.techno.image.link,
        });
      } else {
        this.setState({
          orderIndex: this.orderIndex,
          isLoading: false,
          editMode: false,
        });
      }
    } catch (error) {
      console.log('AddEditTechnology : ' + JSON.stringify(error));
      this.setState({ isLoading: false });
    }
  }

  handleImageChanged(image: string, name: string) {
    this.setState({ imageName: name, imageContent: image, isError: false });
  }

  handleChangeLabel = (event) => {
    const value = event.target.value;
    this.setState({ label: value, isErrorOnLabel: false, isError: false });
  };

  validate() {
    if (this.state.editMode) {
      if (!!!this.state.label && this.state.label.trim().length === 0 && !!!this.state.imageContent && this.state.imageContent.length === 0) {
        this.setState({ isError: true, errorMessage: MANDATORY_AT_LEAST_ON_FIELD });
        return false;
      }
    } else {
      if (!!!this.state.label && this.state.label.trim().length === 0) {
        this.setState({ isErrorOnLabel: true, errorMessage: MANDATORY_FIELD_MISSING });
        return false;
      }
      if (!!!this.state.imageContent && this.state.imageContent.length === 0) {
        this.setState({ isImageError: true });
        return false;
      }
    }
    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.validate()) {
      console.log(`error validate`);
      return;
    }
    try {
      let image: DeviceTechnologyImage = new DeviceTechnologyImage(this.state.imageContent, this.state.imageName);
      let techno: DeviceTechnology = new DeviceTechnology(this.orderIndex, this.state.label, image);
      if (!!this.state.editMode) {
        techno.id = this.state.id;
        ProductService.updateTechnology(techno)
          .then((res) => {
            if (res.status === 201) {
              document.dispatchEvent(
                new CustomEvent('addNotification', {
                  detail: {
                    type: 'success',
                    content: `The techno ${this.state.label} has been modified.`,
                  },
                })
              );
              this.props.history.push('/reference-data');
            } else {
              if (res.status >= 400) {
                this.setState({ isError: true, errorMessage: res.data.message + ': ' + res.data.description });
              }
            }
          })
          .catch((e) => {
            console.log(`err ${JSON.stringify(e)}`);
            this.setState({ isError: true, errorMessage: e.message });
          });
      } else {
        ProductService.addTechnology(techno)
          .then((res) => {
            if (res.status === 201) {
              document.dispatchEvent(
                new CustomEvent('addNotification', {
                  detail: {
                    type: 'success',
                    content: `The techno ${this.state.label} has been added.`,
                  },
                })
              );
              this.props.history.push('/reference-data');
            } else {
              if (res.status >= 400) {
                this.setState({ isError: true, errorMessage: res.data.message + ': ' + res.data.description });
              }
            }
          })
          .catch((e) => {
            console.log(`err ${JSON.stringify(e)}`);
            this.setState({ isError: true, errorMessage: e.message });
          });
      }
    } catch (e) {
      let message = 'Error';
      this.setState({ isError: true, errorMessage: message });
    }
  }

  renderForm() {
    return (
      <div className="container">
        <form id="myForm" className="mt-2" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6 offset-md-0">
              <div className='form-group mb-3'>
                {this.state.editMode ? (
                  <p className='technology-reminder'>
                    Modify either Label and / or Image. The changes will be applied to all devices using this
                    technology.
                  </p>
                ) : (
                  ''
                )}
                <div className='mb-3 mt-2'>All mandatory fields are marked with an *</div>
                <label htmlFor='label' className={this.state.editMode ? '' : 'is-required'}>
                  Label
                </label>
                <input
                  type='text'
                  value={this.state.label}
                  className='form-control mt-2'
                  id='label'
                  name='label'
                  maxLength={191}
                  onChange={this.handleChangeLabel}
                />
                {this.state.isErrorOnLabel ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
              </div>
              <label htmlFor='label' className={this.state.editMode ? '' : 'is-required'}>
                Image
              </label>
              <div className="mt-2">
                <ImageLoader
                  imageUrl={this.state.baseImgURL + this.state.imageLink}
                  altLabel="Technology Image"
                  handleImageChanged={this.handleImageChanged}
                  isError={this.state.isImageError}
                  isReadOnly={null}
                ></ImageLoader>
              </div>
              <div className="form-group my-3 d-flex flex-row justify-content-end">
                <button onClick={() => this.props.history.push('/reference-data')} className="me-3 btn btn-secondary">
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {this.state.editMode ? 'Save' : 'Add'}
                </button>
                <br></br>
              </div>
            </div>
            {this.state.isError ? <p className="errorMessage">{this.state.errorMessage}</p> : null}
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="p-3 bg-white">
        <PageHeader title={(this.state.editMode ? "Edit '" + this.state.label + "'" : 'Add') + ' Technology'}></PageHeader>
        {this.state.isLoading ? <LoadingSpinner /> : this.renderForm()}
      </div>
    );
  }
}
