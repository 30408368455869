import React, { Component } from 'react';

export class Legalmatters extends Component {
  render() {
    return (
      <div className="Legalmatters" id="legalmatters">
        <div id="administratif_legalmatters" className="border-bottom border-light pt-3">
          <div className="container">
            <h1 className="display-2">Legal matters</h1>
          </div>
        </div>

        <div className="container mt-4">
          <div className="row">
            <div className="col-10 mt-5">
              <h2 className="mb-1 display-4">Editor</h2>
              <h3 className="mb-1 display-5">Orange</h3>
              <p className="line-height-1-5 font-18 mb-5">
                Public stock corporation (société anonyme) incorporated in France with registered share capital of Euro 10 640 226 396
                <br></br>
                RCS Nanterre 380 129 866
                <br></br>
                Headquarters: 111, Quai du Président Roosevelt, Issy-les-moulineaux 92130, France
                <br></br>
                Tel: + 33 (0)1 44 44 22 22
                <br></br>
              </p>

              <h2 className="mb-1 display-4">Hosting</h2>
              <h3 className="mb-1 display-5">Orange</h3>
              <p className="line-height-1-5 font-18 mb-5">
                Public stock corporation (société anonyme) incorporated in France with registered share capital of Euro 10 640 226 396
                <br></br>
                RCS Nanterre 380 129 866
                <br></br>
                Headquarters: 111, Quai du Président Roosevelt, Issy-les-moulineaux 92130, France
                <br></br>
                Tel: + 33 (0)1 44 44 22 22
                <br></br>
              </p>

              <h2 className="mb-1 display-4">Publishing director</h2>
              <p className="line-height-1-5 font-18 mb-5">Christel HEYDEMANN</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
