import React, { Component } from 'react';

export class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="PrivacyPolicy" id="privacypolicy">
        <div id="administratif_privacypolicy" className="pt-3 border-bottom border-light">
          <div className="container">
            <h1 className="display-2 mt-3">Privacy policy</h1>
          </div>
        </div>
        <div className="container">
          <div className="row mt-4">
            <div className="col-10">
              <p className="mb-3 display-5">
                This Privacy Policy explains how Orange SA (“Orange”) uses, shares and protects your personal information and how this protection
                applies to the websites called “Orange Foreground” (the “Sites”). By accessing, browsing and/or using the Sites, you agree to the
                terms of this Privacy Policy.
              </p>
              <p className="mb-5 font-10">Last updated: March 16th, 2020</p>

              <h2 className="mb-1 display-4">1. Your personal information</h2>
              <p className="font-18 line-height-1-5 mb-5">
                If you wish to register with Orange Foreground or only to contact Orange Foreground, you are required to provide some personal
                information (such as first name, family name, email address). This information will be used (i) to put you in contact with relevant
                people at Orange and/or its affiliates or (ii) to answer your questions. If you wish to receive newsletters of Orange and information
                in relation to products and services which Orange considers may be of interest to you, you are required to provide personal
                information (such as your first name, family name, email address). In accordance with the European Directive 95/46/EC on Personal Data
                Protection and French law on personal data dated 6th January 1978, as amended, you have the right to access, alter, correct or delete
                any data that relates to you. You have a right to ask for a copy of your information (for which Orange will charge a small fee).
              </p>

              <h2 className="mb-1 display-4">2. Supply and accuracy of information</h2>
              <p className="font-18 line-height-1-5 mb-5">
                You guarantee that the information provided by you to Orange is true and accurate and undertake to notify Orange of any changes to the
                information.
              </p>

              <h2 className="mb-1 display-4">3. Disclosure of your information to third parties</h2>
              <p className="font-18 line-height-1-5 mb-5">
                In addition to the disclosures referred to above, Orange may disclose your personal data acting in good faith if it believes that such
                action is necessary to: (i) conform with a legal requirement; or (ii) comply with a legal process; or (iii) defend the rights or
                property of Orange.
              </p>

              <h2 className="mb-1 display-4">4. Cookies</h2>
              <p className="font-18 line-height-1-5 mb-5">
                A cookie is a small text file sent by a web server when you visit a website and that is stored by your web browser’s device (pc or
                other); so that it can later be read back from that browser. Cookies may store your preferences and other information but will not
                identify you as an individual. Orange uses cookies to help you save time (for instance, to save you from having to type your login
                and/or password again) and to provide, for the purpose of Orange’s internal usage only, some audience statistics in relation to the
                Sites. You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify
                your browser setting to decline cookies if you prefer. If you chose to decline cookies some of the interactive features of these Sites
                may not be available to you. Orange is committed to protecting your privacy and only uses cookies to improve the performance of the
                Sites. The usage patterns tracked by cookies remain confidential and Orange does not share or sell any cookie-generated information
                with third parties.
              </p>

              <h2 className="mb-1 display-4">5. Minors</h2>
              <p className="font-18 line-height-1-5 mb-5">
                No information should be submitted to the Sites by persons under the age of 18 years. By registering with Orange Foreground, you
                warrant that you are 18 years of age or over.
              </p>
              <h2 className="mb-1 display-4">6. Change of the Privacy policy</h2>
              <p className="font-18 line-height-1-5 mb-5">
                Orange reserves the right to vary from time to time the terms of this Privacy Policy, such variations becoming effective immediately
                upon posting of the varied Privacy Policy on the Sites. By continuing to use the Sites, you will be deemed to have accepted the
                updated Privacy Policy available on the Sites.
              </p>

              <h2 className="mb-1 display-4">7. Choice of Law - Jurisdiction</h2>
              <p className="font-18 line-height-1-5 mb-5">
                This Privacy Policy shall be governed by and construed in accordance with the laws of France. You and Orange agree to submit to the
                jurisdiction of the courts of Paris, France, for any claim or matter arising under or in connection with this Privacy Policy.
              </p>

              <hr />
              <p>
                <strong>
                  If you have any questions about the handling or protection of your personal data, please contact the Orange Foreground
                  Communications Manager at {process.env.REACT_APP_MAIL_TO}
                </strong>
              </p>
              <p>
                <strong>
                  For more information about Orange’s commitments with respect to personal data, please read our company privacy policy:
                  <br />
                  <a href="https://www.orange.com/en/privacy-notice-protecting-your-personal-data">
                    {' '}
                    https://www.orange.com/en/privacy-notice-protecting-your-personal-data
                  </a>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
