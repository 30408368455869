import React, { Component } from 'react';
import { connect } from 'react-redux';

import { UserPassword } from './UserPassword';
import { UserPrivacies } from './UserPrivacies';
import { UserDetails } from './UserDetails';
import { AppProps } from '../../models/Props';
import { PageHeader } from '../PageHeader';

class UserProfileProps {
  public appProps: AppProps;
  public history: any;
}

class UserProfile extends Component<UserProfileProps> {
  render() {
    const location = window.location.pathname;
    const newProps = { ...this.props.appProps, ...this.props };

    let userContent;
    if(location.startsWith('/user-profile/privacies')) {
      document.title = 'Account / Privacy controls - Foreground V3';
      userContent= <UserPrivacies></UserPrivacies>
    } else if (location.startsWith('/user-profile/password')) {
      document.title = 'Account / Password - Foreground V3';
      userContent= <UserPassword appProps={this.props.appProps} history={this.props.history}></UserPassword>
    } else if (location.startsWith('/user-profile/details')) {
      document.title = 'Account / Details - Foreground V3';
      userContent= <UserDetails {...newProps}></UserDetails>
    }

    return (
      <div className="container" id="userprofile">
        <PageHeader title="Account"></PageHeader>

        <div id="localContainer" className="d-flex flex-row col-10 collapse navbar-collapse justify-content-between align-items-baseline mt-3">
          <ul className="nav nav-tabs">
            <li className="nav-item" role="button">
              <a className={`nav-link ${location.startsWith('/user-profile/details') ? 'active' : ''}`} href="/user-profile/details">
                Details
              </a>
            </li>
            <li className="nav-item" role="button">
              <a className={`nav-link ${location.startsWith('/user-profile/password') ? 'active' : ''}`} href="/user-profile/password">
                Password
              </a>
            </li>
            <li className="nav-item" role="button">
              <a className={`nav-link ${location.startsWith('/user-profile/privacies') ? 'active' : ''}`} href="/user-profile/privacies">
                Privacy controls
              </a>
            </li>
          </ul>
        </div>

        {userContent}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userid: state.userid,
    firstname: state.firstname,
    lastname: state.lastname,
    scope: state.scope,
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(UserProfile);
