import { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  updateUserToken,
  updateUserScope,
  updateUserLastName,
  updateUserFirstName,
  updateUserId,
  updateCompanySlug,
  updateUserCgu,
  updateUserCanDeleteCompaniesAndDevices,
} from './actions/users.actions';
import Accueil from './pages/accueil/Accueil';
import Login from './pages/login/Login';
import { Header } from './components/Header';
import { addNotificationEvent } from './events/NotificationEvent';
import { Legalmatters } from './pages/Legalmatters';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { Accessibility} from './pages/Accessibility';
import { SignupRegister } from './pages/SignupRegister';
import { TermsConditions } from './pages/TermsConditions';
import UserProfile from './components/user-management/UserProfile';
import { Footer } from './components/Footer';
import { PartnerUsersList } from './components/user-management/PartnerUsersList';
import { AddPartnerUser } from './components/user-management/AddPartnerUser';
import { OrangeUsersList } from './components/user-management/OrangeUsersList';
import { AddOrangeUser } from './components/user-management/AddOrangeUser';
import GuardedRoute from './components/GuardedRoute';
import { ProducstList } from './pages/product_management/ProductsList';
import { ReferenceData } from './components/ReferenceData';
import { AddEditTechnology } from './components/AddEditTechnology';

import Utils from './helpers/Utils';
import { CompaniesList } from './pages/company-management/CompaniesList';
import { CompanyCreateModify } from './pages/company-management/CompanyCreateModify';
import { DeviceCreateModify } from './pages/product_management/DeviceCreateModify';
import { DeviceInformations } from './pages/product_management/DeviceInformations';
import { CompanyInformations } from './pages/company-management/CompanyInformations';
import { SetPasswordByToken } from './components/user-management/SetPasswordByToken';
import Cgu from './pages/Cgu';
import { ChangePassword } from './components/user-management/ChangePassword';
import { Redirect } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { TOKEN_NUMBER_OF_SECONDS_ALERT, TOKEN_VERIFY_DELAY } from './models/constants';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      warning_displayed:false,
    };
    this.logout = this.logout.bind(this);
  }

  redirectToLogin(window) {
    if (window.location.pathname === '/health') {
      return;
    }
    if (window.location.pathname !== '/Login') {
      window.location = '/Login';
    }
  }

  componentDidMount() {
    /**
     * Customs events listener
     */
    document.addEventListener('addNotification', addNotificationEvent, false);
    this.timer = setInterval(()=> this.checkToken(), TOKEN_VERIFY_DELAY);
  }

  componentWillUnmount() {
    this.timer = null;
  }

  checkToken() {
    try {
      const decoded = jwtDecode(Utils.getAuthToken());
      const exp = decoded.exp;
      const now=Math.round(Date.now()/1000);
      const diff = exp - now;
      if(!this.state.warning_displayed && diff <= TOKEN_NUMBER_OF_SECONDS_ALERT) {
        const remainingDays = Math.floor(TOKEN_NUMBER_OF_SECONDS_ALERT/86400);
        document.dispatchEvent(
          new CustomEvent('addNotification', { detail: { type: 'danger', category: 'error', content: 'Your token will expire in '+remainingDays+' days ! Please logout and login as soon as possible...' } })
        );
        this.setState({ warning_displayed: true });
      }
      if(exp <= now) {
        document.dispatchEvent(
          new CustomEvent('addNotification', { detail: { type: 'danger', category: 'error', content: 'Your token has expired! You have been disconnected...' } })
        );
        this.logout();
      }
    }
    catch (e) { }
  }

  logout() {
    this.props.updateUserToken(null);
    this.props.updateUserLastName(null);
    this.props.updateUserFirstName(null);
    this.props.updateUserScope(null);
    this.props.updateUserId(null);
    this.props.updateCompanySlug(null);
    this.props.updateUserCgu(null);
    this.props.updateUserCanDeleteCompaniesAndDevices(null);
    Utils.logout();
    localStorage.removeItem('isReloading');
    Utils.deleteFilter(Utils.PRODUCTFILTERSTATUS);
    Utils.deleteFilter(Utils.PRODUCTFILTERTYPES);
    Utils.deleteFilter(Utils.PRODUCTFILTERCOMPANIES);
    Utils.deleteFilter(Utils.PRODUCTFILTERNETWORK);
    Utils.deleteFilter(Utils.PRODUCTFILTERVERTICALS);
    Utils.deleteFilter(Utils.PRODUCTFILTERUSECASES);
    Utils.deleteFilter(Utils.PRODUCTFILTERORDER);
    Utils.deleteFilter(Utils.PRODUCTFILTERDEVICENAME);
    Utils.deleteFilter(Utils.PRODUCTFILTERCOMPANYNAME);
    Utils.deleteFilter(Utils.COMPANYFILTERNAME);
    Utils.deleteFilter(Utils.COMPANYFILTERSTATUS);
    Utils.deleteFilter(Utils.COMPANYFILTERCOLLABORATIONS);
    Utils.deleteFilter(Utils.COMPANYFILTERCOUNTRIES);
  }

  render() {
    const newProps = { logout: this.logout, ...this.props };
    return (
      <BrowserRouter basename="/">
        <div className="App">
          <main className="main-container">
            <GuardedRoute appProps={newProps} path="/" component={Header} />
            <div
              id="containerNotifications"
              style={{
                position: 'fixed',
                right: 10,
                top: 110,
                zIndex: 999999,
              }}
            ></div>

            {/*Home*/}
            <Route exact path="/" component={Accueil} />
            <Route exact path="/login" component={Login} />
            <Route path="/register" component={SignupRegister} />
            <Route path="/legal-matters" component={Legalmatters} />
            <Route path="/terms-conditions" component={TermsConditions} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/accessibility" component={Accessibility} />
            <Route exact path="/devices" render={() => <Redirect to={'/products'} />} />
            <Route exact path="/devices/:id/edit" render={(match) => <Redirect to={`/products/${match.match.params.id}/edit`} />} />
            <Route exact path="/devices/:id" render={(match) => <Redirect to={`/products/${match.match.params.id}`} />} />
            <GuardedRoute appProps={this.props} path="/cgu" component={Cgu} />
            <GuardedRoute appProps={{ logout: this.logout, ...this.props }} path="/changePassword" component={ChangePassword} />
            <GuardedRoute appProps={this.props} path="/passwordReset/:token" component={SetPasswordByToken} />
            <GuardedRoute appProps={newProps} path="/user-profile" component={UserProfile} />
            <GuardedRoute appProps={this.props} exact path="/partner-users" component={PartnerUsersList} />
            <GuardedRoute appProps={this.props} exact path="/partner-users/add" component={AddPartnerUser} />
            <GuardedRoute appProps={this.props} exact path="/plateform-users" component={OrangeUsersList} />
            <GuardedRoute appProps={this.props} exact path="/plateform-users/add" component={AddOrangeUser} />
            <GuardedRoute appProps={this.props} exact path="/companies" component={CompaniesList} />
            <GuardedRoute appProps={this.props} exact path="/companies/:id/edit" component={CompanyCreateModify} />
            <GuardedRoute appProps={this.props} exact path="/companies/:id" component={CompanyInformations} />
            <GuardedRoute appProps={this.props} exact path="/companies/add" component={CompanyCreateModify} />
            <GuardedRoute appProps={this.props} exact path="/products" component={ProducstList} />
            <GuardedRoute appProps={this.props} exact path="/products/:id/edit" component={DeviceCreateModify} />
            <GuardedRoute appProps={this.props} exact path="/products/:id" component={DeviceInformations} />
            <GuardedRoute appProps={this.props} exact path="/solutions/:id" component={DeviceInformations} />
            <GuardedRoute appProps={this.props} exact path="/products/add" component={DeviceCreateModify} />
            <GuardedRoute appProps={this.props} exact path="/reference-data" component={ReferenceData} />
            <GuardedRoute appProps={this.props} exact path="/reference-data/add-edit/" component={AddEditTechnology} />
          </main>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    userid: state.userid,
    firstname: state.firstname,
    lastname: state.lastname,
    scope: state.scope,
    companySlug: state.companySlug,
    cgu: state.cgu,
    canDeleteCompaniesAndDevices: state.canDeleteCompaniesAndDevices,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserToken: (token) => {
      dispatch(updateUserToken(token));
    },
    updateUserLastName: (lastname) => {
      dispatch(updateUserLastName(lastname));
    },
    updateUserFirstName: (firstname) => {
      dispatch(updateUserFirstName(firstname));
    },
    updateUserScope: (scope) => {
      dispatch(updateUserScope(scope));
    },
    updateUserId: (userid) => {
      dispatch(updateUserId(userid));
    },
    updateCompanySlug: (companySlug) => {
      dispatch(updateCompanySlug(companySlug));
    },
    updateUserCgu: (cgu) => {
      dispatch(updateUserCgu(cgu));
    },
    updateUserCanDeleteCompaniesAndDevices: (canDeleteCompaniesAndDevices) => {
      dispatch(updateUserCanDeleteCompaniesAndDevices(canDeleteCompaniesAndDevices));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
