import React, { Component } from 'react';
import Select, { StylesConfig } from 'react-select';

export const SELECT_STYLES = {
  control: (styles) => ({ ...styles, border: '.125rem solid #ccc', borderRadius: '0', boxShadow: 'unset', minHeight: '40px' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: '#000',
      backgroundColor: '#fff',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        color: '#fff',
        backgroundColor: '#000',
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      color: '#000',
      backgroundColor: '#fff',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#000',
    backgroundColor: '#fff',
  }),
  groupHeading: (base) => ({
    ...base,
    flex: '1 1',
    color: '#000',
    margin: 0,
    fontWeight: '700',
    fontSize: '18px',
  }),
};

export enum SELECT_ACTION {
  CLEAR = 'clear',
  SELECT_OPTION = 'select-option',
  REMOVE_VALUE = 'remove-value',
}

export class ForegroundOption {
  public label: string;
  public value: any;

  constructor(label: string, value: any) {
    this.label = label;
    this.value = value;
  }
}

export class ForegroundSubItemGroup {
  public label: string;
  public value: any;
  public group: string;

  constructor(label: string, value: any, group: string) {
    this.label = label;
    this.value = value;
    this.group = group;
  }
}

export class ForegroundOptionGroup {
  public label: string;
  public value: any;
  public options: ForegroundSubItemGroup;

  constructor(label: string, value: any, options: ForegroundSubItemGroup) {
    this.label = label;
    this.value = value;
    this.options = options;
  }
}

export class ForegroundSelectPropsBase {
  public isClearable?: boolean;
  public closeOnSelect?: boolean;
  public styles?: StylesConfig;

  public inputId: string;

  public placeholder: string;
  public value?: any;

  public isDisabled?: boolean;

  public ariaDescribedBy?: string;
  public ariaInvalid?: boolean;
  public ariaLabel?: string;
  public ariaRequired?: boolean;
}

export class ForegroundSelectPropsSingle extends ForegroundSelectPropsBase {
  public options: ForegroundOption[];
  public onChange(newValue, action) {}
  public onFocus?() {}
}

export class ForegroundSelectMetaAction {
  public action: SELECT_ACTION;
  public option: ForegroundOption;
  public removedValue: ForegroundOption;
}

export class ForegroundSelectPropsMulti extends ForegroundSelectPropsBase {
  public options: ForegroundOption[];
  public onChange(newValue, action) {}
  public onFocus?() {}
}

export class ForegroundSelectPropsMultiGroup extends ForegroundSelectPropsBase {
  public options: ForegroundOptionGroup[];
  public onChange(newValue, action) {}
}

export class ForegroundSelectState {
  public isClearable: boolean;
  public closeOnSelect?: boolean;
  public styles?: any;
}

export class ForegroundSelectSingle extends Component<ForegroundSelectPropsSingle, ForegroundSelectState> {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const isClearable = this.props.isClearable === undefined ? true : this.props.isClearable;
    const closeOnSelect = this.props.closeOnSelect === undefined ? false : this.props.closeOnSelect;
    const styles = {
      control: (styles) => ({ 
        ...styles, border: '.125rem solid #ccc', borderRadius: '0', boxShadow: 'unset', minHeight: '40px' 
      }),
      placeholder: (defaultStyles) => ({
          ...defaultStyles,
          color: '#000',
      })
    };
    this.state = { isClearable: isClearable, closeOnSelect: closeOnSelect, styles: styles };
  }

  render() {
    return (
      <Select
        className="mt-2"
        classNamePrefix={this.props.inputId}
        onFocus={this.props.onFocus}
        inputId={this.props.inputId}
        hideSelectedOptions={false}
        styles = { this.state.styles }
        isMulti={false}
        placeholder={this.props.placeholder}
        value={this.props.value}
        options={this.props.options}
        onChange={(newValue, a) => this.props.onChange(newValue, a)}
        isDisabled={this.props.isDisabled}
        isClearable={this.state.isClearable}
        closeMenuOnSelect={this.state.closeOnSelect}
        aria-describedby={this.props.ariaDescribedBy}
        aria-invalid={this.props.ariaInvalid}
        required={this.props.ariaRequired}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#ff7900',
            primary: 'black',
          },
        })}
      />
    );
  }
}

export class ForegroundSelectMulti extends Component<ForegroundSelectPropsMulti, ForegroundSelectState> {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const isClearable = this.props.isClearable === undefined ? true : this.props.isClearable;
    const styles = { 
      control: (styles) => ({ 
        ...styles, border: '.125rem solid #ccc', borderRadius: '0', boxShadow: 'unset', minHeight: '40px' 
      }),
      placeholder: (defaultStyles) => ({
          ...defaultStyles,
          color: '#000',
      })
  };
    this.state = { isClearable: isClearable , styles: styles };
  }

  render() {
    return (
      <Select
        className="mt-2"
        classNamePrefix={this.props.inputId}
        inputId={this.props.inputId}
        onFocus={this.props.onFocus}
        hideSelectedOptions={false}
        styles = {this.state.styles}
        isMulti={true}
        placeholder={this.props.placeholder}
        value={this.props.value}
        options={this.props.options}
        onChange={(newValue, a) => this.props.onChange(newValue, a)}
        isDisabled={this.props.isDisabled}
        isClearable={this.state.isClearable}
        closeMenuOnSelect={false}
        aria-describedby={this.props.ariaDescribedBy}
        aria-invalid={this.props.ariaInvalid}
        required={this.props.ariaRequired}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#ff7900',
            primary: 'black',
          },
        })}
      />
    );
  }
}
