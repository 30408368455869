import React, { Component } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import CountryService from '../services/country.service';
import UserService from '../services/user.service';
import { createBrowserHistory } from 'history';
import {
  AUTOCOMPLETE_STYLE,
  BAD_EMAIL_FORMAT, BAD_WEB_URL,
  EMAIL_REGEX,
  MANDATORY_FIELD_MISSING,
  PLEASE_SELECT_COUNTRY,
  PLEASE_SELECT_JOB_POSITION, WEB_URL_REGEX,
  ZINDEX_HIGHEST,
} from '../models/constants';
import LoadingSpinner from '../components/LoadingSpinner';
import { ForegroundOption, ForegroundSelectSingle } from '../components/ForegroundSelect';
import { Country } from '../models/country';
import { UserJobs } from '../models/user';

class SignupRegisterProps {
  history: any;
}

class SignupRegisterState {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  companyWebSite: string;
  currentCountry: Country;
  currentJob: ForegroundOption;
  checked: boolean;
  submitDisabled:boolean;

  isErrorOnFirstName: boolean;
  isErrorOnLastName: boolean;
  isErrorOnEmail: boolean;
  isErrorOnCompanyName: boolean;
  isErrorOnCompanyWebSite: boolean;
  isErrorOnCountry: boolean;
  isErrorOnJob: boolean;
  isErrorOnChecked: boolean;

  isError: boolean;
  errorMessage: string;

  countries: Array<Country>;
  userJobs: Array<UserJobs>;
  userJobsOptions: Array<ForegroundOption>;
  isLoading: boolean;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.companyName = '';
    this.companyWebSite = '';
    this.currentCountry = null;
    this.currentJob = null;
    this.checked = false;
    this.submitDisabled= false ;

    this.isErrorOnFirstName = false;
    this.isErrorOnLastName = false;
    this.isErrorOnEmail = false;
    this.isErrorOnCompanyName = false;
    this.isErrorOnCompanyWebSite = false;
    this.isErrorOnCountry = false;
    this.isErrorOnJob = false;
    this.isErrorOnChecked = false;

    this.isError = false;
    this.errorMessage = '';

    this.countries = [];
    this.userJobs = [];
    this.userJobsOptions = [];
    this.isLoading = true;
  }
}

export class SignupRegister extends Component<SignupRegisterProps, SignupRegisterState> {
  constructor(props) {
    super(props);
    this.state = new SignupRegisterState();
    this.handleChangeUserJob = this.handleChangeUserJob.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeFirstName = (event: { target: { value: string; }; }) => {
    const value = event.target.value;
    if (value.trim().length !== 0) {
      this.setState({ firstName: value, isErrorOnFirstName: false });
    } else {
      this.setState({ firstName: null, isErrorOnFirstName: true });
    }
  };

  handleChangeLastName = (event: { target: { value: string; }; }) => {
    const value = event.target.value;
    if (value.trim().length !== 0) {
      this.setState({ lastName: value, isErrorOnLastName: false });
    } else {
      this.setState({ lastName: value, isErrorOnLastName: true });
    }
  };

  handleChangeEmail = (event: { target: { value: string; }; }) => {
    const value = event.target.value;

    if (value.trim().length !== 0) {
      this.setState({ email: value, isErrorOnEmail: false });
    } else {
      this.setState({ email: null, isErrorOnEmail: true });
    }

  };

  handleChangeUserJob(event: ForegroundOption) {
    this.setState({ currentJob: event, isErrorOnJob: false });
  }

  handleChangeCompanyName = (event: { target: { value: string; }; }) => {
    const value = event.target.value;
    if (value.trim().length !== 0) {
      this.setState({ companyName: value, isErrorOnCompanyName: false });
    } else {
      this.setState({ companyName: null, isErrorOnCompanyName: true });
    }
  };

  handleChangeCompanyWebSite = (event: { target: { value: string; }; }) => {
    const value = event.target.value;
    if (value.trim().length !== 0) {
      this.setState({ companyWebSite: value, isErrorOnCompanyWebSite: false });
    } else {
      this.setState({ companyWebSite: null, isErrorOnCompanyWebSite: true });
    }
  };

  handleChangeChecked(event) {
    if (!event.target.checked) {
      this.setState({ checked: event.target.checked, isErrorOnChecked: true });
    } else {
      this.setState({ checked: event.target.checked, isErrorOnChecked: false });
    }
  }

  validate() {
    if (this.state.firstName.trim().length === 0) {
      this.setState({ isErrorOnFirstName: true });
      return false;
    }

    if (this.state.lastName.trim().length === 0) {
      this.setState({ isErrorOnLastName: true });
      return false;
    }

    if (!EMAIL_REGEX.test(this.state.email)) {
      this.setState({ isErrorOnEmail: true });
      return false;
    }

    if (this.state.currentCountry === null) {
      this.setState({ isErrorOnCountry: true });
      return false;
    }

    if (this.state.currentJob === null) {
      this.setState({ isErrorOnJob: true });
      return false;
    }

    if (this.state.companyName.trim().length === 0) {
      this.setState({ isErrorOnCompanyName: true });
      return false;
    }

    if (!WEB_URL_REGEX.test(this.state.companyWebSite)) {
      this.setState({ isErrorOnCompanyWebSite: true });
      return false;
    }

    if (!this.state.checked) {
      this.setState({ isErrorOnChecked: true });
      return false;
    }

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.submitDisabled) return
    this.setState({ isError: false });

    if (!this.validate()) {
      return;
    }
    this.setState({ submitDisabled:true });

    const currentCountry = this.state.currentCountry;
    const currentJob = new UserJobs(this.state.currentJob.value, this.state.currentJob.label, null);

    const currentCompanyActivity = { 'id': 4, 'label': 'Device / Hardware Manufacturer' };
    const body = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      country: currentCountry,
      job: currentJob,
      companyName: this.state.companyName,
      companyActivity: currentCompanyActivity,
      companyWebSite: this.state.companyWebSite,
    };
    try {
      UserService.register(body)
        .then((res) => {
          if (res.status === 204) {
            document.dispatchEvent(
              new CustomEvent('addNotification', {
                detail: {
                  type: 'success',
                  content: `Thanks for your registration!`,
                },
              })
            );
            this.props.history.push('/login');
          } else {
            if (res.status >= 400) {
              this.setState({ isError: true, errorMessage: res.data.message });
            }
          }
        })
        .catch((e) => {
          this.setState({ isError: true, errorMessage: e.message });
        }).finally(()=>{this.setState({ submitDisabled:false });});
    } catch (e) {
      let message = 'Error';
      if (e.response !== undefined) {
        if (e.response.status === 400) {
          message = 'Field missing or in wrong format';
        }
        if (e.response.status === 404) {
          message = 'Resource not found';
        }
        if (e.response.status === 429) {
          message = 'Too many request. You have been blocked for 1 hour.';
        }
      }
      this.setState({ isError: true, errorMessage: message, submitDisabled:false });
    }
  }

  async componentDidMount() {
    try {
      let response = await Promise.all([CountryService.list('countryName'), UserService.jobsList()]);
      const userJobs = response[1].data.map((item) => {
        return new ForegroundOption(item.label, item.id);
      });

      this.setState({
        countries: response[0].data,
        userJobs: response[1].data,
        userJobsOptions: userJobs,
        isLoading: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  formatResultCountry = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.countryName}</span>
      </>
    );
  };

  onSelectCountry = (item) => {
    this.setState({ currentCountry: item });
    this.setState({ isErrorOnCountry: false });
  };

  onClearCountry = () => {
    this.setState({ currentCountry: null, isErrorOnCountry: true });
  };

  render() {
    const countries = this.state.countries;

    const countryFuseOptions = { keys: ['countryName'], distance: 0, minMatchCharLength: 2 };

    let currentJob = this.state.currentJob;

    let { firstName, lastName, email, companyWebSite, companyName } = this.state;
    return (
      <>
        {this.state.isLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          <div>
            <div id='auth_register' className='border-bottom border-light pt-3'>
              <div className='container'>
                <h1 className='display-2'>Sign up </h1>
              </div>
            </div>

            <div className='container mt-3'>
              <div className='mb-3 mt-2'>All mandatory fields are marked with an *</div>
              <div className='row mt-2'>
                <h2>Devices and hardware manufacturers: please fill in the form below to register.</h2>
                <div className='col-md-4 offset-md-0'>
                  <div className='form-group mb-3'>
                    <label htmlFor='firstname' className='is-required'>
                      First name
                    </label>
                    <input
                      type='text'
                      className='form-control mt-2'
                      id='firstname'
                      name='firstname'
                      maxLength={1000}
                      defaultValue={firstName}
                      onChange={this.handleChangeFirstName}
                      required
                    />
                    {this.state.isErrorOnFirstName ?
                      <p className='errorMessage mt-2'>{MANDATORY_FIELD_MISSING}</p> : null}
                  </div>

                  <div className='form-group mb-3'>
                    <label htmlFor='lastname' className='is-required'>
                      Last name
                    </label>
                    <input
                      type='text'
                      className='form-control  mt-2'
                      id='lastname'
                      name='lastname'
                      maxLength={1000}
                      defaultValue={lastName}
                      onChange={this.handleChangeLastName}
                      required
                    />
                    {this.state.isErrorOnLastName ?
                      <p className='errorMessage mt-2'>{MANDATORY_FIELD_MISSING}</p> : null}
                  </div>

                  <div className='form-group mb-3'>
                    <label htmlFor='email' className='is-required'>
                      Email
                    </label>
                    <input
                      type='email'
                      className='form-control  mt-2'
                      id='email'
                      name='email'
                      maxLength={1000}
                      defaultValue={email}
                      onChange={this.handleChangeEmail}
                      required
                    />
                    {this.state.isErrorOnEmail ? <p className='errorMessage mt-2'>{BAD_EMAIL_FORMAT}</p> : null}
                  </div>

                  <div className='form-group mb-3'>
                    <label htmlFor='country' className='is-required mb-2'>
                      Country
                    </label>

                    <ReactSearchAutocomplete
                      items={countries}
                      autoFocus
                      formatResult={this.formatResultCountry}
                      onSelect={this.onSelectCountry}
                      styling={{ ...AUTOCOMPLETE_STYLE, ...ZINDEX_HIGHEST }}
                      placeholder={PLEASE_SELECT_COUNTRY}
                      showNoResults={true}
                      showNoResultsText='No Result'
                      fuseOptions={countryFuseOptions}
                      resultStringKeyName='countryName'
                      onClear={this.onClearCountry}
                    />
                    {this.state.isErrorOnCountry ? <p className='errorMessage mt-2'>{PLEASE_SELECT_COUNTRY}</p> : null}
                  </div>

                  <div className='form-group mb-3'>
                    <label htmlFor='job' className='is-required'>
                      Job position
                    </label>
                    <ForegroundSelectSingle
                      inputId='job'
                      isClearable={false}
                      placeholder={PLEASE_SELECT_JOB_POSITION}
                      value={currentJob}
                      options={this.state.userJobsOptions}
                      onChange={(e) => this.handleChangeUserJob(e)}
                    />

                    {this.state.isErrorOnJob ? <p className='errorMessage mt-2'>{PLEASE_SELECT_JOB_POSITION}</p> : null}
                  </div>

                  <div className='form-group mb-3'>
                    <label htmlFor='companyname' className='is-required'>
                      Company name
                    </label>
                    <input
                      type='text'
                      className='form-control mt-2'
                      id='companyname'
                      name='companyname'
                      maxLength={1000}
                      defaultValue={companyName}
                      onChange={this.handleChangeCompanyName}
                      required
                    />
                    {this.state.isErrorOnCompanyName ?
                      <p className='errorMessage mt-2'>{MANDATORY_FIELD_MISSING}</p> : null}
                  </div>

                  <div className='form-group mb-3'>
                    <label htmlFor='company_website' className='is-required'>
                      Company website
                    </label>
                    <input
                      type='text'
                      className='form-control mt-2'
                      id='company_website'
                      name='company_website'
                      maxLength={191}
                      defaultValue={companyWebSite}
                      onChange={this.handleChangeCompanyWebSite}
                      required
                    />
                    {this.state.isErrorOnCompanyWebSite ? <p className='errorMessage mt-2'>{BAD_WEB_URL}</p> : null}
                  </div>

                  <div className='form-group mb-3'>
                    <p className='custom-control-label'>
                      <input
                        type='checkbox'
                        aria-label='checkbox_agreement'
                        className='custom-control-input'
                        id='checkbox_agreement'
                        name='checkbox_agreement'
                        autoComplete='off'
                        required
                        onChange={(e) => this.handleChangeChecked(e)}
                        defaultChecked={this.state.checked}
                      />
                      &nbsp;I have read and agree to
                      <span>
                          <a href={`/terms-conditions`}> the terms and conditions of use </a>
                          of
                        </span>
                      &nbsp;foreground.orange.com
                    </p>
                    {this.state.isErrorOnChecked ?
                      <p className='errorMessage mt-2'>You must accept the Foreground's terms and conditions of use to
                        register.</p> : null}
                  </div>

                  <div className='form-group my-3'>
                    <button type='submit' className='btn btn-primary' onClick={this.handleSubmit} disabled={this.state.submitDisabled}>
                      Sign up
                    </button>
                    <br></br>
                  </div>
                </div>

                {this.state.isError ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
              </div>
            </div>
            <div className='container'>
              <hr></hr>
            </div>

            <div className='container'>
              <div className='row'>
                <div className='col-md-4 offset-md-0'>
                  <p>
                    <strong>
                      Already have an account? <a href={`/login`}>Log in</a>
                    </strong>
                  </p>
                  <p>
                    <strong>
                      You did not find what you looked for? <a href={`mailto:${process.env.REACT_APP_MAIL_TO}`}> Contact
                      us</a>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default createBrowserHistory();
