import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { DATE_PICKER_FORMAT } from '../../models/constants';
import CompanyService from '../../services/company.service';

import './company-management.css';
import 'react-datepicker/dist/react-datepicker.css';
import Utils from '../../helpers/Utils';
import { ForegroundOption, ForegroundSelectSingle } from '../../components/ForegroundSelect';
import { CompanyStatus, COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT } from '../../models/company';
import { CompanyWizardStepProps } from './CompanyWizard';

class CompanyStatusStepState {
  public errorMessage: string;
  public companyStatusList: CompanyStatus[];
  companyStatusListOptions: ForegroundOption[];
  currentCompanyStatus: ForegroundOption;
  nda: boolean;
  reseller: boolean;
  inOrangeOffer: boolean;
  withOrangeConnectivity: boolean;
  corpSourContSignatureDate: string;
  corpSourContExpirationDate: string;
  ndaSignatureDate: string;
  ndaExpirationDate: string;
  isErrorOnCorpSourContSignatureDate: boolean;
  isErrorOnCorpSourContExpirationDate: boolean;
  isErrorOnNdaSignatureDate: boolean;
  isErrorOnNdaExpirationDate: boolean;

  constructor() {
    this.errorMessage = '';
    this.companyStatusList = [];
    this.companyStatusListOptions = [];
    this.currentCompanyStatus = null;
    this.nda = false;
    this.reseller = false;
    this.inOrangeOffer = false;
    this.withOrangeConnectivity = false;
    this.corpSourContSignatureDate = '';
    this.corpSourContExpirationDate = '';
    this.ndaSignatureDate = '';
    this.ndaExpirationDate = '';
    this.isErrorOnCorpSourContExpirationDate = false;
    this.isErrorOnCorpSourContSignatureDate = false;
    this.isErrorOnNdaExpirationDate = false;
    this.isErrorOnNdaSignatureDate = false;
  }
}

export class CompanyStatusStep extends Component<CompanyWizardStepProps, CompanyStatusStepState> {
  constructor(props) {
    super(props);
    this.state = new CompanyStatusStepState();
    this.handleChangeCompanyStatus = this.handleChangeCompanyStatus.bind(this);
  }

  async componentDidMount() {
    try {
      const response = await CompanyService.statusList();
      const status = !!this.props.company.status ? response.data.find((a) => a.id === this.props.company.status.id) : null;
      const companyStatusListOptions = response.data.map((item) => {
        return new ForegroundOption(item.label, item.id);
      });
      this.setState({
        companyStatusList: response.data,
        companyStatusListOptions: companyStatusListOptions,
        currentCompanyStatus: !!status ? new ForegroundOption(status.label, status.id) : null,
        nda: !!this.props.company.nda,
      });
    } catch (e) {
      console.error(e);
    }
  }

  handleChangeCompanyStatus(event: ForegroundOption) {
    const status = this.state.companyStatusList.find((a) => a.id === event.value);
    this.props.company.status = status;
    if (status.id === COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT && !!!this.props.company.corporateSourcingContract) {
      this.props.company.corporateSourcingContract = {
        signature: Utils.formatDateToAPI(new Date()),
        expiration: Utils.formatDateToAPI(new Date()),
        commentary: '',
        contractNumber: '',
        buyer: '',
      };
    }
    this.setState({ currentCompanyStatus: event });
  }

  handleChangeCorpSourContSignatureDate(date: string) {
    this.props.company.corporateSourcingContract.signature = Utils.formatDateToAPI(date);
    if (!!date) {
      this.setState({ corpSourContSignatureDate: date, isErrorOnCorpSourContSignatureDate: false });
    } else {
      this.setState({
        corpSourContSignatureDate: date,
        isErrorOnCorpSourContSignatureDate: true,
        errorMessage: 'Please enter the signature date of corporate sourcing contract',
      });
    }
  }

  handleChangeCorpSourContExpirationDate(date: string) {
    this.props.company.corporateSourcingContract.expiration = Utils.formatDateToAPI(date);
    if (!!date) {
      this.setState({ corpSourContExpirationDate: date, isErrorOnCorpSourContExpirationDate: false });
    } else {
      this.setState({
        corpSourContExpirationDate: date,
        isErrorOnCorpSourContExpirationDate: true,
        errorMessage: 'Please enter the expiration date of corporate sourcing contract',
      });
    }
  }

  handleChangeNdaSignatureDate(date: string) {
    this.props.company.nda.signature = Utils.formatDateToAPI(date);
    if (!!date) {
      this.setState({ ndaSignatureDate: date, isErrorOnNdaSignatureDate: false });
    } else {
      this.setState({
        ndaSignatureDate: date,
        isErrorOnNdaSignatureDate: true,
        errorMessage: 'Please enter the signature date of NDA',
      });
    }
  }

  handleChangeNdaExpirationDate(date: string) {
    this.props.company.nda.expiration = Utils.formatDateToAPI(date);
    if (!!date) {
      this.setState({ ndaExpirationDate: date, isErrorOnNdaExpirationDate: false });
    } else {
      this.setState({
        ndaExpirationDate: date,
        isErrorOnNdaExpirationDate: true,
        errorMessage: 'Please enter the expiration date of NDA',
      });
    }
  }

  handleClickNda(bool: boolean) {
    if (bool && !!!this.props.company.nda) {
      this.props.company.nda = {
        signature: Utils.formatDateToAPI(new Date()),
        expiration: Utils.formatDateToAPI(new Date()),
        comment: '',
      };
    }
    if (!bool) {
      this.props.company.nda = null;
    }

    this.setState({ nda: bool });
  }

  handleClickReseller(bool: boolean) {
    this.setState({ reseller: bool });
    this.props.company.reseller = bool;
  }

  handleClickInOrangeOffer(bool: boolean) {
    this.setState({ inOrangeOffer: bool });
    this.props.company.inOrangeOffer = bool;
  }

  handleClickWithOrangeConnectivity(bool: boolean) {
    this.setState({ withOrangeConnectivity: bool });
    this.props.company.withOrangeConnectivity = bool;
  }

  validate() {
    if (this.props.isReadOnly) {
      return true;
    }
    if (!!this.props.company.nda) {
      if (!!!this.props.company.nda.signature) {
        this.setState({
          isErrorOnNdaSignatureDate: true,
          errorMessage: 'Please enter the signature date of NDA',
        });
        return false;
      }
      if (!!!this.props.company.nda.expiration) {
        this.setState({
          isErrorOnNdaExpirationDate: true,
          errorMessage: 'Please enter the expiration date of NDA',
        });
        return false;
      }
    }
    if (this.props.company.status.id === COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT) {
      if (!!!this.props.company.corporateSourcingContract.signature) {
        this.setState({
          isErrorOnCorpSourContSignatureDate: true,
          errorMessage: 'Please enter the signature date of corporate sourcing contract',
        });
        return false;
      }
      if (!!!this.props.company.corporateSourcingContract.expiration) {
        this.setState({
          isErrorOnCorpSourContExpirationDate: true,
          errorMessage: 'Please enter the expiration date of corporate sourcing contract',
        });
        return false;
      }
    }
    return true;
  }

  render() {
    const currentCompanyStatus = this.state.currentCompanyStatus;
    if (this.props.shouldDisplay) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-12 mt-1'>
              <div className='mb-3'>All mandatory fields are marked with an *</div>
              <label htmlFor='status' className='is-required'>
                Status
              </label>
              <ForegroundSelectSingle
                inputId='status'
                placeholder='Please select a company status'
                value={currentCompanyStatus}
                options={this.state.companyStatusListOptions}
                onChange={(e) => this.handleChangeCompanyStatus(e)}
                isClearable={false}
                isDisabled={this.props.isReadOnly}
                closeOnSelect={true}
              />
            </div>
          </div>

          {/* Corporate sourcing  contract */}
          <div className="mt-2 col-md-12">
            <div className="form-row border border-solidp-2 px-2">
              <div className="form-group m-2 w-80">
                <label className="form-check-label div-check" htmlFor="flexSwitchCheckCSC">
                  Corporate Sourcing Contract
                </label>
              </div>
              <div className="d-inline-flex">
                <div className="form-row m-2 col-md-6">
                  <label
                    id="labelsignatureCsc"
                    className={`${this.props.company.status.id === COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT ? 'is-required' : ''}`}
                    htmlFor="datepickerCorpSourContSignature"
                  >
                    Date of signature
                  </label>
                  <DatePicker
                    className="form-control mt-2 datePicker"
                    id="datepickerCorpSourContSignature"
                    name="datepickerCorpSourContSignature"
                    dateFormat={DATE_PICKER_FORMAT}
                    selected={
                      !!this.props.company.corporateSourcingContract ? new Date(this.props.company.corporateSourcingContract.signature) : null
                    }
                    onChange={(date) => this.handleChangeCorpSourContSignatureDate(date)}
                    disabled={this.props.isReadOnly || this.props.company.status.id !== COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT}
                    readOnly={this.props.isReadOnly || this.props.company.status.id !== COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT}
                    ariaRequired={`${this.props.company.status.id === COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT ? 'true' : 'false'}`}
                    ariaDescribedBy={`${this.state.isErrorOnCorpSourContSignatureDate ? 'error-signature-csc' : ''}`}
                  />
                  {this.state.isErrorOnCorpSourContSignatureDate ? (
                    <p id="error-signature-csc" className="errorMessage mt-2">
                      {this.state.errorMessage}
                    </p>
                  ) : null}
                </div>
                <div className="form-row m-2 col-md-6">
                  <label
                    id="labelexpirationCsc"
                    className={`${this.props.company.status.id === COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT ? 'is-required' : ''}`}
                    htmlFor="datepickerexpiration"
                  >
                    Date of expiration
                  </label>
                  <DatePicker
                    className="form-control mt-2 datePicker"
                    id="datepickerexpiration"
                    name="datepickerexpiration"
                    dateFormat={DATE_PICKER_FORMAT}
                    selected={
                      !!this.props.company.corporateSourcingContract ? new Date(this.props.company.corporateSourcingContract.expiration) : null
                    }
                    onChange={(date) => this.handleChangeCorpSourContExpirationDate(date)}
                    disabled={this.props.isReadOnly || this.props.company.status.id !== COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT}
                    readOnly={this.props.isReadOnly || this.props.company.status.id !== COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT}
                    ariaRequired={`${this.props.company.status.id === COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT ? 'true' : 'false'}`}
                    ariaDescribedBy={`${this.state.isErrorOnCorpSourContExpirationDate ? 'error-expiration-csc' : ''}`}
                  />
                  {this.state.isErrorOnCorpSourContExpirationDate ? (
                    <p id="error-expiration-csc" className="errorMessage mt-2">
                      {this.state.errorMessage}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="form-group m-2 col-md-6">
                <label htmlFor="companyCorporateSourcingContractNumber">Contract number</label>
                <input
                  className="form-control w-100"
                  id="companyCorporateSourcingContractNumber"
                  name="companyCorporateSourcingContractNumber"
                  defaultValue={!!this.props.company.corporateSourcingContract ? this.props.company.corporateSourcingContract.contractNumber : ''}
                  onChange={(e) => {
                    this.props.company.corporateSourcingContract.contractNumber = e.target.value;
                  }}
                  disabled={this.props.isReadOnly || this.props.company.status.id !== COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT}
                  readOnly={this.props.isReadOnly || this.props.company.status.id !== COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT}
                ></input>
              </div>
              <div className="form-group m-2 col-md-6">
                <label htmlFor="buyer">Buyer</label>
                <input
                  className="form-control w-100"
                  id="buyer"
                  name="buyer"
                  defaultValue={!!this.props.company.corporateSourcingContract ? this.props.company.corporateSourcingContract.buyer : ''}
                  onChange={(e) => {
                    this.props.company.corporateSourcingContract.buyer = e.target.value;
                  }}
                  disabled={this.props.isReadOnly || this.props.company.status.id !== COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT}
                  readOnly={this.props.isReadOnly || this.props.company.status.id !== COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT}
                ></input>
              </div>
              <div className="form-group m-2 w-80">
                <label htmlFor="companyCorporateSourcingContractComment">
                  Commentary <small className="text-muted">(not visible to partners)</small>
                </label>
                <textarea
                  className="form-control mt-1"
                  id="companyCorporateSourcingContractComment"
                  name="companyCorporateSourcingContractComment"
                  defaultValue={!!this.props.company.corporateSourcingContract ? this.props.company.corporateSourcingContract.commentary : ''}
                  onChange={(e) => {
                    this.props.company.corporateSourcingContract.commentary = e.target.value;
                  }}
                  disabled={this.props.isReadOnly || this.props.company.status.id !== COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT}
                  readOnly={this.props.isReadOnly || this.props.company.status.id !== COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT}
                ></textarea>
              </div>
            </div>
          </div>

          {/* NDA */}
          <div className="mt-2 col-md-12">
            <div className="form-row border border-solidp-2 px-2">
              <div className="form-check form-switch m-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckNda"
                  name="flexSwitchCheckNda"
                  checked={this.state.nda}
                  onChange={() => this.handleClickNda(!this.state.nda)}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckNda">
                  NDA
                </label>
              </div>
              <div className="d-inline-flex">
                <div className="form-row m-2 col-md-6">
                  <label id="labelsignatureNda" className={`${!!this.props.company.nda ? 'is-required' : ''}`} htmlFor="datepickersignature">
                    Date of signature
                  </label>
                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    className="form-control mt-1 datePicker"
                    id="datepickersignature"
                    name="datepickersignature"
                    dateFormat={DATE_PICKER_FORMAT}
                    selected={
                      !!this.props.company.nda ? (!!this.props.company.nda.signature ? new Date(this.props.company.nda.signature) : null) : ''
                    }
                    onChange={(date) => this.handleChangeNdaSignatureDate(date)}
                    disabled={this.props.isReadOnly || !this.state.nda}
                    readOnly={this.props.isReadOnly || !this.state.nda}
                    ariaRequired={`${!!this.props.company.nda ? 'true' : 'false'}`}
                    ariaDescribedBy={`${this.state.isErrorOnNdaSignatureDate ? 'error-signature-nda' : ''}`}
                  />
                  {this.state.isErrorOnNdaSignatureDate ? (
                    <p id="error-signature-nda" className="errorMessage mt-2">
                      {this.state.errorMessage}
                    </p>
                  ) : null}
                </div>
                <div className="form-row m-2 col-md-6">
                  <label id="labelexpirationNda" className={`${!!this.props.company.nda ? 'is-required' : ''}`} htmlFor="datepickerexpirationNda">
                    Date of expiration
                  </label>
                  <DatePicker
                    className="form-control mt-1 datePicker"
                    id="datepickerexpirationNda"
                    name="datepickerexpirationNda"
                    dateFormat={DATE_PICKER_FORMAT}
                    selected={
                      !!this.props.company.nda ? (!!this.props.company.nda.expiration ? new Date(this.props.company.nda.expiration) : null) : ''
                    }
                    onChange={(date) => this.handleChangeNdaExpirationDate(date)}
                    disabled={this.props.isReadOnly || !this.state.nda}
                    readOnly={this.props.isReadOnly || !this.state.nda}
                    ariaRequired={`${!!this.props.company.nda ? 'true' : 'false'}`}
                    ariaDescribedBy={`${this.state.isErrorOnNdaExpirationDate ? 'error-expiration-nda' : ''}`}
                  />
                  {this.state.isErrorOnNdaExpirationDate ? (
                    <p id="error-expiration-nda" className="errorMessage mt-2">
                      {this.state.errorMessage}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="form-group m-2 w-80">
                <label htmlFor="commentNda">Commentary</label>

                <textarea
                  className="form-control mt-1"
                  id="commentNda"
                  name="commentNda"
                  defaultValue={!!this.props.company.nda ? this.props.company.nda.comment : ''}
                  onChange={(e) => {
                    this.props.company.nda.comment = e.target.value;
                  }}
                  disabled={this.props.isReadOnly || !this.state.nda}
                  readOnly={this.props.isReadOnly || !this.state.nda}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="d-inline-flex">
            {/* Orange Reseller Agreement */}
            <div className="form-check form-switch mt-2 div-check">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckReseller"
                name="flexSwitchCheckReseller"
                checked={this.props.company.reseller}
                onChange={() => this.handleClickReseller(!this.props.company.reseller)}
                disabled={this.props.isReadOnly}
                readOnly={this.props.isReadOnly}
              />
              <label className="form-check-label" htmlFor="flexSwitchCheckReseller">
                Orange Reseller Agreement
              </label>
            </div>

            {/* Integrated in Orange Solutions */}
            <div className="form-check form-switch m-2 div-check">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckInOrangeOffer"
                name="flexSwitchCheckInOrangeOffer"
                checked={this.props.company.inOrangeOffer}
                onChange={() => this.handleClickInOrangeOffer(!this.props.company.inOrangeOffer)}
                disabled={this.props.isReadOnly}
                readOnly={this.props.isReadOnly}
              />
              <label className="form-check-label" htmlFor="flexSwitchCheckInOrangeOffer">
                Integrated in Orange Solutions
              </label>
            </div>

            {/* Reseller of Orange connectivity */}
            <div className="form-check form-switch m-2 div-check">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckWithOrangeConnectivity"
                name="flexSwitchCheckWithOrangeConnectivity"
                checked={this.props.company.withOrangeConnectivity}
                onChange={() => this.handleClickWithOrangeConnectivity(!this.props.company.withOrangeConnectivity)}
                disabled={this.props.isReadOnly}
                readOnly={this.props.isReadOnly}
              />
              <label className="form-check-label" htmlFor="flexSwitchCheckWithOrangeConnectivity">
                Reseller of Orange connectivity
              </label>
            </div>
          </div>

          {/* referent */}
          <div className="mt-4">
            <label className="YesNo" htmlFor="companyReferent">
              Name of the contact person in Orange OBS
            </label>
            <input
              className="form-control mt-2"
              type="text"
              id="companyReferent"
              name="companyReferent"
              defaultValue={this.props.company.referent}
              onChange={(e) => {
                this.props.company.referent = e.target.value;
              }}
              required
              readOnly={this.props.isReadOnly}
              disabled={this.props.isReadOnly}
            />
          </div>

          {/* comments */}
          <div className="form-group col-md-14 mt-4">
            <label htmlFor="companyComments">Comments</label>
            <textarea
              className="form-control mt-2"
              id="companyComments"
              name="companyComments"
              defaultValue={this.props.company.comments}
              onChange={(e) => {
                this.props.company.comments = e.target.value;
              }}
              readOnly={this.props.isReadOnly}
              disabled={this.props.isReadOnly}
            ></textarea>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
