import React, { Component } from 'react';

export class PartnersTestimonials extends Component {
  render() {
    return (
      <div className="partnerstestimonials pt-5">
        <div className="container">
          <h2 className="mb-5">IoT partners testimonials</h2>
          <div className="row">
            <blockquote className="col-md-6 px-5 mb-5 blockquote">
              <div className="mb-4 text-start">
                <img src={process.env.PUBLIC_URL + '/assets/img/ercogener-logo.svg'} alt="Ercogener logo" width="179" height="39" />
              </div>
              <p className="fs-4">
                `Ercogener was fortunate to be Orange's technology partner for testing and deploying the LTE-Cat M1 network. This confidence of the
                Orange teams allows us to be the first to introduce a French solution industrialized and certified on this technology.`
                <br />
                <br />
                <strong>Ludovic de Nicolays</strong>
                <br />
                <strong className="text-muted">Chief Executive Officer - ercogener</strong>
              </p>
            </blockquote>
            <blockquote className="col-md-6 px-5 mb-5 blockquote">
              <div className="mb-4 text-start">
                <img src={process.env.PUBLIC_URL + '/assets/img/quuppa-logo.svg'} alt="Quuppa logo" width="179" height="39" />
              </div>
              <p className="fs-4">
                `Orange is like many of our other partners, but bigger. They’re using our technology and building their own software on our APIs
                (application program interfaces) for their live events, logistics and manufacturing and also for healthcare.`
                <br />
                <br />
                <strong>Thomas Hasselman</strong>
                <br />
                <strong className="text-muted">Chief Marketing Officer - Quuppa</strong>
              </p>
            </blockquote>
            <blockquote className="col-md-6 px-5 mb-5 blockquote">
              <div className="mb-4 text-start">
                <img src={process.env.PUBLIC_URL + '/assets/img/LogoJRi_hd.svg'} alt="Jri logo" width="179" height="39" />
              </div>
              <p className="fs-4">
                `Thanks to Orange's LoRaWAN network, these are new opportunities that open up to us to offer connected, automated solutions that can
                monitor the cold chain with the precision, reliability and security that is essential for all professionals subject to Quality
                requirements.`
                <br />
                <br />
                <strong>Eric Cartalas</strong> <br />
                <strong className="text-muted">General Manager - Jri</strong>
              </p>
            </blockquote>
            <blockquote className="col-md-6 px-5 mb-5 blockquote">
              <div className="mb-4 text-start">
                <img src={process.env.PUBLIC_URL + '/assets/img/adeunis_logo_horizontal.svg'} alt="Adeunis logo" width="179" height="39" />
              </div>
              <p className="fs-4">
                `The Orange and adeunis partnership has been built over the past three years around two key values: trust and expertise. The addition
                of the quality of the Orange network and the linked products creates real added value for our customers.`
                <br />
                <br />
                <strong>Franck Fischer</strong>
                <br />
                <strong className="text-muted">Deputy Chief Executive Officer - adeunis</strong>
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    );
  }
}
