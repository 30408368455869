import React, { Component } from 'react';
import { PropsMatch } from '../../models/Props';
import { PageHeader } from '../PageHeader';
import { PasswordInput } from '../PasswordInput';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import {
  BAD_EMAIL_FORMAT,
  EMAIL_REGEX,
  NOT_IDENTICAL_PASSWORDS_ERROR,
  PASSWORD_FORMAT,
  PASSWORD_LENGTH,
  PASSWORD_REGEX,
} from '../../models/constants';
import './UserManagement.css';
import { passwordByToken } from '../../models/user';

import UserService from '../../services/user.service';

class SetPasswordByTokenProps {
  public match: PropsMatch;
}

class SetPasswordByTokenState {
  public isError: boolean;
  public isErrorOnEmail: boolean;
  public isErrorOnMyNewPassword: boolean;
  public isErrorOnMyConfirmPassword: boolean;
  public messageError: string;
  public email: string;
  public myNewPassword: string;
  public myConfirmPassword: string;

  constructor() {
    this.isError = false;
    this.isErrorOnEmail = false;
    this.isErrorOnMyNewPassword = false;
    this.isErrorOnMyConfirmPassword = false;
    this.messageError = '';
  }
}

export class SetPasswordByToken extends Component<SetPasswordByTokenProps, SetPasswordByTokenState> {
  constructor(props) {
    super(props);
    this.state = new SetPasswordByTokenState();
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleMyNewPasswordChange = this.handleMyNewPasswordChange.bind(this);
  }
  handleConfirmPasswordChange(e) {
    this.setState({ myConfirmPassword: e.target.value });
  }

  handleChangeUserName(e) {
    this.setState({ email: e.target.value });
  }
  handleMyNewPasswordChange(e) {
    this.setState({ myNewPassword: e.target.value });
  }

  validate() {
    if (!EMAIL_REGEX.test(this.state.email)) {
      this.setState({ isErrorOnEmail: true, messageError: BAD_EMAIL_FORMAT });
      return false;
    } else {
      this.setState({ isErrorOnEmail: false, messageError: '' });
    }

    if (!PASSWORD_REGEX.test(this.state.myNewPassword) || this.state.myNewPassword.length < PASSWORD_LENGTH) {
      this.setState({ isErrorOnMyNewPassword: true, messageError: PASSWORD_FORMAT });
      return false;
    } else {
      this.setState({ isErrorOnMyNewPassword: false, messageError: '' });
    }

    if (!PASSWORD_REGEX.test(this.state.myConfirmPassword) || this.state.myConfirmPassword.length < PASSWORD_LENGTH) {
      this.setState({ isErrorOnMyConfirmPassword: true, messageError: PASSWORD_FORMAT });
      return false;
    } else {
      this.setState({ isErrorOnMyConfirmPassword: false, messageError: '' });
    }

    if (this.state.myNewPassword !== this.state.myConfirmPassword) {
      this.setState({ isError: true, isErrorOnMyNewPassword: false, isErrorOnMyConfirmPassword: false, messageError: NOT_IDENTICAL_PASSWORDS_ERROR });
      return false;
    } else {
      this.setState({ isError: false, isErrorOnMyNewPassword: false, isErrorOnMyConfirmPassword: false, messageError: '' });
    }

    return true;
  }

  async onSubmit(e) {
    e.preventDefault();
    if (!this.validate()) {
      console.log(`Error validate in User Password`);
      return;
    }

    const tokenFromUrl = this.props.match.params.token;
    const body = new passwordByToken(this.state.email, this.state.myNewPassword, tokenFromUrl);

    try {
      const res = await UserService.passwordByToken(body);
      if (res.status === 204) {
        document.dispatchEvent(
          new CustomEvent('addNotification', {
            detail: {
              type: 'success',
              content: `Your password has been successfully resetted.`,
            },
          })
        );
      } else {
        if (res.status >= 400) {
          this.setState({ isError: true, messageError: res.data.message });
        }
      }
    } catch (e) {
      let message = 'Error';
      this.setState({ isError: true, messageError: message });
    }
  }

  render() {
    document.title = 'Reset Password - Foreground V3';
    return (
      <div className="p-3 bg-white">
        <PageHeader title="Reset Password"></PageHeader>
        <div className="container mt-3">
          <form role="form" className="col-md-4" onSubmit={(e) => this.onSubmit(e)}>
            <div className="d-flex flex-column ">
              <div className='mb-1'>All mandatory fields are marked with an *</div>
              <div id="passwordSection" className="form-group mt-3 div-width">
                <label htmlFor="username" className="is-required mb-2">
                  Email
                </label>

                <input
                  type="email"
                  className="form-control mt-1"
                  id="username"
                  name="email"
                  placeholder="email@example.com"
                  onChange={(e) => this.handleChangeUserName(e)}
                ></input>

                {this.state.isErrorOnEmail ? <p className="errorMessage mt-2">{this.state.messageError}</p> : ''}
              </div>

              <div id="newPasswordSection" className="form-group mt-3 ">
                <label htmlFor="myNewPassword" className="is-required mb-2">
                  New password
                </label>
                <div className="d-flex flex-row div-width-big">
                  <div className="div-width">
                    <PasswordInput
                      inputId="myNewPassword"
                      placeholder="New Password"
                      handleChangePassword={(e) => this.handleMyNewPasswordChange(e)}
                    ></PasswordInput>
                  </div>
                  <span className="input-group-append mt-2">
                    <a data-tooltip-id="my-tooltip" data-tooltip-content={PASSWORD_FORMAT} data-tooltip-place="right">
                      <span className="icon foreground-ic_Assistance ms-2 pt-2 icon-40" aria-hidden="true"></span>
                    </a>
                    <Tooltip id="my-tooltip" className="tooltip-content" />
                  </span>
                </div>
                {this.state.isErrorOnMyNewPassword ?
                  <p className="errorMessage mt-2">{this.state.messageError}</p> : ''}
              </div>

              <div id="confirmPasswordSection" className="form-group mt-3 div-width">
                <label htmlFor="myConfirmPassword" className="is-required mb-2">
                  Confirm new password
                </label>

                <PasswordInput
                  inputId="myConfirmPassword"
                  placeholder="Confirm New Password"
                  handleChangePassword={(e) => this.handleConfirmPasswordChange(e)}
                ></PasswordInput>

                {this.state.isErrorOnMyConfirmPassword ?
                  <p className="errorMessage mt-2">{this.state.messageError}</p> : ''}
              </div>

              {this.state.isError ? <p className="errorMessage">{this.state.messageError}</p> : ''}

              <div id="loginButtonSection" className="mt-3">
                <button id="authButton" type="submit" className="btn btn-primary">
                  Reset password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
