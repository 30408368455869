import './product_management.css';
import ProductLabelLastest from './productLabelLatest';
import { Component } from 'react';
import { ReducedDevice } from '../../models/device';

export class ProductCardProps {
  public product: ReducedDevice;
  public history: any;
  public showCompany: boolean;
}

export default class ProductCard extends Component<ProductCardProps> {
  render() {
    let { product } = this.props;

    const productLink = product.type.id === 6 ? `/solutions/${product.slug}` : `/products/${product.slug}`;

    const productPrice = product.pricing > 0 ? `${product.pricing} ${product.pricingUnit}` : '';
    // const baseUrl = window.location.hostname === 'localhost' || '127.0.0.1' ? 'https://foreground-v3-pre-prod.apps.fr01.paas.tech.orange' : '';
    const baseUrl = process.env.REACT_APP_BASE_URL_API;
    const image = product.images.find((i) => i.isReference);
    return (
      <div className="product-card" tabIndex={0}
      onKeyDown={(e) => {
        if (e.code === "Enter" ){
          this.props.history.push(productLink);
        }
      }}
      >
        <div
          className="product-divimg position-relative"
          onClick={() => {
            this.props.history.push(productLink);
          }}
        >
          {!!image && !!image.link ? (
            <img
              className="container product-img"
              src={`${baseUrl}/public/storage/img/${image.link}`}
              alt={`${product.name}`}
              onError={(e) => {
                (e.target as HTMLImageElement).onerror = null;
                (e.target as HTMLImageElement).remove();
              }}
            />
          ) : (
            <div className="product-img"></div>
          )}
          {!!product.versions ? ProductLabelLastest(product) : ''}
        </div>

        <div className="product-div-desc">
          <div
            className="product-div-name"
            onClick={() => {
              this.props.history.push(productLink);
            }}
          >
            <span id={`device-name-${product.id}`} className="product-name">
              {product.name}
            </span>
          </div>

          {this.props.showCompany ? (
            <div
              className="product-div-company"
              onClick={() => {
                this.props.history.push(`/companies/${product.company.slug}`);
              }}
            >
              <span id={`company-name-${product.id}`} className="product-company">
                {product.company.name}
              </span>
            </div>
          ) : (
            ''
          )}
          <div className="product-div-price">
            <span id={`product-price-${product.id}`} className="product-price text-primary-orange" aria-hidden={!productPrice ? 'true' : 'false'}>
              {productPrice}
            </span>
          </div>
          <div className="product-div-features">
            <p id={`product-features-${product.id}`} className="product-features" dangerouslySetInnerHTML={{ __html: product.features }}></p>
          </div>
          <div className="product-div-type">
            <span id={`product-type-${product.id}`} className="product-type">
              {product.type.label}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
