import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  ORANGE_ADMIN_SCOPE,
  ORANGE_USER_SCOPE,
  ORANGE_VALIDATION_SCOPE,
  PARTNER_SCOPE,
  ORANGE_PARTNER_SCOPE,
  SESSION_STORAGE_PASSWORD,
} from '../models/constants';
import { CompanyCreateModify } from '../pages/company-management/CompanyCreateModify';
import { CompanyInformations } from '../pages/company-management/CompanyInformations';
import { DeviceCreateModify } from '../pages/product_management/DeviceCreateModify';
import { DeviceInformations } from '../pages/product_management/DeviceInformations';

const GuardedRoute = ({ component: Component, appProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const newProps = { appProps: appProps, ...props };
      const path = props.match.path;

      if (sessionStorage.getItem(SESSION_STORAGE_PASSWORD)) {
        if (path !== '/changePassword') {
          return <Redirect to="/changePassword" />;
        }
      } else if (!!appProps.token) {
        if (!appProps.cgu && path !== '/cgu') {
          return <Redirect to="/cgu" />;
        }
      }

      const url = props.match.url;
      const scope = appProps.scope;

      if (path === '/partner-users' || path === '/partner-users/add' || path === '/plateform-users' || path === '/plateform-users/add') {
        if (scope === ORANGE_ADMIN_SCOPE) {
          return <Component {...newProps} />;
        } else {
          return <Redirect to="/" />;
        }
      }
      if (path === '/companies' || path === '/companies/add' || path === '/companies/:id' || path === '/companies/:id/edit') {
        if (scope === ORANGE_ADMIN_SCOPE || scope === ORANGE_USER_SCOPE || scope === ORANGE_VALIDATION_SCOPE) {
          if (url === '/companies/add' && path === '/companies/:id') {
            return null;
          }
          if (path === '/companies/add' || path === '/companies/:id/edit') {
            return <CompanyCreateModify {...newProps} />;
          } else {
            if (path === '/companies/:id') {
              return <CompanyInformations {...newProps} />;
            } else {
              return <Component {...newProps} />;
            }
          }
        } else {
          if (path === '/companies/:id/edit') {
            return <CompanyCreateModify {...newProps} />;
          } else {
            return <Redirect to="/" />;
          }
        }
      }
      if (
        path === '/products' ||
        path === '/products/add' ||
        path === '/products/:id' ||
        path === '/products/:id/edit' ||
        path === '/solutions/:id'
      ) {
        if (
          scope === ORANGE_ADMIN_SCOPE ||
          scope === ORANGE_USER_SCOPE ||
          scope === ORANGE_VALIDATION_SCOPE ||
          scope === PARTNER_SCOPE ||
          scope === ORANGE_PARTNER_SCOPE
        ) {
          if (url === '/products/add' && path === '/products/:id') {
            return null;
          } else {
            if (path === '/products/add' || path === '/products/:id/edit') {
              return <DeviceCreateModify {...newProps} />;
            } else {
              if (path === '/products/:id' || path === '/solutions/:id') {
                return <DeviceInformations {...newProps} />;
              } else {
                return <Component {...newProps} />;
              }
            }
          }
        } else {
          return <Redirect to="/" />;
        }
      }
      return <Component {...newProps} />;
    }}
  />
);

export default GuardedRoute;
