import Utils from '../helpers/Utils';
import instance from '../helpers/InterceptorAxios';
import { cloneDeep } from 'lodash';
import {
  Company,
  CompanyFinanceAndSustainability,
  CompanyInformationI18n,
  CompanyQualityPolicyProduction,
  CompanyRdPolicy,
  CompanyReference,
  CorporateSourcingContract,
} from '../models/company';

export default class CompanyService {
  /**
   * List company activities
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async activitiesList() {
    return await instance.get(`api/v1/companiesActivities`, { headers: Utils.getAuthHeader() });
  }

  /**
   * List company status
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async statusList() {
    return await instance.get(`api/v1/companiesStatus`, { headers: Utils.getAuthHeader() });
  }

  /**
   * List company contact emails
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async usersEmailsByCompany(companySlug: string) {
    return await instance.get(`/api/v1/companies/${companySlug}/usersemails`, { headers: Utils.getAuthHeader() });
  }

  /**
   * List companies
   * @returns {Promise<AxiosResponse<T>>}
   */
  static async companiesList(
    offset: number,
    limit: number,
    orderBy: string,
    companiesStatus: string,
    collaboration: string,
    countries: string,
    companyName: string = null,
    isOrange: boolean = null
  ) {
    const params = {};
    if (offset != null) {
      params['offset'] = offset;
    }
    if (limit != null) {
      params['limit'] = limit;
    }
    if (orderBy != null) {
      params['orderBy'] = orderBy;
      params['sortOrder'] = orderBy === 'updatedAt' ? 'DESC' : 'ASC';
    }
    if (companiesStatus != null && companiesStatus !== '') {
      params['companyStatus'] = companiesStatus;
    }
    if (collaboration != null && collaboration !== '') {
      params['collaboration'] = collaboration;
    }
    if (countries != null && countries !== '') {
      params['countries'] = countries;
    }
    if (isOrange != null) {
      params['isOrange'] = isOrange;
    }
    if (companyName != null && companyName !== '') {
      params['name'] = companyName;
    }

    return await instance.get(`api/v1/companies`, { params: params, headers: Utils.getAuthHeader() });
  }

  static async companiesCount() {
    return await instance.get(`api/v1/companiesCount`, { headers: Utils.getAuthHeader() });
  }

  static async companiesDetails(companySlug: string) {
    return await instance.get(`api/v1/companies/${companySlug}`, { headers: Utils.getAuthHeader() });
  }

  static async companiesAllDetails(companySlug: string) {
    return Promise.all([
      instance.get(`api/v1/companies/${companySlug}`, { headers: Utils.getAuthHeader() }),
      instance.get(`api/v1/companies/${companySlug}/informationI18N`, { headers: Utils.getAuthHeader() }),
    ]);
  }

  static async createCompany(company: Company) {
    const companyToCreate = cloneDeep(company) as Company;
    delete companyToCreate.id;
    delete companyToCreate.status;
    delete companyToCreate.informationI18N;
    if (companyToCreate.logo.fileName == null || companyToCreate.logo.fileName.length === 0) {
      delete companyToCreate.logo;
    }

    return await instance.post('api/v1/companies', companyToCreate, { headers: Utils.getAuthHeader() });
  }

  static async updateCompany(companySlug: string, company: Company) {
    const companyToUpdate = cloneDeep(company);

    if (!!companyToUpdate.logo && companyToUpdate.logo.fileName == null) {
      delete companyToUpdate.logo;
    }
    if (!!companyToUpdate.documentsToAdd) {
      delete companyToUpdate.documentsToAdd;
    }
    if (!!companyToUpdate.documentsToUpdate) {
      delete companyToUpdate.documentsToUpdate;
    }
    if (!!companyToUpdate.documentsToDelete) {
      delete companyToUpdate.documentsToDelete;
    }

    return await instance.put(`api/v1/companies/${companySlug}`, companyToUpdate, { headers: Utils.getAuthHeader() });
  }

  static async createCompanyinformantionI18N(companySlug: string, countryCode: string, data: CompanyInformationI18n) {
    return await instance.post(`api/v1/companies/${companySlug}/informationI18N/countries/${countryCode}`, data, { headers: Utils.getAuthHeader() });
  }

  static async updateCompanyinformantionI18N(companySlug: string, countryCode: string, data: CompanyInformationI18n) {
    return await instance.put(`api/v1/companies/${companySlug}/informationI18N/countries/${countryCode}`, data, { headers: Utils.getAuthHeader() });
  }

  static async updateCompanyCorporateSourcingContract(companySlug: string, data: CorporateSourcingContract) {
    return await instance.put(`api/v1/companies/${companySlug}/corporateSourcingContract`, data, { headers: Utils.getAuthHeader() });
  }

  static async createCompanyCorporateSourcingContract(companySlug: string, data: CorporateSourcingContract) {
    return await instance.post(`api/v1/companies/${companySlug}/corporateSourcingContract`, data, { headers: Utils.getAuthHeader() });
  }

  static async updateCompanyRdPolicy(companySlug: string, data: CompanyRdPolicy) {
    return await instance.put(`api/v1/companies/${companySlug}/rdPolicy`, data, { headers: Utils.getAuthHeader() });
  }

  static async createCompanyRdPolicy(companySlug: string, data: CompanyRdPolicy) {
    return await instance.post(`api/v1/companies/${companySlug}/rdPolicy`, data, { headers: Utils.getAuthHeader() });
  }
  static async updateCompanyCustomerReferences(companySlug: string, data: CompanyReference) {
    return await instance.put(`api/v1/companies/${companySlug}/references`, data, { headers: Utils.getAuthHeader() });
  }

  static async createCompanyCustomerReferences(companySlug: string, data: CompanyReference) {
    return await instance.post(`api/v1/companies/${companySlug}/references`, data, { headers: Utils.getAuthHeader() });
  }
  static async updateCompanyFinanceAndSustainability(companySlug: string, data: CompanyFinanceAndSustainability) {
    return await instance.put(`api/v1/companies/${companySlug}/financeAndSustainability`, data, { headers: Utils.getAuthHeader() });
  }

  static async createCompanyFinanceAndSustainability(companySlug: string, data: CompanyFinanceAndSustainability) {
    return await instance.post(`api/v1/companies/${companySlug}/financeAndSustainability`, data, { headers: Utils.getAuthHeader() });
  }
  static async updateCompanyQualityPolicyProduction(companySlug: string, data: CompanyQualityPolicyProduction) {
    return await instance.put(`api/v1/companies/${companySlug}/qualityPolicyProduction`, data, { headers: Utils.getAuthHeader() });
  }

  static async createCompanyQualityPolicyProduction(companySlug: string, data: CompanyQualityPolicyProduction) {
    return await instance.post(`api/v1/companies/${companySlug}/qualityPolicyProduction`, data, { headers: Utils.getAuthHeader() });
  }

  static async deleteCompany(companySlug: string) {
    return await instance.delete(`/api/v1/companies/${companySlug}`, { headers: Utils.getAuthHeader() });
  }
}
