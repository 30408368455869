import React, { Component } from 'react';
import {
  MANDATORY_FIELD_MISSING,
  NUMERIC_FIELD_ERROR,
  NUMERIC_REGEX,
  PLEASE_SELECT_BATTERY_LONGEVITY,
  PLEASE_SELECT_USESCASES,
  PLEASE_SELECT_VERTICALS,
  SIGN_NUMERIC_FIELD_ERROR,
  SIGN_NUMERIC_REGEX,
} from '../../models/constants';
import './product_management.css';
import ProductService from '../../services/product.service';
import {
  ForegroundOption,
  ForegroundOptionGroup,
  ForegroundSelectMetaAction,
  ForegroundSelectSingle,
  ForegroundSelectMulti,
  ForegroundSubItemGroup,
  SELECT_ACTION,
} from '../../components/ForegroundSelect';
import { DeviceWizardStepProps } from './DeviceWizard';
import { BatteryLife, DeviceUseCase, Verticals } from '../../models/device';

class DeviceDescriptionStepState {
  errorMessage: string;
  isErrorOnVerticals: boolean;
  isErrorOnDimensionX: boolean;
  isErrorOnDimensionY: boolean;
  isErrorOnDimensionZ: boolean;
  isErrorOnWeight: boolean;
  isErrorOnOperatingTemperatureStart: boolean;
  isErrorOnOperatingTemperatureStop: boolean;
  isErrorOnBatteryLongevity: boolean;
  deviceIsModuleOrDevkit: boolean;

  isCreation: boolean;
  deviceBatteryLifesOptions: ForegroundOption[];
  deviceBatteryLifes: BatteryLife[];
  deviceVerticals: Verticals[];
  deviceVerticalsOptions: ForegroundOptionGroup[];
  deviceUseCases: DeviceUseCase[];
  deviceUseCasesOptions: ForegroundOptionGroup[];
  powerSupply: boolean;
  batteryRechargeable: boolean;
  esim: boolean;
  currentUsescases: ForegroundSubItemGroup[];
  currentVerticals: ForegroundSubItemGroup[];
  currentBatteryLife: ForegroundOption;

  constructor() {
    this.errorMessage = '';
    this.isErrorOnVerticals = false;
    this.isErrorOnDimensionX = false;
    this.isErrorOnDimensionY = false;
    this.isErrorOnDimensionZ = false;
    this.isErrorOnWeight = false;
    this.isErrorOnOperatingTemperatureStart = false;
    this.isErrorOnOperatingTemperatureStop = false;
    this.isErrorOnBatteryLongevity = false;
    this.deviceIsModuleOrDevkit = false;

    this.isCreation = true;
    this.deviceBatteryLifesOptions = [];
    this.deviceBatteryLifes = [];
    this.deviceVerticals = [];
    this.deviceVerticalsOptions = [];
    this.deviceUseCases = [];
    this.deviceUseCasesOptions = [];
    this.powerSupply = false;
    this.batteryRechargeable = false;
    this.esim = false;
    this.currentUsescases = [];
    this.currentVerticals = [];
    this.currentBatteryLife = null;
  }
}

export class DeviceDescriptionStep extends Component<DeviceWizardStepProps, DeviceDescriptionStepState> {
  constructor(props) {
    super(props);

    this.state = new DeviceDescriptionStepState();
    this.handleChangeBatteryLife = this.handleChangeBatteryLife.bind(this);
    this.handleChangeUseCases = this.handleChangeUseCases.bind(this);
    this.handleChangeVerticals = this.handleChangeVerticals.bind(this);
  }

  async componentDidMount() {
    try {
      const response = await Promise.all([ProductService.deviceBatteryLifes(), ProductService.deviceVerticals(), ProductService.deviceUseCases()]);
      const isCreation = !!!this.props.device.id;

      const deviceBatteryLifesOptions = response[0].data.map((item) => {
        return {
          label: item.content,
          value: item.id,
        };
      });

      const deviceVerticalsSubOptions = [];
      const parentVertical = response[1].data.filter((item) => item.parent === 0);

      parentVertical.forEach((v) => {
        const subVertical = response[1].data
          .filter((item) => item.parent === v.id)
          .sort((a, b) => {
            return a.id - b.id;
          })
          .map((item) => {
            return new ForegroundSubItemGroup(item.label, item.id, v.label);
          });
        deviceVerticalsSubOptions.push({ id: v.id, options: subVertical });
      });

      const deviceVerticalsOptions = parentVertical.map((item) => {
        const subVertical = deviceVerticalsSubOptions.find((v) => v.id === item.id);
        return new ForegroundOptionGroup(item.label, item.id, subVertical.options);
      });

      const deviceUsesCasesSubOptions = [];
      const parentUsesCases = response[2].data.filter((item) => item.parent === 0);

      parentUsesCases.forEach((v) => {
        const subUsesCases = response[2].data
          .filter((item) => item.parent === v.id)
          .sort((a, b) => {
            return a.id - b.id;
          })
          .map((item) => {
            return new ForegroundSubItemGroup(item.label, item.id, v.label);
          });
        deviceUsesCasesSubOptions.push({ id: v.id, options: subUsesCases });
      });

      const deviceUsesCasesOptions = parentUsesCases.map((item) => {
        const subUsesCases = deviceUsesCasesSubOptions.find((v) => v.id === item.id);
        return new ForegroundOptionGroup(item.label, item.id, subUsesCases.options);
      });

      const currentBatteryLife = !isCreation ? deviceBatteryLifesOptions.find((bl) => bl.value === this.props.device.batteryLife.id) : null;

      const currentUsescases = [];
      const currentVerticals = [];
      if (!isCreation) {
        this.props.device.usescases.forEach((element) => {
          const usescase = response[2].data.find((u) => u.id === element.id);
          const parentGroup =
            usescase.parent === 0
              ? null
              : response[2].data.find((u) => {
                  return u.id === usescase.parent;
                }).label;
          currentUsescases.push({ value: usescase.id, label: usescase.label, group: parentGroup });
        });
        this.props.device.verticals.forEach((element) => {
          const vertical = response[1].data.find((u) => {
            return u.id === element.id;
          });
          const parentGroup =
            vertical.parent === 0
              ? null
              : response[1].data.find((u) => {
                  return u.id === vertical.parent;
                }).label;

          currentVerticals.push({ value: vertical.id, label: vertical.label, group: parentGroup });
        });
      }
      const deviceIsModuleOrDevkit = !isCreation ? this.props.device.type.id === 5 || this.props.device.type.id === 13 : false;

      this.setState({
        deviceBatteryLifesOptions: deviceBatteryLifesOptions,
        deviceBatteryLifes: response[0].data,
        currentBatteryLife: currentBatteryLife,
        deviceVerticals: response[1].data,
        deviceVerticalsOptions: deviceVerticalsOptions,
        deviceUseCases: response[2].data,
        deviceUseCasesOptions: deviceUsesCasesOptions,
        isCreation: isCreation,
        powerSupply: this.props.device.powerSupply,
        batteryRechargeable: this.props.device.batteryRechargeable,
        esim: this.props.device.esim,
        currentUsescases: currentUsescases,
        currentVerticals: currentVerticals,
        deviceIsModuleOrDevkit: deviceIsModuleOrDevkit,
      });
    } catch (e) {
      console.error(e);
    }
  }

  validate() {
    if (this.props.isReadOnly) {
      return true;
    }
    if (!this.state.deviceIsModuleOrDevkit && (!!!this.props.device.verticals || this.props.device.verticals.length === 0)) {
      this.setState({ isErrorOnVerticals: true, errorMessage: PLEASE_SELECT_VERTICALS });
      return false;
    }

    if (!!!this.props.device.dimensionX || this.props.device.dimensionX.toString().trim().length === 0) {
      this.setState({ isErrorOnDimensionX: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }
    if (!NUMERIC_REGEX.test(this.props.device.dimensionX)) {
      this.setState({ isErrorOnDimensionX: true, errorMessage: NUMERIC_FIELD_ERROR });
      return false;
    }
    if (!!!this.props.device.dimensionY || this.props.device.dimensionY.toString().trim().length === 0) {
      this.setState({ isErrorOnDimensionY: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }
    if (!NUMERIC_REGEX.test(this.props.device.dimensionY)) {
      this.setState({ isErrorOnDimensionY: true, errorMessage: NUMERIC_FIELD_ERROR });
      return false;
    }
    if (!!!this.props.device.dimensionZ || this.props.device.dimensionZ.toString().trim().length === 0) {
      this.setState({ isErrorOnDimensionZ: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }
    if (!NUMERIC_REGEX.test(this.props.device.dimensionZ)) {
      this.setState({ isErrorOnDimensionZ: true, errorMessage: NUMERIC_FIELD_ERROR });
      return false;
    }
    if (!!!this.props.device.weight || this.props.device.weight.toString().trim().length === 0) {
      this.setState({ isErrorOnWeight: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }
    if (!NUMERIC_REGEX.test(this.props.device.weight)) {
      this.setState({ isErrorOnWeight: true, errorMessage: NUMERIC_FIELD_ERROR });
      return false;
    }

    const propsDeviceBatteryLife = !!this.props.device.batteryLife ? this.props.device.batteryLife : null;
    if (!propsDeviceBatteryLife || !this.state.currentBatteryLife) {
      this.setState({ isErrorOnBatteryLongevity: true, errorMessage: PLEASE_SELECT_BATTERY_LONGEVITY });
      return false;
    }

    if (!!!this.props.device.operatingTemperatureStart || this.props.device.operatingTemperatureStart.toString().trim().length === 0) {
      this.setState({ isErrorOnOperatingTemperatureStart: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }
    if (!SIGN_NUMERIC_REGEX.test(this.props.device.operatingTemperatureStart)) {
      this.setState({ isErrorOnOperatingTemperatureStart: true, errorMessage: NUMERIC_FIELD_ERROR });
      return false;
    }
    if (!!!this.props.device.operatingTemperatureStop || this.props.device.operatingTemperatureStop.toString().trim().length === 0) {
      this.setState({ isErrorOnOperatingTemperatureStop: true, errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }
    if (!SIGN_NUMERIC_REGEX.test(this.props.device.operatingTemperatureStop)) {
      this.setState({ isErrorOnOperatingTemperatureStop: true, errorMessage: NUMERIC_FIELD_ERROR });
      return false;
    }
    return true;
  }

  handleChangeBatteryLife(event: ForegroundOption, action: ForegroundSelectMetaAction) {
    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        const deviceBatteryLife = this.state.deviceBatteryLifes.find((a) => a.id === event.value);
        this.props.device.batteryLife = deviceBatteryLife;
        this.setState({ currentBatteryLife: event, isErrorOnBatteryLongevity: false });
        break;
      case SELECT_ACTION.CLEAR:
        this.props.device.batteryLife = null;
        this.setState({ currentBatteryLife: null, isErrorOnBatteryLongevity: true, errorMessage: PLEASE_SELECT_BATTERY_LONGEVITY });
        break;
      default:
        this.setState({ isErrorOnBatteryLongevity: true, errorMessage: PLEASE_SELECT_BATTERY_LONGEVITY });
        break;
    }
  }

  handleChangeUseCases(event: ForegroundSubItemGroup[], action: ForegroundSelectMetaAction) {
    const currentUsescases = this.state.currentUsescases;
    let newUsecases = currentUsescases;
    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        newUsecases = event;
        this.setState({ currentUsescases: event });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentUsescases.filter((e) => e.value !== action.removedValue.value);
        this.setState({ currentUsescases: newSelection });
        newUsecases = newSelection;
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentUsescases: [] });
        newUsecases = [];
        break;
      default:
    }
    this.props.device.usescases = [];
    newUsecases.forEach((uc) => {
      const usecase = this.state.deviceUseCases.find((duc) => duc.id === uc.value);
      this.props.device.usescases.push(usecase);
    });
  }

  handleChangeVerticals(event: ForegroundSubItemGroup[], action: ForegroundSelectMetaAction) {
    const currentVerticals = this.state.currentVerticals;
    let newVerticals = currentVerticals;
    switch (action.action) {
      case SELECT_ACTION.SELECT_OPTION:
        newVerticals = event;
        this.setState({ currentVerticals: event, isErrorOnVerticals: false });
        break;
      case SELECT_ACTION.REMOVE_VALUE:
        const newSelection = currentVerticals.filter((e) => e.value !== action.removedValue.value);
        const setToError = newSelection.length === 0;
        const newErrorMessage = setToError ? PLEASE_SELECT_VERTICALS : this.state.errorMessage;
        this.setState({ currentVerticals: newSelection, isErrorOnVerticals: setToError, errorMessage: newErrorMessage });
        newVerticals = newSelection;
        break;
      case SELECT_ACTION.CLEAR:
        this.setState({ currentVerticals: [], isErrorOnVerticals: true, errorMessage: PLEASE_SELECT_VERTICALS });
        newVerticals = [];
        break;
      default:
    }

    this.props.device.verticals = [];
    newVerticals.forEach((v) => {
      const vertical = this.state.deviceVerticals.find((dv) => dv.id === v.value);
      this.props.device.verticals.push(vertical);
    });
  }

  render() {
    if (this.props.shouldDisplay) {
      const deviceBatteryLifesOptions = this.state.deviceBatteryLifesOptions;

      return (
        <div className="row">
          <div className='row'>
            <div className='mb-3 mt-2'>All mandatory fields are marked with an *</div>
            <label className='' htmlFor='features'>
              Main features (maximum of 4)
            </label>
            <textarea
              className='w-100 form-control mt-2 ms-2'
              rows={10}
              id='features'
              defaultValue={this.props.device.features}
              onChange={(e) => {
                this.props.device.features = e.target.value;
              }}
              readOnly={this.props.isReadOnly}
              disabled={this.props.isReadOnly}
            ></textarea>
          </div>
          {this.state.deviceIsModuleOrDevkit ? (
            ''
          ) : (
            <div className="row mt-4">
              <label className="is-required" htmlFor="verticalsGroup">
                Verticals
              </label>

              <ForegroundSelectMulti
                inputId="verticalsGroup"
                placeholder={PLEASE_SELECT_VERTICALS}
                value={this.state.currentVerticals}
                options={this.state.deviceVerticalsOptions}
                onChange={(e, a) => this.handleChangeVerticals(e, a)}
                isDisabled={this.props.isReadOnly}
                ariaDescribedBy={this.state.isErrorOnVerticals ? 'error-vertical' : ''}
                ariaInvalid={this.state.isErrorOnVerticals}
              />
              {this.state.isErrorOnVerticals ? (
                <p id="error-verticals" className="errorMessage mt-2">
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
          )}
          <div className="row mt-4">
            <label className="" htmlFor="useCases">
              Use cases
            </label>
            <ForegroundSelectMulti
              inputId="useCases"
              placeholder={PLEASE_SELECT_USESCASES}
              value={this.state.currentUsescases}
              options={this.state.deviceUseCasesOptions}
              onChange={(e, a) => this.handleChangeUseCases(e, a)}
              isDisabled={this.props.isReadOnly}
            />
          </div>
          <div className="row mt-4">
            <span className="fw-bold is-required">Dimensions and weight </span>
            <div className="row mt-2 ms-2">
              <div className="col-md-2">
                <label className="font-14" htmlFor="dimensionX">
                  X axis (mm)
                </label>
                <input
                  className="w-100 form-control mt-2"
                  type="text"
                  id="dimensionX"
                  maxLength={10}
                  defaultValue={this.props.device.dimensionX}
                  onChange={(e) => {
                    if (NUMERIC_REGEX.test(e.target.value)) {
                      this.props.device.dimensionX = e.target.value;
                      this.setState({ isErrorOnDimensionX: false });
                    } else {
                      this.setState({ isErrorOnDimensionX: true, errorMessage: NUMERIC_FIELD_ERROR });
                    }
                  }}
                  required
                  readOnly={this.props.isReadOnly}
                  disabled={this.props.isReadOnly}
                  aria-describedby={this.state.isErrorOnDimensionX ? 'error-dimensionX' : ''}
                  aria-invalid={this.state.isErrorOnDimensionX}
                  aria-required="true"
                ></input>
                {this.state.isErrorOnDimensionX ? (
                  <p id="error-dimensionX" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
              <div className="col-md-2">
                <label className="font-14" htmlFor="dimensionY">
                  Y axis (mm)
                </label>
                <input
                  className="w-100 form-control mt-2"
                  type="text"
                  id="dimensionY"
                  maxLength={10}
                  defaultValue={this.props.device.dimensionY}
                  onChange={(e) => {
                    if (NUMERIC_REGEX.test(e.target.value)) {
                      this.props.device.dimensionY = e.target.value;
                      this.setState({ isErrorOnDimensionY: false });
                    } else {
                      this.setState({ isErrorOnDimensionY: true, errorMessage: NUMERIC_FIELD_ERROR });
                    }
                  }}
                  required
                  readOnly={this.props.isReadOnly}
                  disabled={this.props.isReadOnly}
                  aria-describedby={this.state.isErrorOnDimensionY ? 'error-dimensionY' : ''}
                  aria-invalid={this.state.isErrorOnDimensionY}
                  aria-required="true"
                ></input>
                {this.state.isErrorOnDimensionY ? (
                  <p id="error-dimensionY" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
              <div className="col-md-2">
                <label className="font-14" htmlFor="dimensionZ">
                  Z axis (mm)
                </label>
                <input
                  className="w-100 form-control mt-2"
                  type="text"
                  id="dimensionZ"
                  maxLength={10}
                  defaultValue={this.props.device.dimensionZ}
                  onChange={(e) => {
                    if (NUMERIC_REGEX.test(e.target.value)) {
                      this.props.device.dimensionZ = e.target.value;
                      this.setState({ isErrorOnDimensionZ: false });
                    } else {
                      this.setState({ isErrorOnDimensionZ: true, errorMessage: NUMERIC_FIELD_ERROR });
                    }
                  }}
                  required
                  readOnly={this.props.isReadOnly}
                  disabled={this.props.isReadOnly}
                  aria-describedby={this.state.isErrorOnDimensionZ ? 'error-dimensionZ' : ''}
                  aria-invalid={this.state.isErrorOnDimensionZ}
                  aria-required="true"
                ></input>
                {this.state.isErrorOnDimensionZ ? (
                  <p id="error-dimensionZ" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
              <div className="col-md-2">
                <label className="font-14" htmlFor="weight">
                  Weight (g)
                </label>
                <input
                  className="w-100 form-control mt-2"
                  type="text"
                  id="weight"
                  maxLength={10}
                  defaultValue={this.props.device.weight}
                  onChange={(e) => {
                    if (NUMERIC_REGEX.test(e.target.value)) {
                      this.props.device.weight = e.target.value;
                      this.setState({ isErrorOnWeight: false });
                    } else {
                      this.setState({ isErrorOnWeight: true, errorMessage: NUMERIC_FIELD_ERROR });
                    }
                  }}
                  required
                  readOnly={this.props.isReadOnly}
                  disabled={this.props.isReadOnly}
                  aria-describedby={this.state.isErrorOnWeight ? 'error-weight' : ''}
                  aria-invalid={this.state.isErrorOnWeight}
                  aria-required="true"
                ></input>
                {this.state.isErrorOnWeight ? (
                  <p id="error-weight" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <span className="fw-bold">Power supply</span>
            <div className="row ms-2">
              <div className="form-check form-switch m-2 div-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="powerSupply"
                  name="powerSupply"
                  checked={this.state.powerSupply}
                  onChange={() => {
                    this.props.device.powerSupply = !this.state.powerSupply;
                    this.setState({ powerSupply: !this.state.powerSupply });
                  }}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label" htmlFor="powerSupply">
                  External power supply
                </label>
              </div>
              <div className="form-check form-switch m-2 div-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="batteryRechargeable"
                  name="batteryRechargeable"
                  checked={this.state.batteryRechargeable}
                  onChange={() => {
                    this.props.device.batteryRechargeable = !this.state.batteryRechargeable;
                    this.setState({ batteryRechargeable: !this.state.batteryRechargeable });
                  }}
                  disabled={this.props.isReadOnly}
                  readOnly={this.props.isReadOnly}
                />
                <label className="form-check-label" htmlFor="batteryRechargeable">
                  Battery Rechargeable
                </label>
              </div>
            </div>

            <div className="row ms-2 col-md-4">
              <label className="is-required" htmlFor="batteryLongevity">
                Battery longevity
              </label>
              <ForegroundSelectSingle
                inputId="batteryLongevity"
                placeholder={PLEASE_SELECT_BATTERY_LONGEVITY}
                value={this.state.currentBatteryLife}
                options={deviceBatteryLifesOptions}
                onChange={(e, a) => this.handleChangeBatteryLife(e, a)}
                isDisabled={this.props.isReadOnly}
                closeOnSelect={true}
              />
              {this.state.isErrorOnBatteryLongevity ? (
                <p id="error-batterylongevity" className="errorMessage mt-2">
                  {this.state.errorMessage}
                </p>
              ) : null}
            </div>
          </div>
          <div className="row mt-4">
            <span className="fw-bold is-required ">Operating temperature</span>
            <div className="row mt-2 ms-2">
              <div className="col-md-2">
                <label className="font-14" htmlFor="operatingTemperatureStart">
                  Min. (°C)
                </label>
                <input
                  className="w-100 form-control mt-2"
                  type="text"
                  id="operatingTemperatureStart"
                  maxLength={11}
                  defaultValue={this.props.device.operatingTemperatureStart}
                  onChange={(e) => {
                    if (SIGN_NUMERIC_REGEX.test(e.target.value)) {
                      this.props.device.operatingTemperatureStart = e.target.value;
                      this.setState({ isErrorOnOperatingTemperatureStart: false });
                    } else {
                      this.setState({ isErrorOnOperatingTemperatureStart: true, errorMessage: SIGN_NUMERIC_FIELD_ERROR });
                    }
                  }}
                  required
                  readOnly={this.props.isReadOnly}
                  disabled={this.props.isReadOnly}
                  aria-describedby={this.state.isErrorOnOperatingTemperatureStart ? 'error-temperatureStart' : ''}
                  aria-invalid={this.state.isErrorOnOperatingTemperatureStart}
                  aria-required="true"
                ></input>
                {this.state.isErrorOnOperatingTemperatureStart ? (
                  <p id="error-temperatureStart" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
              <div className="col-md-2">
                <label className="font-14" htmlFor="operatingTemperatureStop">
                  Max. (°C)
                </label>
                <input
                  className="w-100 form-control mt-2"
                  type="text"
                  id="operatingTemperatureStop"
                  maxLength={11}
                  defaultValue={this.props.device.operatingTemperatureStop}
                  onChange={(e) => {
                    if (SIGN_NUMERIC_REGEX.test(e.target.value)) {
                      this.props.device.operatingTemperatureStop = e.target.value;
                      this.setState({ isErrorOnOperatingTemperatureStop: false });
                    } else {
                      this.setState({ isErrorOnOperatingTemperatureStop: true, errorMessage: SIGN_NUMERIC_FIELD_ERROR });
                    }
                  }}
                  required
                  readOnly={this.props.isReadOnly}
                  disabled={this.props.isReadOnly}
                  aria-describedby={this.state.isErrorOnOperatingTemperatureStop ? 'error-temperatureStop' : ''}
                  aria-invalid={this.state.isErrorOnOperatingTemperatureStop}
                  aria-required="true"
                ></input>
                {this.state.isErrorOnOperatingTemperatureStop ? (
                  <p id="error-temperatureStop" className="errorMessage mt-2">
                    {this.state.errorMessage}
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="form-check form-switch m-2 div-check div-check-esim">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="esim"
                name="esim"
                checked={this.state.esim}
                onChange={() => {
                  this.props.device.esim = !this.state.esim;
                  this.setState({ esim: !this.state.esim });
                }}
                disabled={this.props.isReadOnly}
                readOnly={this.props.isReadOnly}
              />
              <label className="form-check-label" htmlFor="esim">
                eUICC (eSIM) compatible
              </label>
            </div>
            <div className="div-check mt-2 ">
              <a
                className="icon foreground-ic_Guide_help not_underlined ms-2 font-28"
                href={`${process.env.PUBLIC_URL}/assets/pdf/SGP.02-v4.0.pdf`}
                target="_blank" rel='noreferrer'
              >
                <span className="visually-hidden">Technical Specification Remote Provisioning Architecture for Embedded UICC</span>
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
