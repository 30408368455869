export class ForegroundImage {
  public id: number;
  public link: string;
  public isReference: boolean;
  public fileContent: string;
  public name: string;

  constructor(fileName: string, fileContent: string) {
    this.name = fileName;
    this.fileContent = fileContent;
    this.isReference = true;
  }
}

export class ForegroundImageToUpdate {
  public id: number;
  public isReference: boolean;

  constructor(id: number, isReference: boolean) {
    this.id = id;
    this.isReference = isReference;
  }
}
