import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import UserService from '../../services/user.service';
import CompanyService from '../../services/company.service';
import CountryService from '../../services/country.service';

import LoadingSpinner from '../LoadingSpinner';

import Utils from '../../helpers/Utils';

import {
  BAD_PHONE_FORMAT,
  MANDATORY_FIELD_MISSING,
  PHONE_REGEX,
  PLEASE_SELECT_COUNTRY,
  PLEASE_SELECT_ENTITY,
  PLEASE_SELECT_USER_ROLE,
  PLEASE_SELECT_JOB_POSITION,
  PLEASE_SELECT_COMPANY,
  USER_TYPE,
  UNDEFINED_INDEX_VALUE,
  UNDEFINED_LABEL,
  ORANGE_ADMIN_SCOPE,
  ORANGE_USER_SCOPE,
  ORANGE_VALIDATION_SCOPE,
  ORANGE_PARTNER_SCOPE,
  PARTNER_SCOPE,
  PLEASE_CONTACT_ADMIN,
} from '../../models/constants';
import { ForegroundOption, ForegroundSelectSingle, SELECT_STYLES } from '../ForegroundSelect';
import { User, UserCompany, UserJobs, UserToUpdate, UserTypes } from '../../models/user';
import { Country } from '../../models/country';
import { AppProps } from '../../models/Props';

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <>
      {props.data.countryCode ? (
        <>
          <img src={`https://flagcdn.com/${props.data.countryCode.toLowerCase()}.svg`} width="30" alt={props.data.countryName} />
          <span style={{ marginLeft: '5px', textAlign: 'left' }}>{`${props.data.countryName} ${props.data.dialCode}`}</span>
        </>
      ) : (
        <></>
      )}
    </>
  </components.SingleValue>
);

const Option = (props) => {
  return (
    <components.Option {...props}>
      <>
        {props.data.countryCode ? (
          <>
            <img src={`https://flagcdn.com/${props.data.countryCode.toLowerCase()}.svg`} width="30" alt={props.data.countryName} />
            <span style={{ marginLeft: '5px', textAlign: 'left' }}>{`${props.data.countryName} ${props.data.dialCode}`}</span>
          </>
        ) : (
          <></>
        )}
      </>
    </components.Option>
  );
};

class UserDetailsProps {
  public appProps: AppProps;
  public history: any;
}

class UserDetailsState {
  userTypesList: Array<UserTypes>;
  userTypesSelectList: Array<ForegroundOption>;
  entitiesList: Array<UserCompany>;
  entitiesSelectList: Array<ForegroundOption>;
  countriesList: Array<Country>;
  jobsList: Array<UserJobs>;
  jobsSelectList: Array<ForegroundOption>;
  isLoading: boolean;

  userPreviousValues: User;
  firstname: string;
  lastname: string;
  phone: string;
  isErrorOnFirstName: boolean;
  isErrorOnLastName: boolean;
  isErrorOnPhone: boolean;
  currentTypeValue: ForegroundOption;
  isErrorOnType: boolean;
  currentEntity: ForegroundOption;
  isErrorOnEntity: boolean;
  currentJob: ForegroundOption;
  isErrorOnJob: boolean;
  currentCountry: Country;
  isErrorOnCountry: boolean;
  isError: boolean;
  errorMessage: string;
  email: string;

  constructor() {
    this.userTypesList = [];
    this.userTypesSelectList = [];
    this.entitiesList = [];
    this.entitiesSelectList = [];
    this.countriesList = [];
    this.jobsList = [];
    this.jobsSelectList = [];
    this.isLoading = true;

    this.userPreviousValues = null;
    this.firstname = '';
    this.lastname = '';
    this.phone = '';
    this.isErrorOnFirstName = false;
    this.isErrorOnLastName = false;
    this.isErrorOnPhone = false;
    this.currentTypeValue = UNDEFINED_LABEL;
    this.isErrorOnType = false;
    this.currentEntity = UNDEFINED_LABEL;
    this.isErrorOnEntity = false;
    this.currentJob = UNDEFINED_LABEL;
    this.isErrorOnJob = false;
    this.currentCountry = null;
    this.isErrorOnCountry = false;
    this.isError = false;
    this.errorMessage = '';
    this.email = null;
  }
}

export class UserDetails extends Component<UserDetailsProps, UserDetailsState> {
  constructor(props) {
    super(props);

    this.state = new UserDetailsState();
    this.handleChangeUserType = this.handleChangeUserType.bind(this);
    this.handleChangeEntity = this.handleChangeEntity.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChangeJob = this.handleChangeJob.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const userScope = this.props.appProps.scope;
      let response;
      let entitiesList, entitiesSelectList;
      let jobsList, jobsSelectList;
      let userTypesList, userTypesSelectList;
      let userPreviousValues;
      const orangeScopesArray = [ORANGE_ADMIN_SCOPE, ORANGE_PARTNER_SCOPE, ORANGE_USER_SCOPE, ORANGE_VALIDATION_SCOPE];

      if (userScope !== PARTNER_SCOPE && userScope !== ORANGE_PARTNER_SCOPE) {
        response = await Promise.all([
          CountryService.list('countryName'),
          UserService.details(this.props.appProps.userid),
          UserService.userTypesList(),
          CompanyService.companiesList(null, null, 'name', null, null, null, null, true),
        ]);
      } else {
        if (userScope === ORANGE_PARTNER_SCOPE) {
          response = await Promise.all([
            CountryService.list('countryName'),
            UserService.details(this.props.appProps.userid),
            UserService.userTypesList(),
            CompanyService.companiesList(null, null, 'name', null, null, null, null, true),
            UserService.jobsList(),
          ]);
        } else {
          response = await Promise.all([CountryService.list('countryName'), UserService.details(this.props.appProps.userid), UserService.jobsList()]);
        }
      }

      if (userScope === PARTNER_SCOPE) {
        jobsList = response[2].data;
      } else {
        userTypesList = response[2].data;
        entitiesList = response[3].data;
        if (userScope === ORANGE_PARTNER_SCOPE) {
          jobsList = response[4].data;
        }
      }

      userPreviousValues = response[1].data as User;
      const currentCompany = userPreviousValues.company;
      const currentJobValue = userPreviousValues.job;
      const currentTypeValue = userPreviousValues.type;

      // Be really CAREFUL = pointer affectation, if not cloneDeep()
      let currentEntity = cloneDeep(UNDEFINED_LABEL);
      if (Utils.checkFilledValue(currentCompany) && Utils.checkFilledValue(currentCompany.id)) {
        currentEntity.label = currentCompany.name;
        currentEntity.value = currentCompany.id;
      }

      let currentJob = cloneDeep(UNDEFINED_LABEL);
      if (Utils.checkFilledValue(currentJobValue) && Utils.checkFilledValue(currentJobValue.id)) {
        currentJob.label = currentJobValue.label;
        currentJob.value = currentJobValue.id;
      }

      let currentType = cloneDeep(UNDEFINED_LABEL);
      if (Utils.checkFilledValue(currentTypeValue) && Utils.checkFilledValue(currentTypeValue.id)) {
        currentType.label = currentTypeValue.label;
        currentType.value = currentTypeValue.id;
      }

      let currentCountry = new Country();
      if (Utils.checkFilledValue(userPreviousValues.country)) {
        currentCountry = userPreviousValues.country;
      }

      let currentPhone = '';
      if (Utils.checkFilledValue(userPreviousValues.phone)) {
        currentPhone = userPreviousValues.phone;
      }

      const initialTypeValueId =
        Utils.checkFilledValue(userPreviousValues) && Utils.checkFilledValue(userPreviousValues.type)
          ? userPreviousValues.type.id
          : UNDEFINED_INDEX_VALUE;

      if (userScope !== PARTNER_SCOPE) {
        userTypesSelectList = !!userTypesList
          ? userTypesList
              .filter((item) => {
                if (orangeScopesArray.includes(userScope)) return item.id === initialTypeValueId || item.id === USER_TYPE.PARTNER;
                if (userScope === ORANGE_PARTNER_SCOPE) return item.id === USER_TYPE.PARTNER || item.id === USER_TYPE.USER;
                return null;
              })
              .map((item) => {
                return {
                  label: item.label,
                  value: item.id,
                };
              })
          : [];

        /* PROBLEM with the sorting, by the Company API = temporary solution */
        entitiesSelectList = !!entitiesList
          ? entitiesList
              .sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              })
              .map((item) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
          : [];
      }

      if (userScope === PARTNER_SCOPE || userScope === ORANGE_PARTNER_SCOPE) {
        jobsSelectList = !!jobsList
          ? jobsList.map((item) => {
              return new ForegroundOption(item.label, item.id);
            })
          : [];
      }

      this.setState({
        userPreviousValues: userPreviousValues,
        firstname: userPreviousValues.firstName,
        lastname: userPreviousValues.lastName,
        phone: currentPhone,
        email: userPreviousValues.email,
        currentTypeValue: currentType,
        currentCountry: currentCountry,
        countriesList: response[0].data,
        userTypesList: userTypesList,
        userTypesSelectList: userTypesSelectList,
        jobsList: jobsList,
        jobsSelectList: jobsSelectList,
        entitiesList: entitiesList,
        entitiesSelectList: entitiesSelectList,
        currentEntity: currentEntity,
        currentJob: currentJob,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  renderUserDetails() {
    let {
      firstname,
      lastname,
      phone,
      countriesList,
      currentCountry,
      currentEntity,
      currentTypeValue,
      currentJob,
      entitiesSelectList,
      jobsSelectList,
      userTypesSelectList,
    } = this.state;

    const userScope = this.props.appProps.scope;

    return (
      <div className="container" id="account_allprofilesAccountDetails">
        {this.state.isLoading ? (
          <LoadingSpinner />
        ) : (
          <form id="myForm" className="mt-3" onSubmit={this.handleSubmit}>
            <div className='row'>
              <div className='col-md-6 offset-md-0'>
                <div className='mb-3'>All mandatory fields are marked with an *</div>
                <div className='form-group mb-3'>
                  <label htmlFor='firstname' className='is-required'>
                    First name
                  </label>
                  <input
                    type='text'
                    className='form-control mt-2'
                    name='firstname'
                    id='firstname'
                    maxLength={1000}
                    value={firstname}
                    onChange={this.handleChangeFirstName}
                  />
                  {this.state.isErrorOnFirstName ?
                    <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
                </div>

                <div className='form-group mb-3'>
                  <label htmlFor='lastname' className='is-required'>
                    Last name
                  </label>
                  <input
                    type='text'
                    className='form-control mt-2'
                    name='lastname'
                    id='lastname'
                    maxLength={1000}
                    value={lastname}
                    onChange={this.handleChangeLastName}
                  />
                  {this.state.isErrorOnLastName ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
                </div>

                <div className='form-group mb-3'>
                  <label htmlFor='phone' className='is-required'>
                    Phone number
                  </label>
                  <div className='d-flex flex-row mt-2'>
                    <div style={{ width: '300px' }}>
                      <Select
                        name='country_id'
                        id='country_id'
                        styles={SELECT_STYLES}
                        placeholder={PLEASE_SELECT_COUNTRY}
                        value={currentCountry}
                        options={countriesList}
                        onChange={(e) => this.handleChangeCountry(e)}
                        components={{ SingleValue, Option }}
                      />
                      {this.state.isErrorOnCountry ?
                        <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
                    </div>
                    <div>
                      <input
                        type='tel'
                        className='form-control'
                        name='phone'
                        id='phone'
                        maxLength={1000}
                        value={phone}
                        onChange={this.handleChangePhone}
                        required
                        style={{ paddingLeft: 50, height: '40px' }}
                      />
                      {this.state.isErrorOnPhone ?
                        <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
                    </div>
                  </div>
                </div>

                {userScope !== PARTNER_SCOPE ? (
                  <>
                    <div className='form-group mb-3'>
                      <label htmlFor='entity_id' className='is-required'>
                        Orange Entity
                      </label>
                      <ForegroundSelectSingle
                        isClearable={false}
                        inputId='entity_id'
                        placeholder={PLEASE_SELECT_ENTITY}
                        value={currentEntity}
                        options={entitiesSelectList}
                        closeOnSelect={true}
                        onChange={(e) => this.handleChangeEntity(e)}
                      />

                      {this.state.isErrorOnEntity ?
                        <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
                    </div>

                    <div className='form-group mb-3'>
                      <label htmlFor='user_role' className='is-required'>
                        User role
                      </label>
                      <ForegroundSelectSingle
                        isClearable={false}
                        inputId='user_role'
                        placeholder={PLEASE_SELECT_USER_ROLE}
                        value={currentTypeValue}
                        options={userTypesSelectList}
                        closeOnSelect={true}
                        onChange={(e) => this.handleChangeUserType(e)}
                      />

                      {this.state.isErrorOnType ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
                    </div>
                  </>
                ) : (
                  ''
                )}

                {userScope === ORANGE_PARTNER_SCOPE || userScope === PARTNER_SCOPE ? (
                  <div className='form-group mb-3'>
                    <label htmlFor='job' className='is-required'>
                      Job
                    </label>
                    <ForegroundSelectSingle
                      isClearable={false}
                      inputId='job'
                      placeholder={PLEASE_SELECT_JOB_POSITION}
                      value={currentJob}
                      options={jobsSelectList}
                      closeOnSelect={true}
                      onChange={(e) => this.handleChangeJob(e)}
                    />

                    {this.state.isErrorOnJob ? <p className='errorMessage mt-2'>{this.state.errorMessage}</p> : null}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
              <div className="form-group my-3">
                  <button type="submit" className="btn btn-primary">
                    Save details
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
  render() {
    return (
      <div className="UserDetails" id="userdetails">
        {this.state.isLoading ? <LoadingSpinner /> : this.renderUserDetails()}
      </div>
    );
  }

  handleChangeFirstName = (event) => {
    const value = event.target.value;
    this.setState({ firstname: value });
    this.setState({ isErrorOnFirstName: false });
  };

  handleChangeLastName = (event) => {
    const value = event.target.value;
    this.setState({ lastname: value });
    this.setState({ isErrorOnLastName: false });
  };

  handleChangePhone = (event) => {
    const value = event.target.value;
    this.setState({ phone: value });
    this.setState({ isErrorOnPhone: false });
  };

  handleChangeUserType(event) {
    this.setState({ currentTypeValue: event, isErrorOnType: false });
  }

  handleChangeEntity(event: ForegroundOption) {
    this.setState({ currentEntity: event, isErrorOnEntity: false });
  }

  handleChangeCountry(event) {
    this.setState({ currentCountry: event, isErrorOnCountry: false });
  }

  handleChangeJob(event: ForegroundOption) {
    this.setState({ currentJob: event });
    this.setState({ isErrorOnJob: false });
  }

  validate() {
    const userScope = this.props.appProps.scope;
    const emptyCountry = new Country();

    if (this.state.firstname.trim().length === 0) {
      this.setState({ isErrorOnFirstName: true });
      this.setState({ errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }

    if (this.state.lastname.trim().length === 0) {
      this.setState({ isErrorOnLastName: true });
      this.setState({ errorMessage: MANDATORY_FIELD_MISSING });
      return false;
    }

    if (this.state.currentCountry.id === emptyCountry.id) {
      this.setState({ isErrorOnCountry: true });
      this.setState({ errorMessage: PLEASE_SELECT_COUNTRY });
      return false;
    }

    if (!PHONE_REGEX.test(this.state.phone)) {
      this.setState({ isErrorOnPhone: true });
      this.setState({ errorMessage: BAD_PHONE_FORMAT });
      return false;
    }

    if (userScope !== PARTNER_SCOPE) {
      if (this.state.currentTypeValue.value === UNDEFINED_LABEL.value) {
        this.setState({ isErrorOnType: true });
        this.setState({ errorMessage: PLEASE_SELECT_USER_ROLE });
        return false;
      }

      if (this.state.currentEntity.value === UNDEFINED_LABEL.value) {
        this.setState({ isErrorOnEntity: true });
        this.setState({ errorMessage: PLEASE_SELECT_COMPANY });
        return false;
      }
      if (
        Utils.checkFilledValue(this.state.userPreviousValues.type) &&
        Utils.checkFilledValue(this.state.currentTypeValue) &&
        this.state.userPreviousValues.type.id !== this.state.currentTypeValue.value
      ) {
        if (this.state.userPreviousValues.type.id === USER_TYPE.PARTNER) {
          this.setState({ isErrorOnType: true });
          this.setState({ errorMessage: PLEASE_CONTACT_ADMIN });
          return false;
        }
      }
    }

    if (userScope === ORANGE_PARTNER_SCOPE || userScope === PARTNER_SCOPE) {
      if (this.state.currentJob.value === UNDEFINED_LABEL.value) {
        this.setState({ isErrorOnJob: true });
        this.setState({ errorMessage: PLEASE_SELECT_JOB_POSITION });
        return false;
      }
    }
    return true;
  }

  handleSubmit(event) {
    let userType = null;
    if (Utils.checkFilledValue(this.state.userPreviousValues.type)) userType = this.state.userPreviousValues.type;

    let entityC = null;
    if (Utils.checkFilledValue(this.state.userPreviousValues.company)) entityC = this.state.userPreviousValues.company;

    let jobP = null;
    if (Utils.checkFilledValue(this.state.userPreviousValues.job)) jobP = this.state.userPreviousValues.job;

    event.preventDefault();
    this.setState({ isError: false });

    if (!this.validate()) {
      console.log(`Error validate in User Account Details`);
      return;
    }

    if (Utils.checkFilledValue(this.state.currentTypeValue.value)) {
      if (Utils.checkFilledValue(this.state.userTypesList)) {
        userType = this.state.userTypesList.find((e) => e.id === this.state.currentTypeValue.value);
      }
    }

    if (Utils.checkFilledValue(this.state.currentEntity.value)) {
      if (Utils.checkFilledValue(this.state.entitiesList)) {
        entityC = this.state.entitiesList.find((e) => e.id === this.state.currentEntity.value);
      }
    }

    if (Utils.checkFilledValue(this.state.currentJob.value)) {
      if (Utils.checkFilledValue(this.state.jobsList)) {
        jobP = this.state.jobsList.find((e) => e.id === this.state.currentJob.value);
      }
    }

    const body = new UserToUpdate(
      this.state.firstname,
      this.state.lastname,
      this.state.email,
      this.state.currentCountry,
      this.state.phone,
      userType,
      jobP,
      entityC
    );

    try {
      UserService.updateUser(this.props.appProps.userid, body)
        .then((res) => {
          if (res.status === 204) {
            if (
              Utils.checkFilledValue(this.state.userPreviousValues.type) &&
              Utils.checkFilledValue(userType) &&
              this.state.userPreviousValues.type.id !== userType.id
            ) {
              document.dispatchEvent(
                new CustomEvent('addNotification', {
                  detail: {
                    type: 'logout_needed',
                    content: `The user ${this.state.firstname} ${this.state.lastname} has been updated. A logging out is now needed, for authorization modification.`,
                  },
                })
              );
              this.props.appProps.logout();
            } else {
              document.dispatchEvent(
                new CustomEvent('addNotification', {
                  detail: {
                    type: 'success',
                    content: `The user ${this.state.firstname} ${this.state.lastname} has been updated.`,
                  },
                })
              );
            }
            this.props.history.push('/products');
          } else {
            if (res.status >= 400) {
              this.setState({ isError: true, errorMessage: res.data.message });
            }
          }
        })
        .catch((e) => {
          console.log(`err ${JSON.stringify(e)}`);
          this.setState({ isError: true, errorMessage: e.message });
        });
    } catch (e) {
      let message = 'Error in User Account Details (Submit)';
      this.setState({ isError: true, errorMessage: message });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userid: state.userid,
    firstname: state.firstname,
    lastname: state.lastname,
    scope: state.scope,
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(UserDetails);
