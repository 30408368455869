import { Component } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import {
  PASSWORD_FORMAT,
  PASSWORD_LENGTH,
  PASSWORD_REGEX,
  NOT_IDENTICAL_PASSWORDS_ERROR,
  NOT_VALID_PASSWORD,
  SESSION_STORAGE_PASSWORD,
  USER_NOT_FOUND,
  INTERNAL_ERROR,
  GENERAL_ERROR,
} from '../../models/constants';
import UserService from '../../services/user.service';
import './UserManagement.css';
import { PasswordInput } from '../PasswordInput';
import { AppProps } from '../../models/Props';
import { PageHeader } from '../PageHeader';
import Utils from '../../helpers/Utils';
import { CguProps } from '../../pages/Cgu';

class ChangePasswordProps {
  public appProps: AppProps;
  public history: any;
}

class ChangePassworState {
  public isError: boolean;
  public isErrorOnMyPassword: boolean;
  public isErrorOnMyNewPassword: boolean;
  public isErrorOnMyConfirmPassword: boolean;
  public messageError: string;
  public myPassword: string;
  public myNewPassword: string;
  public myConfirmPassword: string;

  constructor(
    isError: boolean,
    isErrorOnMyPassword: boolean,
    isErrorOnMyNewPassword: boolean,
    isErrorOnMyConfirmPassword: boolean,
    messageError: string,
    myPassword: string
  ) {
    this.isError = isError;
    this.isErrorOnMyPassword = isErrorOnMyPassword;
    this.isErrorOnMyNewPassword = isErrorOnMyNewPassword;
    this.isErrorOnMyConfirmPassword = isErrorOnMyConfirmPassword;
    this.messageError = messageError;
    this.myPassword = myPassword;
  }
}

export class ChangePassword extends Component<ChangePasswordProps, ChangePassworState> {
  constructor(props) {
    super(props);
    let myPassword = sessionStorage.getItem(SESSION_STORAGE_PASSWORD);
    this.state = new ChangePassworState(false, false, false, false, '', myPassword);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handleMyPasswordChange = this.handleMyPasswordChange.bind(this);
    this.handleMyNewPasswordChange = this.handleMyNewPasswordChange.bind(this);
  }

  handleConfirmPasswordChange(e) {
    this.setState({ myConfirmPassword: e.target.value });
  }

  handleMyPasswordChange(e) {
    this.setState({ myPassword: e.target.value });
  }

  handleMyNewPasswordChange(e) {
    this.setState({ myNewPassword: e.target.value });
  }

  async onSubmit(e) {
    let response;
    e.preventDefault();
    if (!this.validate()) {
      console.log(`Error validate in User Password`);
      return;
    }

    try {
      let userid = this.props.appProps.userid;
      let body = { oldPassword: this.state.myPassword, newPassword: this.state.myNewPassword };
      response = await UserService.updatePassword(userid, body);
      switch (response.status) {
        case 204:
          document.dispatchEvent(
            new CustomEvent('addNotification', {
              detail: {
                type: 'success',
                content: `Your password has been updated. Plese log in again!`,
              },
            })
          );
          break;
        case 403:
          switch (response.data.code) {
            case 1950:
              this.setState({ isErrorOnMyPassword: true, messageError: NOT_VALID_PASSWORD });
              break;
            default:
              this.setState({ isErrorOnMyPassword: true, messageError: response.data.message });
              break;
          }
          return false;
        case 404:
          this.setState({ isErrorOnMyNewPassword: true, messageError: USER_NOT_FOUND });
          return false;
        case 500:
          this.setState({ isErrorOnMyNewPassword: true, messageError: INTERNAL_ERROR });
          return false;
        default:
          this.setState({ isErrorOnMyNewPassword: true, messageError: response.data.message });
          return false;
      }
    } catch (e) {
      console.log(`err ${JSON.stringify(e)}`);
      this.setState({ isError: true, messageError: GENERAL_ERROR });
      return false;
    }
    sessionStorage.removeItem(SESSION_STORAGE_PASSWORD);
    this.props.appProps.logout();
    this.props.history.push(`/login`);
  }

  render() {
    document.title = 'Change Password - Foreground V3';
    return (
      <div className="p-3 bg-white">
        <PageHeader title="Change Password"></PageHeader>
        <div className="container mt-5 ">
          <form role="form" className="col-md-4" onSubmit={(e) => this.onSubmit(e)}>
            <div className="d-flex flex-column">
              <div id="passwordSection" className="form-group mt-3 div-width">
                <label htmlFor="myPassword" className="is-required mb-2">
                  Current password
                </label>
                <PasswordInput
                  inputId="myPassword"
                  defaultValue={this.state.myPassword}
                  placeholder={`Current Password`}
                  handleChangePassword={(e) => this.handleMyPasswordChange(e)}
                ></PasswordInput>
                {this.state.isErrorOnMyPassword ? <p className="errorMessage mt-2">{this.state.messageError}</p> : ''}
              </div>
              <div id="newPasswordSection" className="form-group mt-3">
                <label htmlFor="myNewPassword" className="is-required mb-2">
                  New password
                </label>
                <div className="d-flex flex-row  div-width-big">
                  <div className="div-width">
                    <PasswordInput
                      inputId="myNewPassword"
                      placeholder={`New Password`}
                      handleChangePassword={(e) => this.handleMyNewPasswordChange(e)}
                    ></PasswordInput>
                  </div>
                  <span className="input-group-append mt-2">
                    <a data-tooltip-id="my-tooltip" data-tooltip-content={PASSWORD_FORMAT} data-tooltip-place="right">
                      <span className="icon foreground-ic_Assistance ms-2 pt-2 icon-40" aria-hidden="true"></span>
                    </a>
                    <Tooltip id="my-tooltip" className="tooltip-content" />
                  </span>
                </div>
                {this.state.isErrorOnMyNewPassword ? <p className="errorMessage mt-2">{this.state.messageError}</p> : ''}
              </div>
              <div id="confirmPasswordSection" className="form-group mt-3">
                <label htmlFor="myConfirmPassword" className="is-required mb-2">
                  Confirm new password
                </label>
                <div className="d-flex flex-row  div-width-big">
                  <div className="div-width">
                    <PasswordInput
                      inputId="myConfirmPassword"
                      placeholder={`Confirm New Password`}
                      handleChangePassword={(e) => this.handleConfirmPasswordChange(e)}
                    ></PasswordInput>
                  </div>
                  {this.state.isErrorOnMyConfirmPassword ? <p className="errorMessage mt-2">{this.state.messageError}</p> : ''}
                </div>
                {this.state.isError ? <p className="errorMessage">{this.state.messageError}</p> : ''}
              </div>
              <div id="loginButtonSection" className="mt-3">
                <button id="authButton" type="submit" className="btn btn-primary">
                  Save password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  validate() {
    // https://www.geeksforgeeks.org/reactjs-setstate/
    /*
    setState is asynchronous call means if synchronous call get called it may not get updated at right time like to know current value of object after update using setState it may not get give current updated value on console. To get some behavior of synchronous need to pass function instead of object to setState.
    */
    if (!PASSWORD_REGEX.test(this.state.myNewPassword) || this.state.myNewPassword.length < PASSWORD_LENGTH) {
      this.setState({ isErrorOnMyNewPassword: true, messageError: PASSWORD_FORMAT });
      return false;
    } else {
      this.setState({ isErrorOnMyNewPassword: false, messageError: '' });
    }

    if (!PASSWORD_REGEX.test(this.state.myConfirmPassword) || this.state.myConfirmPassword.length < PASSWORD_LENGTH) {
      this.setState({ isErrorOnMyConfirmPassword: true, messageError: PASSWORD_FORMAT });
      return false;
    } else {
      this.setState({ isErrorOnMyConfirmPassword: false, messageError: '' });
    }

    if (this.state.myNewPassword !== this.state.myConfirmPassword) {
      this.setState({ isError: true, isErrorOnMyNewPassword: false, isErrorOnMyConfirmPassword: false, messageError: NOT_IDENTICAL_PASSWORDS_ERROR });
      return false;
    } else {
      this.setState({ isError: false, isErrorOnMyNewPassword: false, isErrorOnMyConfirmPassword: false, messageError: '' });
    }

    return true;
  }
}
