import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, RichUtils, Modifier } from 'draft-js';
import PropTypes from 'prop-types';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './ManageTranslation.css';
import { AVAILABLE_LANGUAGES } from '../../models/constants';
import { stopPropagation } from 'react-draft-wysiwyg';
import { getEntityRange, getSelectionEntity } from 'draftjs-utils';

export class ManageTranslationProps {
  public manageObject: any;
  public field: string;
  public isReadOnly: boolean;
  public ariaDescribedBy: string;
  public ariaInvalid: boolean;
  public ariaLabel: string;
  public handleTranslateValueChanged() {}
}

class ManageTranslationState {
  public isLoading: boolean;
  public selectedCountry: string;
  public translationItems: any;
  public editorStateEn: EditorState;
  public editorStateFr: EditorState;
  public editorStateEs: EditorState;
  public editorStateRo: EditorState;
  public editorStatePl: EditorState;
  public editorStateNl: EditorState;

  constructor(isLoading: boolean, selectedCountry: string, translationItems: any) {
    this.isLoading = isLoading;
    this.selectedCountry = selectedCountry;
    this.translationItems = translationItems;
    AVAILABLE_LANGUAGES.forEach((l) => {
      let editorState;
      if (!!translationItems[l]) {
        const contentBlock = htmlToDraft(translationItems[l]);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorState = EditorState.createWithContent(contentState);
      } else {
        editorState = EditorState.createEmpty();
      }
      switch (l) {
        case 'EN':
          this.editorStateEn = editorState;
          break;
        case 'FR':
          this.editorStateFr = editorState;
          break;
        case 'ES':
          this.editorStateEs = editorState;
          break;
        case 'RO':
          this.editorStateRo = editorState;
          break;
        case 'PL':
          this.editorStatePl = editorState;
          break;
        case 'NL':
          this.editorStateNl = editorState;
          break;
      }
    });
  }
}

const TOOLBAR_EMPTY = {
  options: [],
};

class CustomOptionProp {
  public editorState: EditorState;
  public onChange(newState) {}
  public country: string;
  public isEnabled: boolean;
}

class CustomOptionLink extends Component<CustomOptionProp> {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
    country: PropTypes.string,
  };

  state = {
    showModal: false,
    linkTarget: '',
    linkTitle: '',
    currentEntity: this.props.editorState ? getSelectionEntity(this.props.editorState) : undefined,
  };

  componentDidUpdate(prevProps) {
    const { editorState } = this.props;
    if (editorState && editorState !== prevProps.editorState) {
      this.setState({ currentEntity: getSelectionEntity(editorState) });
    }
  }

  addLinkAction = (linkTitle, linkTarget) => {
    const { editorState, onChange } = this.props;
    const { currentEntity } = this.state;
    let selection = editorState.getSelection();

    if (currentEntity) {
      const entityRange = getEntityRange(editorState, currentEntity);
      const isBackward = selection.getIsBackward();
      if (isBackward) {
        selection = selection.merge({
          anchorOffset: entityRange.end,
          focusOffset: entityRange.start,
        });
      } else {
        selection = selection.merge({
          anchorOffset: entityRange.start,
          focusOffset: entityRange.end,
        });
      }
    }
    const entityKey = editorState
      .getCurrentContent()
      .createEntity('LINK', 'MUTABLE', {
        url: linkTarget,
        targetOption: '_blank',
      })
      .getLastCreatedEntityKey();

    let contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      selection,
      `${linkTitle}`,
      editorState.getCurrentInlineStyle(),
      entityKey
    );
    let newEditorState = EditorState.push(editorState, contentState, 'insert-characters');

    // insert a blank space after link
    selection = newEditorState.getSelection().merge({
      anchorOffset: selection.get('anchorOffset') + linkTitle.length,
      focusOffset: selection.get('anchorOffset') + linkTitle.length,
    });
    newEditorState = EditorState.acceptSelection(newEditorState, selection);
    contentState = Modifier.insertText(newEditorState.getCurrentContent(), selection, ' ', newEditorState.getCurrentInlineStyle(), undefined);
    onChange(EditorState.push(newEditorState, contentState, 'insert-characters'));
    this.toggleCloseAddLink();
  };

  addLink = () => {
    const { onChange } = this.props;
    const { linkTitle, linkTarget } = this.state;
    this.addLinkAction(linkTitle, linkTarget);
  };

  updateValue = (event) => {
    this.setState({
      [`${event.target.name}`]: event.target.value,
    });
  };

  getCurrentBlock = (editorState) => {
    const currentSelection = editorState.getSelection();
    const blockKey = currentSelection.getStartKey();
    return editorState.getCurrentContent().getBlockForKey(blockKey);
  };

  getCurrentText = (editorState) => {
    const currentBlock = this.getCurrentBlock(editorState);
    const blockText = currentBlock.getText();
    return blockText;
  };

  toggleOpenAddLink: Function = (): void => {
    this.setState({ showModal: true });
    const { editorState } = this.props;
    const currentEntity = getSelectionEntity(editorState);
    const contentState = editorState.getCurrentContent();
    const entityRange = currentEntity && getEntityRange(editorState, currentEntity);
    let selection = editorState.getSelection();

    if (currentEntity && contentState.getEntity(currentEntity).get('type') === 'LINK') {
      this.setState({
        linkTarget: currentEntity && contentState.getEntity(currentEntity).get('data').url,
        linkTitle: entityRange && entityRange.text,
      });
    } else {
      if (selection) {
        const currentText = this.getCurrentText(editorState);
        const isBackward = selection.getIsBackward();
        let selectedText;
        if (isBackward) {
          selectedText = currentText.substring(selection.focusOffset, selection.anchorOffset);
        } else {
          selectedText = currentText.substring(selection.anchorOffset, selection.focusOffset);
        }
        this.setState({ linkTarget: '', linkTitle: selectedText });
      } else {
        this.setState({ linkTarget: '', linkTitle: '' });
      }
    }
  };
  toggleCloseAddLink: Function = (): void => {
    this.setState({ showModal: false });
  };

  AddLink: Function = (): JSX.Element => {
    const { linkTitle, linkTarget } = this.state;
    return (
      <div className="rdw-link-modal" onClick={stopPropagation}>
        <label className="rdw-link-modal-label" htmlFor="linkTitle">
          Link Title
        </label>
        <input
          id="linkTitle"
          className="rdw-link-modal-input"
          onChange={this.updateValue}
          onBlur={this.updateValue}
          name="linkTitle"
          value={linkTitle}
        />
        <label className="rdw-link-modal-label" htmlFor="linkTarget">
          Link Target
        </label>
        <input
          id="linkTarget"
          className="rdw-link-modal-input"
          onChange={this.updateValue}
          onBlur={this.updateValue}
          name="linkTarget"
          value={linkTarget}
        />

        <span className="rdw-link-modal-buttonsection">
          <button className="rdw-link-modal-btn" onClick={this.addLink} disabled={!linkTarget || !linkTitle}>
            Add
          </button>
          <button
            className="rdw-link-modal-btn"
            onClick={() => {
              this.toggleCloseAddLink();
            }}
          >
            Cancel
          </button>
        </span>
      </div>
    );
  };

  render() {
    const { country, isEnabled } = this.props;
    return (
      <div>
        <button
          id={`button-link-${country}`}
          disabled={!isEnabled}
          className="custom-option"
          onClick={() => {
            this.toggleOpenAddLink();
          }}
        >
          <img className="custom-option-element" src="/assets/img/link.svg" alt="Link"></img>
        </button>
        {this.state.showModal && this.AddLink()}
      </div>
    );
  }
}

class CustomOption extends Component<CustomOptionProp> {
  static propTypes = {
    country: PropTypes.string,
    isEnabled: PropTypes.bool,
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  toggleBold: Function = (): void => {
    const { editorState, onChange } = this.props;
    const newState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
    if (newState) {
      onChange(newState);
    }
  };

  render() {
    const { country, isEnabled } = this.props;
    return (
      <button
        id={`button-bold-${country}`}
        className="custom-option"
        disabled={!isEnabled}
        onClick={() => {
          this.toggleBold();
        }}
      >
        <span className="custom-option-element">B</span>
      </button>
    );
  }
}

export class ManageTranslation extends Component<ManageTranslationProps, ManageTranslationState> {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    const translationItems = {};
    AVAILABLE_LANGUAGES.forEach((l) => {
      if (l === 'EN') {
        translationItems[l] = this.props.manageObject[this.props.field];
      } else {
        const otherValue = this.props.manageObject.informationI18N.find((e) => e.countryCode === l);

        const value = !!otherValue ? otherValue[this.props.field] : '';
        translationItems[l] = value;
      }
    });
    this.state = new ManageTranslationState(true, 'EN', translationItems);
  }

  async componentDidMount() {}
  handleChange(content: string, key) {
    const { translationItems } = this.state;
    let otherValue = {};

    translationItems[key] = content;
    this.setState({
      translationItems: translationItems,
    });

    if (key === 'EN') {
      this.props.manageObject[this.props.field] = content;
    } else {
      if (!!this.props.manageObject.informationI18N) {
        let infoForCurrentCountry = this.props.manageObject.informationI18N.find((e) => e.countryCode === key);
        otherValue = !!infoForCurrentCountry ? infoForCurrentCountry : { isNewToBeInitialized: true };
        otherValue[this.props.field] = content;
      } else {
        otherValue['isNewToBeInitialized'] = true;
      }

      if (otherValue['isNewToBeInitialized']) {
        otherValue[this.props.field] = content;
        otherValue['countryCode'] = key;
        otherValue['id'] = null;
        otherValue['jointProjectWithOrange'] = null;
        otherValue['isNewToBeInitialized'] = false;

        if (!!this.props.manageObject.informationI18N) this.props.manageObject.informationI18N.push(otherValue);
      }
    }

    this.props.handleTranslateValueChanged();
  }

  onEditorStateChange: Function = (editorState, key) => {
    switch (key) {
      case 'EN':
        this.setState({ editorStateEn: editorState });
        break;
      case 'FR':
        this.setState({ editorStateFr: editorState });
        break;
      case 'ES':
        this.setState({ editorStateEs: editorState });
        break;
      case 'RO':
        this.setState({ editorStateRo: editorState });
        break;
      case 'PL':
        this.setState({ editorStatePl: editorState });
        break;
      case 'NL':
        this.setState({ editorStateNl: editorState });
        break;
    }
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.handleChange(content, key);
  };

  render() {
    const { selectedCountry, translationItems } = this.state;
    return (
      <div>
        <div className="d-flex flex-row col-10 collapse navbar-collapse justify-content-between align-items-baseline">
          <ul className="nav nav-tabs " role="tablist" id="nav-tab">
            {AVAILABLE_LANGUAGES.map((c) => {
              return (
                <li
                  key={c}
                  id={`nav-descr-${c}-li`}
                  className="nav-item"
                  role="tab"
                  onClick={() => {
                    this.setState({
                      selectedCountry: c,
                    });
                  }}
                  aria-selected={selectedCountry === c}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      this.setState({
                        selectedCountry: c,
                      });
                    }
                  }}
                >
                  <span
                    className={`nav-link ${selectedCountry === c ? 'active' : ''}`}
                    id={`nav-descr-${c}-tab`}
                    data-toggle="tab"
                    aria-controls={`nav-desc-${c}`}
                  >
                    {c}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        {AVAILABLE_LANGUAGES.map((c) => {
          let editorState;
          switch (c) {
            case 'EN':
              editorState = this.state.editorStateEn;
              break;
            case 'FR':
              editorState = this.state.editorStateFr;
              break;
            case 'ES':
              editorState = this.state.editorStateEs;
              break;
            case 'RO':
              editorState = this.state.editorStateRo;
              break;
            case 'PL':
              editorState = this.state.editorStatePl;
              break;
            case 'NL':
              editorState = this.state.editorStateNl;
              break;
          }
          const ariaDescribedBy = c === 'EN' ? this.props.ariaDescribedBy : '';
          const ariaInvalid = c === 'EN' ? this.props.ariaInvalid : false;
          const ariaRequired = c === 'EN';
          return (
            <div
              key={c}
              className={`tab-panel  ${selectedCountry === c ? '' : 'd-none'} ${this.props.isReadOnly ? 'rsw-editor background-color-gray' : ''}`}
              id={`nav-desc-${c}`}
              role="tabpanel"
              aria-labelledby={`nav-descr-${c}-tab`}
            >
              {this.props.isReadOnly ? (
                <div className="readonly" dangerouslySetInnerHTML={{ __html: translationItems[c] }}></div>
              ) : (
                <Editor
                  toolbar={TOOLBAR_EMPTY}
                  toolbarCustomButtons={[
                    <CustomOptionLink
                      editorState={editorState}
                      onChange={(state) => this.onEditorStateChange(state, c)}
                      country={c}
                      isEnabled={selectedCountry === c}
                    />,
                    <CustomOption
                      editorState={editorState}
                      onChange={(state) => this.onEditorStateChange(state, c)}
                      country={c}
                      isEnabled={selectedCountry === c}
                    />,
                  ]}
                  editorState={editorState}
                  wrapperClassName="rsw-editor"
                  editorClassName="editor-class"
                  toolbarClassName="rsw-toolbar"
                  ariaLabel={this.props.ariaLabel}
                  ariaDescribedBy={ariaDescribedBy}
                  ariaInvalid={ariaInvalid}
                  ariaRequired={ariaRequired}
                  onEditorStateChange={(state) => this.onEditorStateChange(state, c)}
                ></Editor>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
