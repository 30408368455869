import React, { Component } from 'react';
import { ForegroundImage } from '../../models/image';
import './product_management.css';

class DeviceImageProps {
  public image: ForegroundImage;
  public index: number;
  public isReadOnly: boolean;
  public alt: string;
  public baseUrl: string;

  public onImageReferenceChanged(imageId: number) {}
  public onDeleteImage(imageId: number) {}
}

export class DeviceImage extends Component<DeviceImageProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const image = this.props.image;
    return (
      <div className="border border-dark image-div me-2">
        <div className="border border-top-0 border-end-0 border-start-0 border-dark image-header-div d-flex flex-row mt-2">
          <div className="ms-2" style={{ width: '80px' }}>
            <p className="device-image-p">Image {this.props.index}</p>
            {image.isReference ? <p className="greenBackgroundColor">Reference</p> : ''}
          </div>
          <div className="ms-2">
            <button
              className="btn btn-primary btn-block mt-1"
              onClick={(e) => {
                this.props.onImageReferenceChanged(image.id);
              }}
              disabled={image.isReference || this.props.isReadOnly}
            >
              Make as reference
            </button>
          </div>
          <div className="m-auto">
            <button
              id={`deleteImage-${image.id}`}
              aria-labelledby={`button-label-${image.id}`}
              className="icon foreground-Trash delete_button not_underlined link-button"
              onClick={() => {
                this.props.onDeleteImage(image.id);
              }}
              disabled={this.props.isReadOnly}
            >
              <span id={`button-label-${image.id}`} className="visually-hidden">
                delete image {image.id}
              </span>
            </button>
          </div>
        </div>
        <div>
          {!!image && !!image.link ? (
            <img
              className="container product-img"
              style={{ height: '200px' }}
              src={`${this.props.baseUrl}/public/storage/img/${image.link}`}
              alt={`${this.props.alt}`}
              onError={(e) => {
                (e.target as HTMLImageElement).onerror = null;
                (e.target as HTMLImageElement).remove();
              }}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
