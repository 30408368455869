import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ModalDeleteReview(props) {
  return (
    <Modal show={props.show} onHide={props.onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Delete Review</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="me-1">Are you sure you want to delete the review from user:</span>
        <em>{`${props.user}`} </em>
        <span>?</span>
        {props.isErrorReviewDeletion ? <p className="errorMessage mt-2">{props.reviewDeletionErrorMessage}</p> : ''}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => props.onDelete()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
