import { AVAILABLE_LANGUAGES, DISTRIBUTION_STRATEGY_OPTIONS, UNIT_OPTIONS } from './constants';
import { ForegroundDocument, ForegroundDocumentToAdd } from './document';

export class NDA {
  constructor(public signature: string, public expiration: string, public comment: string) {}
}

export class CompanyInformationI18nBase {
  constructor(public description: string, public jointProjectWithOrange: string) {}
}

export class CompanyInformationI18n extends CompanyInformationI18nBase {
  constructor(public countryCode: string, public description: string, public jointProjectWithOrange: string) {
    super(description, jointProjectWithOrange);
  }
}

export class CompanyLogo {
  constructor(public fileName: string, public fileContent: string, public link: string = '') {}
}

export class CorporateSourcingContract {
  constructor(public commentary: string, public signature: string, public expiration: string, public contractNumber: string, public buyer: string) {}
}

export class CompanyActivity {
  constructor(public id: number, public label: string) {}
}

export const COMPANY_STATUS_CORPORATING_SOURCING_CONTRACT = 6;
export class CompanyStatus {
  constructor(public id: number, public label: string) {}
}

export class CompanyReference {
  constructor(public id: number, public customerReferencesInIot: string, public distributionStrategy: string) {}
}

export class CompanyRdPolicy {
  constructor(
    public id: number,
    public rdLocation: string,
    public nbEngineersRd: number,
    public rdAnnualSpending: number,
    public rdAnnualSpendingMoney: string,
    public patentPortfolio: string
  ) {}
}

export class CompanyQualityPolicyProduction {
  constructor(public isoCertificationPolicy: string, public productionCapacityAndLocation: string, public productionPartners: string) {}
}

export class CompanyFinanceAndSustainability {
  constructor(
    public id: number,
    public creationIotActivity: string,
    public headCountIot: number,
    public stackholdersIotSusbsidiary: string,
    public companyCapital: number,
    public companyCapitalUnit: string,
    public volumeOfProduction: number,
    public turnoverLastYear: number,
    public turnoverLastYearUnit: string,
    public commercialOrganisation: string
  ) {}
}

export class SustainableDevelopment {
  constructor(
    public id: number,
    public companyId: number,
    public esgCSRPolicy: boolean,
    public esgCSRAdditionalInformation: string,
    public csrPolicyEvaluated: boolean,
    public sustainableProcurementPolicy: boolean,
    public sustainableProcurementAdditionalInformation: string,
    public esgCSRContact: string
  ) {}
}
export class Company {
  public id: number;
  public name: string;
  public slug: string;
  public description: string;
  public address: string;
  public zipcode: string;
  public city: string;
  public logo: CompanyLogo;
  public website: string;
  public socialNetwork : string;
  public isPartner: boolean;
  public isOrange: boolean;
  public nda: NDA;
  public withOrangeConnectivity: boolean;
  public inOrangeOffer: boolean;
  public referent: string;
  public comments: string;
  public reseller: boolean;
  public dateReseller: string;
  public jointProjectWithOrange: string;
  public productsRange: string;
  public createdAt: string;
  public updatedAt: string;
  public activity: CompanyActivity;
  public status: CompanyStatus;
  public country: string;
  public countryId: number;
  public dateStatus: string;
  public corporateSourcingContract: CorporateSourcingContract;
  public customerReferences: CompanyReference;
  public financeAndSustainability: CompanyFinanceAndSustainability;
  public rdPolicy: CompanyRdPolicy;
  public qualityPolicyProduction: CompanyQualityPolicyProduction;
  public documents: Array<ForegroundDocument>;
  public sustainableDevelopment: SustainableDevelopment;
  public informationI18N: Array<CompanyInformationI18n>;
  public documentsToAdd: ForegroundDocumentToAdd;
  public documentsToUpdate: Array<ForegroundDocument>;
  public documentsToDelete: Array<number>;

  constructor() {
    this.id = null;
    this.name = '';
    this.description = '';
    this.address = '';
    this.zipcode = '';
    this.city = '';
    this.logo = new CompanyLogo('', '', null);
    this.website = '';
    this.socialNetwork = '';
    this.isPartner = false;
    this.isOrange = false;

    this.status = null;
    this.activity = null;
    this.jointProjectWithOrange = '';
    this.customerReferences = new CompanyReference(null, '', DISTRIBUTION_STRATEGY_OPTIONS[0].label);

    this.financeAndSustainability = new CompanyFinanceAndSustainability(null, '', 0, '', 0, UNIT_OPTIONS[0].label, 0, 0, UNIT_OPTIONS[0].label, '');

    this.rdPolicy = new CompanyRdPolicy(null, '', 0, 0, UNIT_OPTIONS[0].label, '');

    this.qualityPolicyProduction = new CompanyQualityPolicyProduction('', '', '');

    this.sustainableDevelopment = new SustainableDevelopment(null, null, false, '', false, false, '', '');

    this.countryId = null;
    this.informationI18N = [];
    AVAILABLE_LANGUAGES.forEach((l) => {
      if (l !== 'EN') {
        this.informationI18N.push(new CompanyInformationI18n(l, '', ''));
      }
    });
  }
}

export class CompanyCountItem {
  public id: number;
  public label: string;
  public nbCompanies: number;
}

export class CompanyCount {
  public companyStatus: CompanyCountItem[];
  public companyCollaboration: CompanyCountItem[];
  public companyCountries: CompanyCountItem[];

  constructor() {
    this.companyStatus = [];
    this.companyCollaboration = [];
    this.companyCountries = [];
  }
}
