import { ForegroundOption } from '../components/ForegroundSelect';

export const USER_TYPE = {
  PARTNER: 1,
  ADMIN: 2,
  USER: 3,
  ALSTOM: 4,
  VALIDER: 5,
};

export enum FOREGROUND_SCOPE {
  PARTNER = 'partner',
  ORANGE_PARTNER = 'orange_partner',
  ORANGE_ADMIN = 'administrator',
  ORANGE_USER = 'orange_user',
  ORANGE_VALIDATION = 'orange_validation',
}

export const PARTNER_SCOPE = 'partner';
export const ORANGE_PARTNER_SCOPE = 'orange_partner';
export const ORANGE_ADMIN_SCOPE = 'administrator';
export const ORANGE_USER_SCOPE = 'orange_user';
export const ORANGE_VALIDATION_SCOPE = 'orange_validation';

export const EMAIL_REGEX = /^([a-zA-Z0-9._-]+)@[a-zA-Z0-9]{1,63}([.-][a-zA-Z0-9]{1,63}){0,}[.][a-zA-Z0-9]{2,63}$/;

export const ORANGE_EMAIL_REGEX = /^([a-zA-Z0-9._-]+)@(orange.com|sofrecom.com|buyin.pro)$/;

export const PASSWORD_REGEX = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/;
export const PASSWORD_LENGTH = 6;

export const NUMERIC_REGEX = /^[\d]*$/;
export const DECIMAL_REGEX = /^[\d]*\.?[\d]*$/;
export const SIGN_NUMERIC_REGEX = /^(-|\+)?[\d]*$/;
export const PHONE_REGEX = /^([+]?)([0-9\.\s\(\)\-]+)$/;

export const WEB_URL_REGEX = /^https?:\/\/.+$/;
export const WEB_URL_YOUTUBE_REGEX = /^https?:\/\/(www\.|m\.)?youtube\.com\/.+$/;

export const UNDEFINED_INDEX_VALUE = -1;

export const UNDEFINED_LABEL = new ForegroundOption('', UNDEFINED_INDEX_VALUE);

// ERROR Message
export const LOGIN_ERROR = 'Login error! If error persists, please contact an administrator';
export const MANDATORY_FIELD_MISSING = 'Please complete this field';
export const MANDATORY_FIELD_MISSING_PERCENTAGE = 'Please put a percentage';
export const MANDATORY_AT_LEAST_ON_FIELD = 'Please enter at least one field';
export const BAD_EMAIL_FORMAT = 'Wrong email format';
export const BAD_PHONE_FORMAT = 'Wrong phone format';
export const PASSWORD_FORMAT = 'Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters';
export const NOT_VALID_PASSWORD = 'The given password is not valid';
export const USER_NOT_FOUND = 'User not found';
export const INTERNAL_ERROR = 'Internal Error';
export const GENERAL_ERROR = 'An error occured';
export const LISTING_ERROR = GENERAL_ERROR + '. Please reload the page and possibiliy log out / log in again';

export const PLEASE_SELECT_COUNTRY = 'Please select a country';
export const PLEASE_SELECT_JOB_POSITION = 'Please select a job position';
export const PLEASE_SELECT_COMPANY = 'Please select or enter company name';
export const PLEASE_SELECT_USER_ROLE = 'Please select a user role';
export const PLEASE_SELECT_ENTITY = 'Please select an entity';
export const PLEASE_SELECT_COMPANY_ACTIVITY = 'Please select a company activity';
export const PLEASE_SELECT_BATTERY_LONGEVITY = 'Please select a battery longevity';
export const PLEASE_SELECT_VERTICALS = 'Please select a vertical';
export const PLEASE_SELECT_USESCASES = 'Please select a use case';
export const PLEASE_SELECT_CATEGORY = 'Please select a document category';

export const PLEASE_SELECT_PROVIDER = 'Please select a provider or type an other provider name';
export const PLEASE_SELECT_REGULATION = 'Please select a regulation compliancy';
export const PLEASE_SELECT_NETWORK = 'Please select a network/radio interfaces';
export const PLEASE_SELECT_PROTOCOL = 'Please select a device protocol';
export const PLEASE_SELECT_DEVICE_MATURITY = 'Please select a device maturity';
export const PLEASE_SELECT_DEVICE_FRONTEND = 'Please select frontend';
export const PLEASE_CONTACT_ADMIN = 'Please contact your admin for this operation';
export const PLEASE_SELECT_STATUS_PRODUCTION = 'Please select a status of production';
export const PLEASE_SELECT_DEVICE_TYPE = 'Please select a device type';
export const PLEASE_SELECT_DESCRIPTION_EN = 'Please fill the description, in English';

export const PLEASE_SELECT_TECHNOLOGIES = 'Please select technologies';

export const NUMERIC_FIELD_ERROR = 'Only digits are autorized';
export const SIGN_NUMERIC_FIELD_ERROR = 'Only digits are autorized and minus sign';
export const DECIMAL_FIELD_ERROR = 'Only digit and dot are autorized';
export const BAD_WEB_URL = 'Web url should start with https:// or http://';
export const BAD_WEB_URL_YOUTUBE = 'Please enter a valid Youtube video url';
export const NOT_IDENTICAL_PASSWORDS_ERROR = 'New password and Confirm new password does not match';

export const DATE_PICKER_FORMAT = 'yyyy/MM/dd';

export const AUTOCOMPLETE_STYLE = {
  height: '40px',
  border: '.125rem solid #ccc',
  borderRadius: '0',
  boxShadow: 'unset',
  placeholderColor: '',
};

export const ZINDEX_HIGHEST = { zIndex: 999999 };
export const ZINDEX_LOWEST = { zIndex: 0 };

export const UNIT_OPTIONS = [new ForegroundOption('€', '€'), new ForegroundOption('$', '$')];

export const DISTRIBUTION_STRATEGY_OPTIONS = [
  new ForegroundOption('Direct', 'Direct'),
  new ForegroundOption('Indirect', 'Indirect'),
  new ForegroundOption('Direct & Indirect', 'Direct & Indirect'),
];
export const AVAILABLE_LANGUAGES = ['EN', 'FR', 'ES', 'RO', 'PL', 'NL'];

export const EMPTY_DESCRIPTION = '<p></p>';

export const SESSION_STORAGE_PASSWORD = 'sessionStoragePassword';

export const SESSION_STORAGE_DOC_EDIT_ONLY = 'docEditionOnly';

// placeholder
export const PLACEHOLDER_FILL_FIELD = 'If yes, please fill in this field';

export const TOKEN_VERIFY_DELAY=5000; // 5 seconds (in milli-seconds)

export const TOKEN_NUMBER_OF_SECONDS_ALERT=86400; // 1 day (in seconds)

